import { useState } from "react";
import { Spinner } from "react-bootstrap";
import DeleteModal from "../../../components/DeleteModal";
import { useNotification } from "../../../components/NotificationProvider";
import { DocumentFile } from "../../../models/documentFile";
import {
  deleteDocument,
  deletePermanentlyDocument,
  downloadDocument,
  restoreDocument,
} from "../../../services/document.service";
import DisplayFileType from "./DisplayFileType";
import {
  TableHeaderCell,
  SortableWrapper,
  SortChevron,
  CustomTable,
  DownloadLink,
  DocumentCenterWrapper,
  DeleteIcon,
  RestoreIcon,
  TableFilterHeaderCell,
} from "./styles";
import TableFilter from "../../../components/TableFilter";
import {
  DocumentQuery,
  DocumentOrderByColumn,
} from "../../../models/document-query";
import {
  DocumentQueryAction,
  DocumentQueryActionKind,
} from "../documentQueryReducer";
import { FileState } from "../../../models/file-state";

interface DocumentListProps {
  documentQuery: DocumentQuery;
  dispatch: React.Dispatch<DocumentQueryAction>;
  filterOptions: any;
  documents: DocumentFile[];
  isLoading: boolean;
  fetchFiles: () => void;
  isDeletedDocumentFolder?: boolean;
}

function DocumentTable({
  documentQuery,
  dispatch,
  filterOptions,
  documents,
  isLoading,
  fetchFiles,
  isDeletedDocumentFolder,
}: DocumentListProps) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<string | undefined>(
    undefined
  );

  const { showError } = useNotification();

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date: Date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const handleSort = (orderBy: DocumentOrderByColumn) => {
    if (documentQuery.orderBy !== orderBy) {
      if (orderBy === DocumentOrderByColumn.Date) {
        handleOrderSort(orderBy, true);
      }
      if (orderBy === DocumentOrderByColumn.Name) {
        handleOrderSort(orderBy, false);
      }
      return;
    }
    handleOrderSort(orderBy, !documentQuery.sortDesc);
  };

  const handleDeleteDocument = (id: string) => {
    if (!isDeletedDocumentFolder) {
      deleteDocument(id).then(() => {
        fetchFiles();
      });
    } else {
      setSelectedDocument(id);
      setShowConfirmDelete(true);
    }
  };

  const handleRestoreDocument = (id: string) => {
    restoreDocument(id).then(() => {
      fetchFiles();
    });
  };

  const handleOrderSort = (
    orderBy: DocumentOrderByColumn,
    sortDesc: boolean
  ) => {
    dispatch({
      type: DocumentQueryActionKind.UPDATE_SORT,
      payload: { orderBy, sortDesc },
    });
  };

  const handleFilter = (target: string, value: string) => {
    dispatch({
      type: DocumentQueryActionKind.UPDATE_FILTER,
      payload: {
        [target]: value,
      },
    });
  };

  return (
    <div className="mt-4">
      <CustomTable>
        <thead>
          <tr
            style={{
              height: "1px",
            }}
          >
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.module}
                setExternalValue={(value) => handleFilter("module", value)}
                columnTitle="Module"
                options={filterOptions.modules}
              />
            </TableFilterHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(DocumentOrderByColumn.Name)}
              >
                Document Name
                <SortChevron
                  $show={documentQuery.orderBy === DocumentOrderByColumn.Name}
                  $desc={documentQuery.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.type}
                setExternalValue={(value) => handleFilter("type", value)}
                columnTitle="Type"
                options={filterOptions.types}
              />
            </TableFilterHeaderCell>
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.activityType}
                setExternalValue={(value) =>
                  handleFilter("activityType", value)
                }
                columnTitle="Activity Type"
                options={filterOptions.activityTypes}
              />
            </TableFilterHeaderCell>
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.activity}
                setExternalValue={(value) => handleFilter("activity", value)}
                columnTitle="Activity ID/Name"
                options={filterOptions.activities}
              />
            </TableFilterHeaderCell>
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.purpose}
                setExternalValue={(value) => handleFilter("purpose", value)}
                columnTitle="Document Purpose"
                options={filterOptions.purposes}
              />
            </TableFilterHeaderCell>
            <TableFilterHeaderCell>
              <TableFilter
                externalValue={documentQuery.creator}
                setExternalValue={(value) => handleFilter("creator", value)}
                columnTitle="Added By"
                options={filterOptions.creators}
              />
            </TableFilterHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(DocumentOrderByColumn.Date)}
              >
                Date
                <SortChevron
                  $show={documentQuery.orderBy === DocumentOrderByColumn.Date}
                  $desc={documentQuery.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell
              style={{
                minWidth: "85px",
              }}
            ></TableHeaderCell>
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            {documents.map((document, i) => (
              <tr key={i}>
                <td className="text-center">{document.module}</td>
                <td>
                  <DownloadLink onClick={() => downloadDocument(document.id)}>
                    {document.fileDisplayName}
                  </DownloadLink>
                </td>
                <td className="text-center">
                  <DisplayFileType fileName={document.fileDisplayName} />
                </td>
                <td className="text-center">{document.activityType}</td>
                <td className="text-center">{document.activityName}</td>
                <td className="text-center">{document.documentPurpose}</td>
                <td className="text-center">{document.createdByDisplayName}</td>
                <td className="text-center">
                  {formatDate(new Date(document.createdDate))}
                </td>
                <td className="text-center">
                  {document.state !== FileState.Locked && (
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteDocument(document.id);
                      }}
                    />
                  )}
                  {isDeletedDocumentFolder && (
                    <RestoreIcon
                      className="ms-3"
                      onClick={() => {
                        handleRestoreDocument(document.id);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </CustomTable>
      {isLoading && (
        <DocumentCenterWrapper>
          <Spinner animation="border" />
          <span>Loading ...</span>
        </DocumentCenterWrapper>
      )}
      {!isLoading && documents.length === 0 && (
        <DocumentCenterWrapper>No data found.</DocumentCenterWrapper>
      )}
      <DeleteModal
        showModal={showConfirmDelete && !!selectedDocument}
        handleCloseModal={() => setShowConfirmDelete(false)}
        handleDelete={() => {
          selectedDocument &&
            deletePermanentlyDocument(selectedDocument)
              .then(() => {
                fetchFiles();
              })
              .catch(() => {
                showError(
                  undefined,
                  "An error occurred while permanently delete this document from the portal."
                );
              })
              .finally(() => {
                setSelectedDocument(undefined);
                setShowConfirmDelete(false);
              });
        }}
        messageContent=" This action will permanently delete this document from the portal. Do you want to continue?"
      />
    </div>
  );
}

export default DocumentTable;
