import React from "react";

function withFormatValidation<T>(
  WrappedComponent: React.ComponentType<T>,
  maskFormatter: (value: string) => string,
  valueFormatter: (value: string) => number,
  additionalCondition: (value: string) => boolean
) {
  const InputComponentWithFormatValidation = React.forwardRef(
    (
      {
        ...passedProps
      }: Omit<T, "maskFormatter" | "valueFormatter" | "additionalCondition">,
      ref
    ) => {
      return (
        <WrappedComponent
          {...(passedProps as unknown as T)}
          ref={ref}
          maskFormatter={maskFormatter}
          valueFormatter={valueFormatter}
          additionalCondition={additionalCondition}
        />
      );
    }
  );
  return InputComponentWithFormatValidation;
}

export default withFormatValidation;
