import { DropdownLayout, FilterDropdown } from "./styles";

type Props = {
  label: string;
  options: { [key: string]: string };
  value: string | null;
  hasDefaultValue?: boolean;
  dropdownChange: (value: string | null) => void;
};

function DropdownNoneOutline({
  label,
  options,
  value,
  hasDefaultValue,
  dropdownChange,
}: Props) {
  return (
    <DropdownLayout>
      <div>{label}:</div>
      <FilterDropdown
        value={value || ""}
        onChange={(e: any) => {
          if (e.target.value === "") dropdownChange(null);
          else dropdownChange(e.target.value);
        }}
      >
        {hasDefaultValue && <option value="">All</option>}
        {Object.entries(options).map((o) => (
          <option value={o[0]} key={o[1]}>
            {o[1]}
          </option>
        ))}
      </FilterDropdown>
    </DropdownLayout>
  );
}

export default DropdownNoneOutline;
