import styled from "styled-components";

export const LoginFormLayout = styled.div.attrs((_) => ({
  className: "",
}))`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  min-width: 400px;
  width: 50vw;
  text-align: center;
`;
export const Link = styled.a`
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.darkBlueLink.primaryColor};
  font-family: ${({ theme }) => theme.fontFamily.body};
  :hover {
    color: ${({ theme }) => theme.darkBlueLink.secondaryColor};
  }
`;
export const IconWrapper = styled.div.attrs((_) => ({
  className: "btn btn-icon",
}))`
  position: absolute;
  right: -50px;
  top: 0;
  color: black !important;
  border: none;
`;
export const ActionWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
