import SLRAllWhiteLogo from "./logos/SLR Logo 2020_RGB_white_for web.png";
const slrBranding = {
  black: "rgb(0, 0, 0)",
  darkBlue: "rgb(0, 69, 135)",
  blue: "rgb(0, 175, 219)",
  blue20: "rgba(0, 175, 219, 0.2)",
  mediumBlue: "#1D62D7",
  yellowGreen: "rgb(193, 205, 35)",
  teal: "rgb(0, 153, 184)",
  charcoal: "rgb(65, 67, 76)",
  green: "rgb(134, 163, 21)",
  green1: "#24B907",
  orange: "rgb(255, 103, 27)",
  red: "#E51935",
  white: "rgb(255,255,255)",
  grey: "rgb(190,190,190)",
  lightGrey: "rgb(245, 245, 245)",
  lightGrey2: "rgb(226, 230, 234)",
  mediumGrey: "rgb(200, 207, 213)",
  grey1: "#575756",
  grey2: "#878787",
  grey3: "#B9B4BB",
  fontFamilyHeadlines: "Calibri",
  fontFamilyBody: "Calibri",
  headlinesFontWeight: "bold",
  bodyFontWeight: "normal",
  logoAllWhite: SLRAllWhiteLogo,
};
export default slrBranding;
