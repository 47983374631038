import { useNavigate } from "react-router-dom";
import { Spinner, Container, Form, FormGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ActionWrapper, IconWrapper, Link, LoginFormLayout } from "./styles";
import {
  DarkBlueOutlinedButton,
  GreyOutlinedInput,
} from "../../../components/common";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { strings } from "../../../localization/en";
import sessionService from "../../../services/session.service";
import { emailRegex } from "../../../helpers/regex-pattern";
import { ApiResponse } from "../../../models/api-response";
import { useNotification } from "../../../components/NotificationProvider";

enum LoginStatus {
  BadRequest = "BadRequest",
  NotFound = "NotFound",
  Inactive = "Inactive",
  InvalidPassword = "InvalidPassword",
  LoginFail = "LoginFail",
  MaxAttempt = "MaxAttempt",
}
const LoginErrorMessages: {
  [key: string]: {
    type: string;
    message: any;
  };
} = {
  [LoginStatus.BadRequest]: {
    type: "custom",
    message: (
      <div className="text-center">Have error(s) occurred when login.</div>
    ) as any,
  },
  [LoginStatus.NotFound]: {
    type: "custom",
    message: (
      <span dangerouslySetInnerHTML={{ __html: strings.account_not_found }}/>
    ) as any,
  },
  [LoginStatus.Inactive]: {
    type: "custom",
    message: (
      <div className="text-center">{strings.account_inactivate}</div>
    ) as any,
  },
  [LoginStatus.MaxAttempt]: {
    type: "custom",
    message: (
      <div className="text-center">{strings.login_error_max_attempt}</div>
    ) as any,
  },
};

function LoginForm() {
  const { control, getValues, formState, setError, handleSubmit, clearErrors } =
    useForm({
      defaultValues: {
        email: "",
        password: "",
      },
      mode: "all",
    });
  const { errors, dirtyFields } = formState;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMaxAttempts, setIsMaxAttempts] = useState<boolean>(false);

  const { showError } = useNotification();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    sessionService
      .login(data.email, data.password)
      .then((res) => {
        if (res?.to === "Login2FA") {
          navigate("/login-2fa", { state: { token: res.token } });
        } else if (res?.to === "SetupMFA") {
          navigate("/mfa-setup", { state: { token: res.token } });
        }
      })
      .catch((err: any) => {
        if (err.message === "Failed to fetch") {
          showError(undefined, "An error occurred. Please try again later");
          setIsLoading(false);
          return;
        }

        const apiResponse = err.data as ApiResponse;
        clearErrors("email");

        const loginState = apiResponse.status as LoginStatus;
        switch (loginState) {
          case LoginStatus.InvalidPassword:
            const accessFailedTimes = Number(apiResponse.data);
            if (accessFailedTimes >= 5) {
              setError("password", LoginErrorMessages[LoginStatus.MaxAttempt]);
            } else {
              setError("password", {
                type: "custom",
                message: (
                  <div className="text-center">
                    {strings.login_error_common_attempt.replace(
                      "{{X}}",
                      `${5 - accessFailedTimes}`
                    )}
                  </div>
                ) as any,
              });
            }
            setIsMaxAttempts(accessFailedTimes >= 5);
            break;

          case LoginStatus.NotFound:
            setError("password", LoginErrorMessages[LoginStatus.NotFound]);
            break;

          case LoginStatus.Inactive:
            setError("password", LoginErrorMessages[LoginStatus.Inactive]);
            break;

          default:
            setError("password", LoginErrorMessages[LoginStatus.BadRequest]);
            break;
        }

        setIsLoading(false);
      });
  });

  const goToForgotPassword = () => {
    const { email } = getValues();
    navigate("/forgot-password", { state: { email: email } });
  };

  const showPasswordHandler = () => setIsShowPassword(!isShowPassword);

  const isDisableSubmitBtn = () =>
    !!errors.email || !!errors.password || isLoading || !dirtyFields.password;

  const resetPasswordValidation = () => {
    if ((formState.errors?.password?.type !== "required") as boolean) {
      clearErrors("email");
      clearErrors("password");
      setIsMaxAttempts(false);
    }
  };

  function errorMessageElement(name: "email" | "password") {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return message !== undefined ? (
            <Form.Control.Feedback type="invalid" className="text-start">
              {message}
            </Form.Control.Feedback>
          ) : (
            <></>
          );
        }}
      />
    );
  }

  return (
    <LoginFormLayout>
      <Container as={Form} onSubmit={onSubmit} noValidate>
        <Controller
          name="email"
          defaultValue=""
          control={control}
          render={({ field }) => {
            const { onBlur, onChange, ...rest } = field;
            return (
              <FormGroup className="w-100">
                <GreyOutlinedInput
                  placeholder="Enter email address here"
                  type="email"
                  isInvalid={!!errors.email}
                  onBlur={() => {
                    onBlur();
                    resetPasswordValidation();
                  }}
                  onChange={(e: any) => {
                    resetPasswordValidation();
                    onChange(e);
                  }}
                  {...rest}
                />

                {errorMessageElement("email")}
              </FormGroup>
            );
          }}
          rules={{
            required: "Required.",
            pattern: {
              value: emailRegex,
              message: "Invalid email address. Please check and re-enter.",
            },
          }}
        />

        <Controller
          name="password"
          defaultValue=""
          control={control}
          render={({ field }) => {
            const { onBlur, onChange, ...rest } = field;
            return (
              <FormGroup className="w-100 position-relative">
                <GreyOutlinedInput
                  placeholder="Password"
                  type={isShowPassword ? "text" : "password"}
                  isInvalid={!!errors.password}
                  disabled={isMaxAttempts}
                  style={{ backgroundImage: "none" }}
                  onChange={(e: any) => {
                    resetPasswordValidation();
                    onChange(e);
                  }}
                  {...rest}
                />
                {errorMessageElement("password")}
                <IconWrapper onClick={showPasswordHandler}>
                  <Icon path={!isShowPassword ? mdiEyeOff : mdiEye} size={1} />
                </IconWrapper>
              </FormGroup>
            );
          }}
          rules={{
            required: "Required.",
          }}
        />

        <ActionWrapper>
          <Link onClick={goToForgotPassword}>{strings.forgotten_password}</Link>
          <DarkBlueOutlinedButton type="submit" disabled={isDisableSubmitBtn()}>
            <Spinner
              animation="border"
              size="sm"
              className="me-2"
              hidden={!isLoading}
            />
            Login
          </DarkBlueOutlinedButton>
        </ActionWrapper>
      </Container>
    </LoginFormLayout>
  );
}

export default LoginForm;
