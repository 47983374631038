import styled from "styled-components";
import { LightGreyOutlinedInput } from "../../common";

type Props = {
  $isPaddedRight: boolean;
};

export const Input = styled(LightGreyOutlinedInput)<Props>`
  padding: 0.375rem 0.75rem !important;
  color: ${({ theme }) => theme.lightGreyInput.secondaryColor};
  :focus {
    color: ${({ theme }) => theme.lightGreyInput.secondaryColor};
    caret-color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};
  }
  &.is-invalid {
    color: ${({ theme }) => theme.lightGreyInput.secondaryColor}!important;
    caret-color: ${({ theme }) => theme.lightGreyInput.tertiaryColor}!important;
    background-image: none !important;
  }

  ${({ $isPaddedRight }) =>
    $isPaddedRight &&
    `
    padding-right: 2rem !important;
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputMask = styled.span`
  position: absolute;
  font-family: ${({ theme }) => theme.fontFamily.body};
  top: 0.5rem;
  left: calc(0.75rem + 2px);
  color: ${({ theme }) => theme.styledInput.placeholderText};
  writing-mode: horizontal-tb !important;

  & span {
    color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};
  }
`;
