import { IoClose } from "react-icons/io5";
import { DarkBlueSolidButton } from "../../../components/common";
import { Body, ButtonWrapper, Header, RoundedModal } from "./styles";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  handleSaveUser: () => void;
};
function MasterAdminConfirmationModal({
  showModal,
  handleCloseModal,
  handleSaveUser,
}: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      className="border-0"
    >
      <Header>
        <IoClose size={25} onClick={handleCloseModal} />
      </Header>
      <Body>
        <p>Are you sure you want to set this user as Master Admin?</p>
        <ButtonWrapper>
          <DarkBlueSolidButton onClick={handleSaveUser}>
            Yes
          </DarkBlueSolidButton>
          <DarkBlueSolidButton onClick={handleCloseModal}>
            No
          </DarkBlueSolidButton>
        </ButtonWrapper>
      </Body>
    </RoundedModal>
  );
}

export default MasterAdminConfirmationModal;
