import { Breadcrumb } from "react-bootstrap";
import styled from "styled-components";

export const StyledBreadcrumb = styled(Breadcrumb)`
  padding: 1rem;
  & a {
    color: ${({ theme }) => theme.breadcrumb.link};
    text-decoration: none;
  }
  & a:hover {
    color: ${({ theme }) => theme.breadcrumb.linkHover};
    text-decoration: none;
  }
  .breadcrumb-item::before {
    color: ${({ theme }) => theme.breadcrumb.separator};
  }
  .breadcrumb-item.active {
    color: ${({ theme }) => theme.breadcrumb.textActive};
  }
`;
