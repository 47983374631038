import { useEffect, useState } from "react";
import { useTaskManagement } from "../../contexts/TaskManagementProvider";
import { NotificationCount, NotificationCountWrapper } from "./styles";

interface Props {
  module: string;
  feature?: string;
  subFeature?: string;
}

function TaskCount({ module }: Props) {
  const [count, setCount] = useState<number | undefined>(undefined);

  const { statistic } = useTaskManagement();

  useEffect(() => {
    if (!statistic) {
      setCount(undefined);
      return;
    }

    setCount(statistic.find((x) => x.module === module)?.count);
  }, [statistic]);

  return count ? (
    <NotificationCountWrapper>
      <NotificationCount>{count}</NotificationCount>
    </NotificationCountWrapper>
  ) : (
    <></>
  );
}

export default TaskCount;
