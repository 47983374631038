import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useTaskManagement } from "../../../../contexts/TaskManagementProvider";
import { InputBox, ClearIcon, SearchIcon } from "./styles";

function SearchBox() {
  const { selectedItem, setFilter } = useTaskManagement();
  const [searchText, setSearchText] = useState<string>("");

  const onSearchDispatch = (value: string) => {
    setFilter((currentFilter) => {
      return { ...currentFilter, search: value === "" ? null : value };
    });
  };

  const debounceSearchText = React.useCallback(
    debounce((nextValue: string) => onSearchDispatch(nextValue), 250),
    []
  );

  useEffect(() => {
    setSearchText("");
  }, [selectedItem]);

  return (
    <div className="position-relative">
      <InputBox
        data-testid="searchBox"
        value={searchText}
        placeholder="Search"
        maxLength="255"
        onChange={(e: any) => {
          setSearchText(e.target.value);
          debounceSearchText(e.target.value);
        }}
      />
      <SearchIcon />
      {searchText && (
        <ClearIcon
          onClick={() => {
            setSearchText("");
            onSearchDispatch("");
          }}
        />
      )}
    </div>
  );
}

export default SearchBox;
