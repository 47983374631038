import { IoClose } from "react-icons/io5";
import {
  GreenSolidButton,
  RedSolidButton,
} from "../../../../components/common";
import {
  RoundedModal,
  Header,
  Body,
  WarningContent,
  ButtonWrapper,
} from "../../SlrBusinessConfirmationModal/styles";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  handleConfirmed: () => void;
};

function SelectUserWarningModal({
  showModal,
  handleCloseModal,
  handleConfirmed,
}: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      className="border-0"
    >
      <Header>
        <IoClose size={25} onClick={handleCloseModal} />
      </Header>
      <Body>
        <WarningContent>
          Are you sure?
          <br />
          You're about to remove this user's admin rights
        </WarningContent>
        <ButtonWrapper>
          <GreenSolidButton onClick={handleConfirmed}>Yes</GreenSolidButton>
          <RedSolidButton onClick={handleCloseModal}>No</RedSolidButton>
        </ButtonWrapper>
      </Body>
    </RoundedModal>
  );
}

export default SelectUserWarningModal;
