import { TextInputRHF } from "../../InputsWithRHF";
import { Wrapper } from "./styles";

type Props = {
  label: string;
  testId?: string;
  name: string;
};

function ModalTextInput({ label, name }: Props) {
  return (
    <Wrapper>
      <label>{label}</label>
      <div>
        <TextInputRHF name={name} />
      </div>
    </Wrapper>
  );
}

export default ModalTextInput;
