import { memo } from "react";
import { Input } from "./styles";
type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: string | null;
  setExternalValue: (value: string | null) => void;
  isTextarea?: boolean;
  isInvalid?: boolean;
  isPaddedRight?: boolean;
};
const TextInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    isTextarea = false,
    isInvalid = undefined,
    isPaddedRight = false,
    onBlur,
    onFocus,
    name,
    placeholder,
  }: Props) => {
    return (
      <Input
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        type="text"
        placeholder={placeholder}
        forwardedAs={isTextarea ? "textarea" : "input"}
        onChange={(e: any) => {
          if (e.target.value === "") setExternalValue(null);
          else setExternalValue(e.target.value);
        }}
        value={externalValue ? externalValue : ""}
        disabled={disabled}
        isInvalid={isInvalid}
        $isPaddedRight={isPaddedRight}
      />
    );
  }
);

export default TextInput;
