import styled from "styled-components";

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ColTitle = styled.div`
  justify-self: start;
  color: ${({ theme }) => theme.clientManagement.secondaryText};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.headlines};
`;
