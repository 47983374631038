import ExcelIcon from "./FileIcons/Excel.png";
import WordIcon from "./FileIcons/Word.png";
import PowerPointIcon from "./FileIcons/PowerPoint.png";
import PDFIcon from "./FileIcons/PDF.png";
import UnknownIcon from "./FileIcons/Unknown.png";

function DisplayFileType({ fileName }: { fileName: string }) {
  const regexFileExtension = /(?:\.([^.]+))?$/;
  const extension = regexFileExtension.exec(fileName)?.at(1);
  const getFileIcon = () => {
    if (extension === "xls" || extension === "xlsx" || extension === "csv") {
      return ExcelIcon;
    } else if (extension === "doc" || extension === "docx") {
      return WordIcon;
    } else if (extension === "ppt" || extension === "pptx") {
      return PowerPointIcon;
    } else if (extension === "pdf") {
      return PDFIcon;
    } else {
      return UnknownIcon;
    }
  };
  return <img src={getFileIcon()} alt={fileName} width={24} height={24} />;
}

export default DisplayFileType;
