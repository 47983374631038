import styled from "styled-components";

export const Layout = styled.div`
  color: ${({ theme }) => theme.layout.text};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SideBarLayout = styled.div`
  width: 220px;
  min-height: 75vh;
`;

export const DocumentListLayout = styled.div.attrs((_) => ({
  className: "vstack",
}))`
  width: calc(100% - 220px);
  padding: 1rem;
`;
