import { handleResponse } from "../helpers/handle-response";
import { Module } from "../models/module";

export function getAllModuleFeatures(): Promise<Module[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/Clients/modules`,
    requestOptions
  ).then(handleResponse);
}

export function getAccessibleModuleFeatures(): Promise<Module[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/Clients/modules/mine`,
    requestOptions
  ).then(handleResponse);
}

export function getClientAccessibleModuleFeatures(
  clientId: number
): Promise<Module[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/Clients/${clientId}/modules`,
    requestOptions
  ).then(handleResponse);
}
