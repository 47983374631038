import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export const StatusRow = styled.div.attrs((_) => ({
  className: "w-100 text-center py-3",
}))`
  background-color: ${({ theme }) => theme.userManagement.rowPrimaryColor};
  border-radius: 1rem;
`;

export const SpinnerLoading = styled(Spinner).attrs((_) => ({
  animation: "border",
}))`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;
