import { Modal, ModalHeader, Spinner } from "react-bootstrap";
import styled from "styled-components";

export const ImportingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0.5rem 1rem;

  > div {
    font-size: 18px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.fontFamily.headlines};
  }
`;

export const RoundedModal = styled(Modal)`
  & .modal-content {
    border-radius: 16px;
    border: none;
    box-shadow: 0 4px 5px 2px rgb(0 0 0 / 0.2);
  }
`;

export const Header = styled(ModalHeader).attrs((_) => ({
  className: "pt-2 pb-0 px-3",
}))`
  border: none;
  display: flex;
  justify-content: flex-end;
  & svg {
    cursor: pointer;
  }
`;
