import { memo } from "react";
import { Checkbox, CheckboxGroupWrapper } from "./styles";
type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: (number | string)[] | null;
  setExternalValue: (value: (number | string)[] | null) => void;
  options: { [key: number | string]: string };
  enabledOptions?: { [key: number | string]: string };
  isInvalid?: boolean;
  direction?: "row" | "column";
};
const CheckboxInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    options,
    enabledOptions,
    isInvalid = undefined,
    onBlur,
    onFocus,
    direction,
  }: Props) => {
    return (
      <CheckboxGroupWrapper $direction={direction}>
        {Object.keys(options).map((o, i) => {
          const isChecked = externalValue
            ? externalValue.includes(
                !Number.isNaN(o) && !Number.isNaN(parseFloat(o)) ? +o : o
              )
            : false;
          return (
            <Checkbox
              label={options[o]}
              key={o}
              value={o}
              id={`option-${o}`}
              onChange={() => {
                const checkedValue =
                  !Number.isNaN(o) && !Number.isNaN(parseFloat(o)) ? +o : o;
                if (externalValue) {
                  if (isChecked) {
                    const newvalue = externalValue.filter(
                      (v: any) => v !== checkedValue
                    );
                    setExternalValue(newvalue);
                  } else {
                    const newvalue = [...externalValue, checkedValue];
                    setExternalValue(newvalue);
                  }
                } else {
                  const array = [checkedValue];
                  setExternalValue(array);
                }
              }}
              checked={isChecked}
              disabled={
                disabled ||
                (!!enabledOptions && !Object.keys(enabledOptions).includes(o))
              }
              isInvalid={isInvalid}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          );
        })}
      </CheckboxGroupWrapper>
    );
  }
);

export default CheckboxInput;
