import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../../models/user";
import sessionService from "../../services/session.service";
import AdminHome from "./AdminHome";
import UserHome from "./UserHome";
import React from "react";

function LandingPage() {
  const currentUser = sessionService.getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) navigate("/login");
  }, []);

  if (!currentUser) return <></>;

  if (isAdmin(currentUser)) {
    return <AdminHome />;
  }
  return <UserHome />;
}

export default LandingPage;
