import { Spinner } from "react-bootstrap";
import { ActionWrapper, CallbackLayout } from "./styles";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import sessionService from "../../services/session.service";
import { User, isOnlyDocumentFeature } from "../../models/user";

enum DestinationParam {
  Tasks = "Tasks",
}

function Callback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const destination = searchParams.get("destination");
  useEffect(() => {
    sessionService.getMyInfo(token).then((user: User) => {
      if (destination === DestinationParam.Tasks) navigate("/tasks");
      else if (user && user.accessibleFeatures && isOnlyDocumentFeature(user)) {
        //(US - 152290): redirect user to directly document management.
        navigate("/documents");
      } else navigate("/");
    });
  }, [token, navigate, destination]);

  return (
    <CallbackLayout>
      <ActionWrapper>
        <Spinner animation="border" size="sm" className="me-2" />
      </ActionWrapper>
    </CallbackLayout>
  );
}

export default Callback;
