import Form from "react-bootstrap/Form";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { PrimaryButton } from "../common/styles";
import { SpinnerLoading, StatusRow } from "./styles";
import { useCallback, useMemo } from "react";
import { UserRoleType } from "../../../enums/user-role";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";

interface AppProps {
  isLoading: boolean;
  onSubmit: (data: any) => void;
  disabled?: boolean;
}

export default function ProfileStatus({
  isLoading,
  onSubmit,
  disabled,
}: AppProps) {
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useFormContext();
  const {
    field: { onChange: onControllerChange, value },
  } = useController({
    control,
    name: "active",
    defaultValue: false,
  });

  const userRoleType = useWatch({
    control,
    name: "userRole",
  }) as keyof typeof UserRoleType;
  const selectedModules = useWatch({ control, name: "modules" }) as
    | number[]
    | [];

  const submitForm = handleSubmit((data) => {
    onSubmit(data);
  });

  const keyOfRole = useCallback((value: UserRoleType) => {
    return getKeyByValueEnum(value, UserRoleType) as keyof typeof UserRoleType;
  }, []);

  const disabledSaveBtn = useMemo(() => {
    if (!isValid || isLoading) {
      return true;
    }

    // AC2 - 1510: Admin must be at least 1 modules
    if (
      userRoleType !== keyOfRole(UserRoleType.User) &&
      selectedModules &&
      selectedModules.length === 0
    ) {
      return true;
    }

    // AC2.2 - 1529: Can unassign an account out of modules.
    if (disabled) {
      return !isDirty;
    }

    return disabled;
  }, [
    isValid,
    isLoading,
    userRoleType,
    keyOfRole,
    selectedModules,
    disabled,
    isDirty,
  ]);

  return (
    <StatusRow>
      <div>
        <span className="me-2">Inactive</span>
        <Form.Check
          disabled={disabled}
          className="d-inline-block"
          data-testid="user-active"
          type="switch"
          id="custom-switch"
          checked={value}
          onChange={onControllerChange}
        />
        <span className="ms-2 pe-3">Active</span>
      </div>

      <PrimaryButton onClick={submitForm} disabled={disabledSaveBtn}>
        {isLoading && <SpinnerLoading />}
        Save
      </PrimaryButton>
    </StatusRow>
  );
}
