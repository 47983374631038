import { memo, useMemo } from "react";
import { RadioButton, RadioButtonGroupWrapper } from "./styles";
type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: string | null;
  setExternalValue: (value: string | null) => void;
  options: { [key: string]: string };
  disabledOptions?: { [key: string]: string };
  isInvalid?: boolean;
};
const RadioListInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    options,
    isInvalid = undefined,
    onBlur,
    onFocus,
    disabledOptions,
  }: Props) => {
    const disabledKeys = useMemo(
      () => disabledOptions && Object.keys(disabledOptions),
      [disabledOptions]
    );

    return (
      <RadioButtonGroupWrapper>
        {Object.keys(options).map((o, i) => {
          const isChecked = externalValue === o;
          return (
            <RadioButton
              label={options[o]}
              key={o}
              value={o}
              id={`option-${o}`}
              onChange={() => {
                setExternalValue(o);
              }}
              checked={isChecked}
              disabled={disabled || disabledKeys?.includes(o)}
              isInvalid={isInvalid}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          );
        })}
      </RadioButtonGroupWrapper>
    );
  }
);

export default RadioListInput;
