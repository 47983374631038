import { PlaceholderBox, Layout } from "./styles";

function UserHome() {
  return (
    <Layout>
      <PlaceholderBox>Placeholder content</PlaceholderBox>
    </Layout>
  );
}

export default UserHome;
