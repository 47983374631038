import { Form } from "react-bootstrap";
import ValidationLayout from "../ValidationLayout";
import { FormLabel } from "./styled";

interface Props {
  name: string;
  children: JSX.Element;
  label: string;
  fixedWidth?: string;
  withoutControlId?: boolean;
  style?: Object;
}
function LabelWrapper({
  name,
  label,
  children,
  fixedWidth,
  withoutControlId,
  style,
}: Props) {
  return (
    <Form.Group
      className="d-flex"
      controlId={withoutControlId ? "" : `form-${name}`}
    >
      <FormLabel $fixedWidth={fixedWidth} className="me-3 pt-2" style={style}>
        {label}
      </FormLabel>
      <ValidationLayout name={name} children={children} />
    </Form.Group>
  );
}

export default LabelWrapper;
