import { IoClose } from "react-icons/io5";
import { DarkBlueSolidButton, GreenSolidButton, RedSolidButton } from "../common";
import { Body, ButtonWrapper, Header, RoundedModal } from "./styles";
type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  handleDelete: () => void;
  messageContent: string;
};
function DeleteModal({ showModal, handleCloseModal, handleDelete, messageContent }: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      className="border-0"
    >
      <Header>
        <IoClose size={25} onClick={handleCloseModal} />
      </Header>
      <Body>
        <p>
          {messageContent}
        </p>
        <ButtonWrapper>
          <GreenSolidButton onClick={handleDelete}>Yes</GreenSolidButton>
          <RedSolidButton onClick={handleCloseModal}>
            No
          </RedSolidButton>
        </ButtonWrapper>
      </Body>
    </RoundedModal>
  );
}

export default DeleteModal;
