export function formatDate(date: string) {
  if (!date) return null;
  const dateObject = new Date(date);
  const extractedDate = padWithLeadingZeros(dateObject.getDate(), 2);
  const extractedMonth = padWithLeadingZeros(dateObject.getMonth() + 1, 2);
  const extractedYear = padWithLeadingZeros(dateObject.getFullYear(), 4);
  return [extractedYear, extractedMonth, extractedDate].join("-");
}
function padWithLeadingZeros(num: number, totalLength: number) {
  return String(num).padStart(totalLength, "0");
}
