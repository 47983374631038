import { Form } from "react-bootstrap";
import styled from "styled-components";

export const Radio = styled(Form.Check).attrs((_) => ({
  type: "radio",
}))`
  .form-check-input[disabled] ~ label.form-check-label {
    opacity: 0.7;
    color: ${({ theme }) => theme.layout.text};
  }
  .form-check-input[disabled] {
    background-color: ${({ theme }) => theme.lightGreyInput.primaryColor};
  }
`;
export const RadioGroupWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
