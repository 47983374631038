import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import {
  DayMonthInputRHF,
  DropdownInputRHF,
  TextInputRHF,
} from "../../../components/InputsWithRHF";
import { useNotification } from "../../../components/NotificationProvider";
import { DarkBlueOutlinedButton } from "../../../components/common";
import { ClientSector } from "../../../enums/client-sector";
import { checkDayMonth } from "../../../helpers/check-day-month";
import { Currency } from "../../../models/currency";
import {
  getAllCountries,
  getAllCurrencies,
} from "../../../services/general.service";
import {
  default as LabelWrapper,
  default as LabelWrapperWithValidation,
} from "../LabelWrapper";
import { FormLabel } from "../LabelWrapper/styled";
import SlrBusiness from "../SlrBusiness";
import ValidationLayout from "../ValidationLayout";
import { HeaderText } from "../common/styles";
import { DisplayCurrencySymbol, InputFileUpload } from "./styles";

interface Props {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  isEdit: boolean;
}

function ClientInformation({ file, setFile, isEdit }: Props) {
  const allowedExtensions = /(\.jpg|\.png|\.jpeg|\.svg)$/i;

  const [countries, setCountries] = useState<{ [key: string]: string }>({});
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<{
    [key: string]: string;
  }>({});
  const [currencySymbol, setCurrencySymbol] = useState("");

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { showError } = useNotification();
  const { control, trigger, formState: { isDirty } } = useFormContext();
  const preferredReportingCurrency = useWatch({
    control,
    name: "preferredReportingCurrency",
  });

  const fromDate = useWatch({
    control,
    name: "fromDate",
  }) as string | undefined;

  const toDate = useWatch({
    control,
    name: "toDate",
  }) as string | undefined;

  useLayoutEffect(() => {
    getAllCurrencies()
      .then((currencies) => {
        setCurrencies(currencies);
        let _currencies = {};
        currencies.forEach((currency) => {
          _currencies = {
            ..._currencies,
            [currency.code]: currency.code,
          };
        });
        setCurrencyOptions(_currencies);
      })
      .catch(() => showError(undefined, "Can't fetch currencies"));

    getAllCountries()
      .then((countries) => {
        let _countries: { [key: string]: string } = {};
        countries.forEach((country) => {
          _countries = { ..._countries, [country.code]: country.name };
        });
        setCountries(_countries);
      })
      .catch(() => showError(undefined, "Can't fetch countries"));
  }, []);

  useEffect(() => {
    const selectedCurrency = currencies.find(
      (x) => x.code === preferredReportingCurrency
    );
    if (selectedCurrency) {
      setCurrencySymbol(selectedCurrency.symbol);
    } else {
      setCurrencySymbol("");
    }
  }, [preferredReportingCurrency, currencies]);

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files) {
      const { files } = e.target;
      for (const file of files) {
        if (!allowedExtensions.exec(file.name)) {
          showError(undefined, "File not allowed. Allowed *.jpg, *.png, *.svg");
          return;
        }
      }
      if (e.target.files.length > 0) setFile(e.target.files[0] as File);
      else setFile(null);
    }
  };

  const checkFinancialDate = (toDate: string | undefined, fromDate: string | undefined) => {
    if (!toDate && !fromDate) return true;
    if (toDate === fromDate) return false;

    return true;
  }

  useEffect(() => {
    if ((isEdit || isDirty) && fromDate && toDate) {
      trigger("fromDate");
      trigger("toDate");
    }
  }, [fromDate, isDirty, isEdit, toDate, trigger])

  return (
    <div className="my-3">
      <HeaderText>Company Information</HeaderText>
      <Row className="mt-2 g-3 px-4">
        <Col xs="5">
          <LabelWrapperWithValidation
            name="name"
            label="Company Name"
            fixedWidth="8rem"
          >
            <TextInputRHF
              name="name"
              placeholder="Company Name"
              rules={{ required: "Required." }}
            />
          </LabelWrapperWithValidation>
        </Col>
        <Col xs="1" />
        <Col xs="3">
          <SlrBusiness />
        </Col>
        <Col xs="3" />
        <Col xs="5">
          <LabelWrapper name={`sector`} label="Sector" fixedWidth="8rem">
            <DropdownInputRHF
              name="sector"
              placeholder="Sector"
              options={ClientSector}
              withSearch
              withDisplaySort
            />
          </LabelWrapper>
        </Col>
        <Col xs="1" />
        <Col xs="6">
          <Form.Label className="pt-2 me-3">
            Company Logo (158px x 72px)
          </Form.Label>
          <DarkBlueOutlinedButton onClick={() => inputFileRef.current?.click()}>
            Upload an image
          </DarkBlueOutlinedButton>
          <InputFileUpload
            ref={inputFileRef}
            type="file"
            id="input-file-upload"
            onChange={handleChange}
          />
          {file && <span className="ms-3">{file.name}</span>}
        </Col>
        <Col xs="5">
          <LabelWrapperWithValidation
            name="address.street"
            label="Company Address"
            fixedWidth="8rem"
          >
            <TextInputRHF name="address.street" placeholder="Street Address" />
          </LabelWrapperWithValidation>
        </Col>
        <Col xs="2">
          <ValidationLayout name="address.city">
            <TextInputRHF name="address.city" placeholder="City" />
          </ValidationLayout>
        </Col>
        <Col xs="2">
          <ValidationLayout name="address.zipCode">
            <TextInputRHF
              name="address.zipCode"
              placeholder="Zip/Postal Code"
            />
          </ValidationLayout>
        </Col>
        <Col xs="2" className="d-flex">
          <ValidationLayout name="address.country">
            <DropdownInputRHF
              name="address.countryCode"
              placeholder="Country"
              options={countries}
              withSearch
            />
          </ValidationLayout>
        </Col>
        <Col xs="12" className="d-flex">
          <FormLabel className="pt-2 me-3" $fixedWidth="8rem">
            Financial Year
          </FormLabel>
          <div className="d-flex gap-4 flex-grow-1">
            <LabelWrapperWithValidation name="fromDate" label="From">
              <DayMonthInputRHF
                name="fromDate"
                rules={{
                  required: "Required.",
                  validate: {
                    validateDayMonth: (value: string) => checkDayMonth(value) || "Invalid",
                    validateDuplicate: (value: string) => checkFinancialDate(toDate, value) || "Start date, end date: must be different dates",
                  }
                }}
              />
            </LabelWrapperWithValidation>
            <LabelWrapperWithValidation name="toDate" label="To">
              <DayMonthInputRHF
                name="toDate"
                rules={{
                  required: "Required.",
                  validate: {
                    validateDayMonth: (value: string) => checkDayMonth(value) || "Invalid",
                    validateDuplicate: (value: string) => checkFinancialDate(value, fromDate) || "Start date, end date: must be different dates",
                  }
                }}
              />
            </LabelWrapperWithValidation>
          </div>
        </Col>
        <Col xs="12" className="d-flex">
          <FormLabel className="pt-2 me-3">
            Preferred Reporting Currency
          </FormLabel>
          <div className="d-flex gap-3 flex-grow-1">
            <DisplayCurrencySymbol>{currencySymbol}</DisplayCurrencySymbol>
            <ValidationLayout name="preferredReportingCurrency">
              <div className="w-50">
                <DropdownInputRHF
                  name="preferredReportingCurrency"
                  placeholder="Currency"
                  options={currencyOptions}
                  rules={{ required: "Required." }}
                  withSearch
                />
              </div>
            </ValidationLayout>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ClientInformation;
