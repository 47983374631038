import { debounce } from "lodash";
import React, { useState } from "react";
import { InputBox, ClearIcon, SearchIcon } from "./styles";
import { useTaskManagement } from "../../../../contexts/TaskManagementProvider";

function SearchBox() {
  const [searchText, setSearchText] = useState<string>("");
  const { setFilter } = useTaskManagement();

  const onSearchDispatch = (value: string) => {
    setFilter((currentFilter) => {
      return { ...currentFilter, search: value === "" ? null : value };
    });
  };

  const debounceSearchText = React.useCallback(
    debounce((nextValue: string) => onSearchDispatch(nextValue), 250),
    []
  );

  return (
    <div className="position-relative">
      <InputBox
        value={searchText}
        data-testid="searchBox"
        placeholder="Search"
        maxLength="255"
        onChange={(e: any) => {
          setSearchText(e.target.value);
          debounceSearchText(e.target.value);
        }}
      />
      <SearchIcon />
      {searchText && (
        <ClearIcon
          onClick={() => {
            setSearchText("");
            onSearchDispatch("");
          }}
        />
      )}
    </div>
  );
}

export default SearchBox;
