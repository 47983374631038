import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import { DarkBlueSolidButton } from "../common";

export const DropDownWrapper = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.body};

  & div.options {
    position: absolute;
    display: none;
    z-index: 10;
    height: 0;
    margin: 0.25rem 0;
    padding: 0 0.25rem;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #ddd;
    max-width: calc(100% - 1.25rem);
    min-width: 100%;
  }

  & div.options.show {
    display: block;
    min-height: fit-content;

    height: unset;
  }

  & div.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border: 2px solid ${({ theme }) => theme.lightGreyInput.primaryColor};
    border-radius: 6px;

    & * {
      box-shadow: unset;
      border: 1px solid #ddd;
    }

    & input {
      border: none;
    }

    & label {
      border: none;
      border-right: 1px solid #ddd;
      margin: 0;
    }

    & svg {
      border: none;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const Chevron = styled(FaChevronDown)`
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
`;

export const Input = styled.div<{ $paddingEnd: boolean }>`
  padding: 0.375rem 0.75rem !important;
  background-color: ${({ theme }) => theme.lightGreyInput.secondaryColor};
  color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};
  border: 2px solid ${({ theme }) => theme.lightGreyInput.primaryColor};
  border-radius: 6px;
  -webkit-transition: padding-right 0.1s ease;
  transition: padding-right 0.1s ease;
  min-height: 2.5rem;
  &.is-invalid {
    background-image: none !important;
  }
`;

export const DropdownValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};

  margin-bottom: 0;
  padding-left: 0 !important;
  overflow: auto;
  max-height: 200px;
  padding-inline-start: 10px;
  -webkit-transition: height 0.1s ease;
  transition: height 0.1s ease;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollTrack};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollBar};
    border-radius: 16px;
  }
`;

export const MasqueradeValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.lightGreyInput.primaryColor};
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const MasqueradeLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-weight: bold;
`;

export const GoButton = styled(DarkBlueSolidButton)`
  min-width: fit-content;
  font-weight: normal;
  padding: 2px 12px;
`;
