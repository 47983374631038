import { useState, createContext, useContext, useEffect } from "react";
import { useNotification } from "../../../components/NotificationProvider";
import { NameValue } from "../../../models/name-value";
import { PaginationModel } from "../../../models/pagination";
import { SelectedModuleFeature } from "../../../models/selected-module-feature";
import { Task } from "../../../models/task";
import { TaskFilter, TaskOrderByColumn } from "../../../models/task-filter";
import { TaskStatistic } from "../../../models/task-statistic";
import {
  filterTasks,
  getTaskStates,
  getTaskStatistic,
} from "../../../services/task.service";

interface TaskManagementContextType {
  selectedItem: SelectedModuleFeature;
  setSelectedItem: React.Dispatch<React.SetStateAction<SelectedModuleFeature>>;
  taskStates?: NameValue[];
  filter: TaskFilter;
  setFilter: React.Dispatch<React.SetStateAction<TaskFilter>>;
  isLoading: boolean;
  tasks: Task[];
  pagination: PaginationModel | undefined;
  statistic: TaskStatistic[] | undefined;
  selectedModuleChanged: (selected: SelectedModuleFeature) => void;
}

let TaskManagementContext = createContext<TaskManagementContextType>(null!);

export function TaskManagementProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedItem, setSelectedItem] = useState<SelectedModuleFeature>({
    module: "",
  });

  const initialFilter = {
    search: null,
    name: null,
    state: null,
    itemsPerPage: 10,
    currentPage: 1,
    orderBy: TaskOrderByColumn.UpdatedDate,
    sortDesc: false,
  };

  const { showError } = useNotification();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskStates, setTaskStates] = useState<NameValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<TaskFilter>({
    ...initialFilter,
    ...selectedItem,
  });
  const [pagination, setPagination] = useState<PaginationModel | undefined>(
    undefined
  );
  const [statistic, setStatistic] = useState<TaskStatistic[] | undefined>(
    undefined
  );

  const selectedModuleChanged = (selected: SelectedModuleFeature) => {
    setSelectedItem(selected);
    setFilter({
      ...initialFilter,
      ...selected,
    });
  };

  useEffect(() => {
    getTaskStatistic()
      .then((s) => setStatistic(s))
      .catch(() => showError(undefined, "Can't fetch task statistic"));
  }, []);

  useEffect(() => {
    getTaskStates(selectedItem.module)
      .then((states: NameValue[]) => {
        setTaskStates(states);
      })
      .catch(() => showError(undefined, "Can't fetch task states"));
  }, [selectedItem]);

  useEffect(() => {
    setIsLoading(true);
    filterTasks(filter)
      .then((result) => {
        setTasks(result.items);
        setPagination({
          currentPage: result.pageIndex,
          totalItems: result.totalItems,
          pageSize: result.pageSize,
        });
      })
      .catch(() => showError(undefined, "Can't fetch tasks"))
      .finally(() => setIsLoading(false));
  }, [filter]);

  const value = {
    selectedItem,
    setSelectedItem,
    taskStates,
    filter,
    setFilter,
    isLoading,
    tasks,
    pagination,
    statistic,
    selectedModuleChanged,
  };

  return (
    <TaskManagementContext.Provider value={value}>
      {children}
    </TaskManagementContext.Provider>
  );
}

export function useTaskManagement() {
  return useContext(TaskManagementContext);
}
