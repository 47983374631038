import { ModuleFeature } from "../models/module-feature";

export enum SlrHeaderType {
  Link,
  Dropdown,
}

interface HeaderInfo {
  type: SlrHeaderType;
  title: string;
  path: string;
}

export interface SlrHeaderItem extends HeaderInfo {
  items?: Array<HeaderInfo>;
  featureType: ModuleFeature | null;
}

const CoreHeaders: Array<SlrHeaderItem> = [
  {
    type: SlrHeaderType.Link,
    title: "Home",
    path: "/",
    featureType: null,
  },
];

const SlrAdminFeatureHeaders: Array<SlrHeaderItem> = [
  {
    type: SlrHeaderType.Link,
    title: "Client Management",
    path: "/clients",
    featureType: ModuleFeature.ClientManagement,
  },
  {
    type: SlrHeaderType.Link,
    title: "User Management",
    path: "/users",
    featureType: ModuleFeature.UserManagement,
  },
];

const SlrGlobalFeatureHeaders: Array<SlrHeaderItem> = [
  {
    type: SlrHeaderType.Link,
    title: "Tasks",
    path: "/tasks",
    featureType: ModuleFeature.TaskManagement,
  },
  {
    type: SlrHeaderType.Link,
    title: "Documents",
    path: "/documents",
    featureType: ModuleFeature.DocumentManagement,
  },
];

export function SlrAdminHeaders(
  accessibleFeature: Array<ModuleFeature>
): Array<SlrHeaderItem> {
  var globalFeatures = SlrGlobalFeatureHeaders.filter((x) =>
    accessibleFeature.some((af) => af === x.featureType)
  );

  return [...CoreHeaders, ...SlrAdminFeatureHeaders, ...globalFeatures];
}

export function SlrClientHeaders(
  accessibleFeature: Array<ModuleFeature>
): Array<SlrHeaderItem> {
  return SlrGlobalFeatureHeaders.filter((x) =>
    accessibleFeature.some((af) => af === x.featureType)
  );
}
