import { handleResponse } from "../../../helpers/handle-response";
import sessionService from "../../../services/session.service";
import SearchUser from "../models/search-user";

export function searchUsers(filter: SearchUser) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };

  const { sortedByName, sortedByStatus, currentPage, itemPerPage, searchText } =
    { ...filter };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/users?` +
      new URLSearchParams({
        sortedByName,
        sortedByStatus: sortedByStatus ?? "",
        currentPage: currentPage.toString(),
        itemPerPage: itemPerPage.toString(),
        searchText: searchText ?? "",
      }),
    requestOptions
  ).then(handleResponse);
}
