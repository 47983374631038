import styled from "styled-components";

export const UserManagementLayout = styled.div`
  background-color: ${({ theme }) => theme.layout.background} !important;
  color: ${({ theme }) => theme.layout.text} !important;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 2rem;

  color: ${({ theme }) => theme.clientManagement.primaryText};
`;
