import styled from "styled-components";
import { Select } from "../common";

export const DropdownLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 25%;
  gap: 1rem;

  select {
    color: ${({ theme }) => theme.clientManagement.primaryText};
    font-weight: bold;
    width: fit-content;
  }
`;

export const FilterDropdown = styled(Select)`
  border: none;
`;
