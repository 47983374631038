import { handleResponse, handleBlobResponse } from "../helpers/handle-response";
import UploadFiles from "../models/upload-files";
import { DocumentOption } from "../models/document-option";
import { DocumentQuery } from "../models/document-query";

export function uploadFiles(payload: UploadFiles) {
  const formData = new FormData();
  const {
    module,
    activityType,
    documentPurpose,
    activityId,
    activity,
    files,
    assignable,
  } = payload;

  if (module !== null) {
    formData.append("Module", module.toString());
  }
  if (activityType !== null)
    formData.append("ActivityType", activityType.toString());
  if (documentPurpose !== null)
    formData.append("DocumentPurpose", documentPurpose.toString());
  if (activityId !== null) formData.append("ActivityId", activityId.toString());
  if (activity !== null) formData.append("Activity", activity);
  if (assignable !== null) formData.append("Assignable", assignable + "");
  if (files !== null)
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      formData.append("Files", file!, file!.name);
    }

  const requestOptions = {
    method: "POST",
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/`,
    requestOptions
  ).then(handleResponse);
}

export function searchFiles(payload: DocumentQuery) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const {
    search,
    module,
    type,
    activityType,
    purpose,
    activity,
    creator,
    itemsPerPage,
    currentPage,
    orderBy,
    showDeletedItems,
    sortDesc,
  } = payload;
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents?` +
      new URLSearchParams({
        search: search || "",
        module: module || "",
        type: type || "",
        activityType: activityType || "",
        documentPurpose: purpose || "",
        activity: activity || "",
        creator: creator || "",
        itemsPerPage: itemsPerPage.toString(),
        currentPage: currentPage.toString(),
        orderBy: orderBy.toString(),
        showDeletedItems: showDeletedItems.toString(),
        sortDesc: sortDesc.toString(),
      }),
    requestOptions
  ).then(handleResponse);
}

export function deleteDocument(fileId: string) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/${fileId}`,
    requestOptions
  ).then(handleResponse);
}

export function deletePermanentlyDocument(fileId: string) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/${fileId}/permanently`,
    requestOptions
  ).then(handleResponse);
}

export function restoreDocument(fileId: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/${fileId}/restore`,
    requestOptions
  ).then(handleResponse);
}

export function downloadDocument(fileId: string) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/${fileId}/download`,
    requestOptions
  ).then(handleBlobResponse);
}

export function getModules(): Promise<DocumentOption[]> {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/modules`,
    requestOptions
  ).then(handleResponse);
}

export function getFilterOptions(showDeletedItems: boolean): Promise<any> {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/documents/filter-options?` +
      new URLSearchParams({
        showDeletedItems: showDeletedItems.toString(),
      }),
    requestOptions
  ).then(handleResponse);
}
