import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { ModalContentState } from "../../models/modal-content-state";
import { StyledSpinner } from "../common";
import { ImportingWrapper, Header, RoundedModal } from "./styles";
import UploadFilesForm from "./UploadFilesForm";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  fetchFiles: () => void;
};

function UploadFilesModal({ showModal, handleCloseModal, fetchFiles }: Props) {
  const [modalContentState, setModalContentState] = useState<ModalContentState>(
    ModalContentState.Common
  );

  useEffect(() => {
    if (modalContentState === ModalContentState.Successful) {
      handleCloseModal();
      fetchFiles();
    }
  }, [modalContentState]);

  return (
    <RoundedModal
      show={showModal}
      centered
      onShow={() => setModalContentState(ModalContentState.Common)}
      onHide={handleCloseModal}
    >
      <Header>
        <IoClose size={25} onClick={handleCloseModal} />
      </Header>
      <Modal.Body>
        {(modalContentState === ModalContentState.Common ||
          modalContentState === ModalContentState.Nothing ||
          modalContentState === ModalContentState.FileTypeError) && (
          <UploadFilesForm
            setModalContentState={setModalContentState}
            modalContentState={modalContentState}
          />
        )}
        {modalContentState === ModalContentState.Importing && (
          <ImportingWrapper>
            <StyledSpinner animation="border" />
            <div>The file is being imported, please wait...</div>
          </ImportingWrapper>
        )}
      </Modal.Body>
    </RoundedModal>
  );
}

export default UploadFilesModal;
