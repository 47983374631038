import { Button, Form, FormControl, Nav, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { NavDropDownAuto } from "./NavDropDownAuto";
import checkBold from "./check-bold.svg";
import closeThick from "./close-thick.svg";

export const StyledButton = styled.button`
  padding: 6px 12px;
  transition: color 0.15s;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: bold;
  min-width: 120px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  :hover,
  :focus,
  :active {
    box-shadow: none;
  }
  :disabled {
    background-color: ${({ theme }) =>
      theme.disabledButton.primaryColor} !important;
    color: ${({ theme }) => theme.disabledButton.secondaryColor}!important;
    border-color: ${({ theme }) => theme.disabledButton.primaryColor}!important;
  }
`;
export const OrangeOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.orangeButton.secondaryColor};
  color: ${({ theme }) => theme.orangeButton.primaryColor};
  border-color: ${({ theme }) => theme.orangeButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.orangeButton.primaryColor};
    color: ${({ theme }) => theme.orangeButton.secondaryColor};
    border-color: ${({ theme }) => theme.orangeButton.primaryColor};
  }
`;
export const OrangeSolidButton = styled(OrangeOutlinedButton)`
  background-color: ${({ theme }) => theme.orangeButton.primaryColor};
  color: ${({ theme }) => theme.orangeButton.secondaryColor};
  border-color: ${({ theme }) => theme.orangeButton.primaryColor};
`;
export const DarkBlueOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.darkBlueButton.secondaryColor};
  color: ${({ theme }) => theme.darkBlueButton.primaryColor};
  border-color: ${({ theme }) => theme.darkBlueButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.darkBlueButton.primaryColor};
    color: ${({ theme }) => theme.darkBlueButton.secondaryColor};
    border-color: ${({ theme }) => theme.darkBlueButton.primaryColor};
  }
`;
export const DarkBlueSolidButton = styled(DarkBlueOutlinedButton)`
  background-color: ${({ theme }) => theme.darkBlueButton.primaryColor};
  color: ${({ theme }) => theme.darkBlueButton.secondaryColor};
  border-color: ${({ theme }) => theme.darkBlueButton.primaryColor};
`;
export const MediumBlueOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.mediumBlueButton.secondaryColor};
  color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
  border-color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
    color: ${({ theme }) => theme.mediumBlueButton.secondaryColor};
    border-color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
  }
`;
export const MediumBlueSolidButton = styled(MediumBlueOutlinedButton)`
  background-color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
  color: ${({ theme }) => theme.mediumBlueButton.secondaryColor};
  border-color: ${({ theme }) => theme.mediumBlueButton.primaryColor};
`;
export const BlueOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.blueButton.secondaryColor};
  color: ${({ theme }) => theme.blueButton.primaryColor};
  border-color: ${({ theme }) => theme.blueButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.blueButton.primaryColor};
    color: ${({ theme }) => theme.blueButton.secondaryColor};
    border-color: ${({ theme }) => theme.blueButton.primaryColor};
  }
`;
export const BlueSolidButton = styled(BlueOutlinedButton)`
  background-color: ${({ theme }) => theme.blueButton.primaryColor};
  color: ${({ theme }) => theme.blueButton.secondaryColor};
  border-color: ${({ theme }) => theme.blueButton.primaryColor};
`;
export const RedOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.redButton.secondaryColor};
  color: ${({ theme }) => theme.redButton.primaryColor};
  border-color: ${({ theme }) => theme.redButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.redButton.primaryColor};
    color: ${({ theme }) => theme.redButton.secondaryColor};
    border-color: ${({ theme }) => theme.redButton.primaryColor};
  }
`;

export const RedSolidButton = styled(RedOutlinedButton)`
  background-color: ${({ theme }) => theme.redButton.primaryColor};
  color: ${({ theme }) => theme.redButton.secondaryColor};
  border-color: ${({ theme }) => theme.redButton.primaryColor};
`;
export const Select = styled(Form.Select)`
  background-color: ${({ theme }) => theme.dropdownInput.background};
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
  color: ${({ theme }) => theme.dropdownInput.text};
  border-color: ${({ theme }) => theme.dropdownInput.borderColor};
  border-width: 2px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  :focus {
    background-color: ${({ theme }) => theme.dropdownInput.background};
    box-shadow: none;
    border-color: ${({ theme }) => theme.dropdownInput.borderColor};
    color: ${({ theme }) => theme.dropdownInput.text};
  }
  :disabled {
    color: ${({ theme }) => theme.dropdownInput.disabledText};
  }
`;
export const StyledInput = styled(FormControl)`
  border-width: 2px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  ::placeholder {
    color: ${({ theme }) => theme.styledInput.placeholderText};
    font-style: normal;
  }
  :focus {
    box-shadow: none;
  }

  &.is-invalid {
    border-color: #e51935 !important;
    color: #e51935 !important;
  }
  &.is-valid {
    color: #24b907 !important;
    border-color: #24b907 !important;
  }

  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }
`;
export const GreyOutlinedInput = styled(StyledInput)`
  background-color: ${({ theme }) => theme.greyInput.secondaryColor};
  color: ${({ theme }) => theme.greyInput.primaryColor};
  border-color: ${({ theme }) => theme.greyInput.primaryColor};

  :focus {
    background-color: ${({ theme }) => theme.greyInput.secondaryColor};
    border-color: ${({ theme }) => theme.greyInput.primaryColor};
    color: ${({ theme }) => theme.greyInput.primaryColor};
  }
`;
export const LightGreyOutlinedInput = styled(StyledInput)`
  background-color: ${({ theme }) => theme.lightGreyInput.secondaryColor};
  color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};
  border-color: ${({ theme }) => theme.lightGreyInput.primaryColor};

  :focus {
    background-color: ${({ theme }) => theme.lightGreyInput.secondaryColor};
    border-color: ${({ theme }) => theme.lightGreyInput.primaryColor};
    color: ${({ theme }) => theme.lightGreyInput.tertiaryColor};
  }
`;
export const WhiteSolidInput = styled(StyledInput)`
  background-color: ${({ theme }) => theme.whiteInput.primaryColor};
  color: ${({ theme }) => theme.whiteInput.secondaryColor};
  border-color: ${({ theme }) => theme.whiteInput.primaryColor};

  :focus {
    background-color: ${({ theme }) => theme.whiteInput.primaryColor};
    color: ${({ theme }) => theme.whiteInput.secondaryColor};
    border-color: ${({ theme }) => theme.whiteInput.primaryColor};
  }
`;
export const StyledSpinner = styled(Spinner)`
  width: 7rem;
  height: 7rem;
  border-width: 1rem;
  color: ${({ theme }) => theme.spinnerColor.background};

  margin-bottom: 1rem;
`;

export const GreyOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.greyButton.secondaryColor};
  color: ${({ theme }) => theme.greyButton.primaryColor};
  border-color: ${({ theme }) => theme.greyButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.greyButton.primaryColor};
    color: ${({ theme }) => theme.greyButton.secondaryColor};
    border-color: ${({ theme }) => theme.greyButton.primaryColor};
  }
`;

export const GreenOutlinedButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.greenButton.secondaryColor};
  color: ${({ theme }) => theme.greenButton.primaryColor};
  border-color: ${({ theme }) => theme.greenButton.primaryColor};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.greenButton.primaryColor};
    color: ${({ theme }) => theme.greenButton.secondaryColor};
    border-color: ${({ theme }) => theme.greenButton.primaryColor};
  }
`;

export const GreenSolidButton = styled(GreenOutlinedButton)`
  background-color: ${({ theme }) => theme.greenButton.primaryColor};
  color: ${({ theme }) => theme.greenButton.secondaryColor};
  border-color: ${({ theme }) => theme.greenButton.primaryColor};
`;
