import ListingClient from "../../models/listing-client";
import {
  CompanyName,
  DefaultLogo,
  EditClientBtn,
  Logo,
  StatusCol,
  ClientManagementTable as Table,
} from "./styles";
import { useNavigate } from "react-router";
import { useCallback, useMemo } from "react";
import AccountInfoCol from "../../../../components/AccountInfoCol";
import { ClientAccountLevel } from "../../../../enums/client-account-level";

type Props = {
  clients: Array<ListingClient>;
  isSearching: boolean;
};

function ClientManagementTable({ clients, isSearching }: Props) {
  const navigate = useNavigate();

  const editHandler = useCallback(
    (id: number) => {
      navigate(`/clients/${id}`);
    },
    [navigate]
  );

  const renderNoContentText = useMemo(
    () =>
      isSearching
        ? "No data found."
        : "There are currently no clients. Please add a new client.",
    [isSearching]
  );

  const renderLogo = useCallback((image: string) => {
    return !!image ? <Logo src={image} alt="Client Logo" /> : <DefaultLogo />;
  }, []);

  return (
    <Table>
      <tbody>
        {clients && clients.length > 0 ? (
          clients.map((client: ListingClient) => (
            <tr key={client.id}>
              <td>{renderLogo(client.image)}</td>
              <td>
                <CompanyName>{client.name}</CompanyName>
              </td>
              <AccountInfoCol
                label="SLR Business"
                value={client.internalSlr ? "Yes" : "No"}
              />
              <AccountInfoCol label="Country" value={client.country} />
              <AccountInfoCol label="Account Type" value={client.accountType} />
              <AccountInfoCol
                label="Account Level"
                value={
                  ClientAccountLevel[
                    client.accountLevel as keyof typeof ClientAccountLevel
                  ]
                }
              />
              <AccountInfoCol
                label="No. of active users"
                value={client.numOfActiveUsers}
              />
              <td>
                <StatusCol $isActive={client.active}>
                  {client.active ? "Active" : "Inactive"}
                </StatusCol>
              </td>
              <td>
                <EditClientBtn onClick={() => editHandler(client.id)}>
                  Edit
                </EditClientBtn>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>{renderNoContentText}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ClientManagementTable;
