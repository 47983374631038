import { ErrorMessage } from "@hookform/error-message";
import { Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { Control, Controller, FieldValues } from "react-hook-form";
import { GreyOutlinedInput } from "../../../components/common";

type Props = {
  name: string;
  value?: any;
  as?: string;
  control: Control<FieldValues, object>;
  rules: any;
  type?: string;
  placeholder: string;
  label: string;
  maxLength?: number;
};

function ControlledInput({
  name,
  value,
  as,
  control,
  rules,
  type = "text",
  placeholder,
  label,
  maxLength = 255,
}: Props) {
  return (
    <Controller
      name={name}
      defaultValue={value ? value : ""}
      control={control}
      render={({ field, formState }) => {
        const { errors } = formState;
        return (
          <FormGroup className="w-100" as={Row}>
            <Col xs={2}>
              <FormLabel>{label}</FormLabel>
            </Col>
            <Col xs={10}>
              <GreyOutlinedInput
                placeholder={placeholder}
                type={type}
                isInvalid={!!errors[name]}
                maxLength={maxLength}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) =>
                  message !== undefined ? (
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-start"
                    >
                      {message}
                    </Form.Control.Feedback>
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
          </FormGroup>
        );
      }}
      rules={rules}
    />
  );
}

export default ControlledInput;
