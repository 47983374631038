import styled from "styled-components";

export const FilterLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  padding-right: 10rem;

  border-bottom: ${({ theme }) => theme.clientManagement.primaryBorder} solid
    4px;

  color: ${({ theme }) => theme.clientManagement.secondaryText};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;
