import styled from "styled-components";

export const NotificationCountWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const NotificationCount = styled.div`
  position: absolute;
  top: -1.35rem;
  right: -1.25rem;
  font-size: 0.7em;
  height: 1.25rem;
  min-width: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 1.5rem;
  color: ${({ theme }) => theme.taskCount.color};
  background-color: ${({ theme }) => theme.taskCount.background};
  text-align: center;
`;
