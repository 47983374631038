import styled from "styled-components";

export const DisplayCurrencySymbol = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  background-color: lightgrey;
  text-align: center;
  line-height: 2.25rem;
  border-radius: 0.5rem;
`;

export const InputFileUpload = styled.input`
  display: none;
`;
