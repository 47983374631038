import { handleResponse } from "../helpers/handle-response";

export function deletePermanentlyUser(email: string) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/users/${email}`,
    requestOptions
  ).then(handleResponse);
}
