import { Container, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  NewPasswordFormLayout,
  ActionWrapper,
  PasswordInputGroup,
  IconWrapper,
  Title,
  Description,
} from "./styles";
import {
  GreyOutlinedButton,
  GreyOutlinedInput,
} from "../../../components/common";
import {
  atLeastANumber,
  oneUpperCase,
  oneSpecialCharacter,
} from "../../../helpers/regex-pattern";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { strings } from "../../../localization/en";
import SuccessModal from "../../../components/SuccessModal";
import { ErrorMessage } from "@hookform/error-message";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import sessionService from "../../../services/session.service";
import { useNotification } from "../../../components/NotificationProvider";
import { ApiResponse } from "../../../models/api-response";
import LoadingSpinner from "../../../components/LoadingSpinner";

function NewPasswordForm() {
  const navigate = useNavigate();
  const { showError } = useNotification();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(true);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isRePasswordShown, setIsRePasswordShown] = useState(false);

  const { control, handleSubmit, getValues, formState, trigger } = useForm({
    defaultValues: {
      password: "",
      rePassword: "",
    },
    mode: "all",
  });
  const { errors, dirtyFields } = formState;

  useEffect(() => {
    if (!email || !token) {
      return;
    }

    sessionService
      .checkEmailToken(email, token)
      .then(({ isTokenValid }) => {
        setIsValidToken(isTokenValid);
      })
      .catch((error) => {
        if (error.status === 404) {
          showError(undefined, "Can't find your account");
          navigate("/");
        } else {
          showError(undefined, "Can't verify create password token");
          setIsValidToken(false);
        }
      })
      .finally(() => setIsLoadingPage(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((_data) => {
    if (email && token) {
      setIsLoading(true);
      sessionService
        .resetPassword(email, _data.password, token)
        .then((__) => {
          setShowModal(true);
        })
        .catch((err) => {
          const errorResponse = err.data as ApiResponse;
          if (errorResponse && errorResponse.status === "Inactive") {
            setShowErrorModal(true);
          } else {
            showError(undefined, err?.error);
          }
        })
        .finally(() => setIsLoading(false));
    }
  });

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleResendLink = useCallback(() => {
    if (email) {
      setIsLoading(true);
      sessionService
        .sendResetPasswordEmail(email)
        .then(() => {
          setShowEmailSentModal(true);
        })
        .catch(() => showError(undefined, "Can't resend reset password email."))
        .finally(() => setIsLoading(false));
    }
  }, [email, showError]);

  if (isLoadingPage) {
    return (
      <NewPasswordFormLayout>
        <LoadingSpinner />
      </NewPasswordFormLayout>
    );
  }

  if (!isValidToken) {
    return (
      <>
        <NewPasswordFormLayout>
          <Title className="mb-3">Your password reset link has expired</Title>
          <Description className="mb-3">
            Please click on the 'Resend Link' button below to receive a fresh
            link to the email address registered to your account.
          </Description>
          <GreyOutlinedButton disabled={isLoading} onClick={handleResendLink}>
            <Spinner
              animation="border"
              size="sm"
              className="me-2"
              hidden={!isLoading}
            />
            Resend Link
          </GreyOutlinedButton>
        </NewPasswordFormLayout>
        <SuccessModal
          showModal={showEmailSentModal}
          title="Password reset link resent"
          handleCloseModal={() => {
            setShowEmailSentModal(false);
            navigate("/login");
          }}
          content="Please check your mailbox and follow steps in email to create password for your account."
          action="OK"
        />
      </>
    );
  }

  return (
    <NewPasswordFormLayout>
      <Title>Create New Password</Title>
      <Description>{strings.new_password_note_line1}</Description>
      <Description>{strings.new_password_note_line2}</Description>
      <Container as={Form} onSubmit={onSubmit} className="mt-2">
        <Controller
          name="password"
          control={control}
          rules={{
            required: "Required.",
            minLength: {
              value: 8,
              message: strings.new_password_not_meet_min_requirement,
            },
            validate: () => {
              if (
                oneUpperCase.test(getValues("password")) &&
                atLeastANumber.test(getValues("password")) &&
                oneSpecialCharacter.test(getValues("password"))
              )
                return true;
              else return strings.new_password_weak_password_hint;
            },
          }}
          render={({ field }) => {
            const { onChange, ...rest } = field;
            return (
              <PasswordInputGroup>
                <GreyOutlinedInput
                  placeholder={strings.type_new_password}
                  isInvalid={!!errors.password}
                  isValid={!errors.password && dirtyFields.password}
                  type={isPasswordShown ? "text" : "password"}
                  minLength={8}
                  onChange={(e: any) => {
                    onChange(e);
                    if (dirtyFields.rePassword) trigger("rePassword");
                  }}
                  {...rest}
                />
                <IconWrapper
                  onClick={() => setIsPasswordShown((prev) => !prev)}
                >
                  <Icon path={!isPasswordShown ? mdiEyeOff : mdiEye} size={1} />
                </IconWrapper>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) =>
                    message !== undefined ? (
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {message}
                      </Form.Control.Feedback>
                    ) : (
                      <></>
                    )
                  }
                />
              </PasswordInputGroup>
            );
          }}
        />
        <Controller
          name="rePassword"
          control={control}
          rules={{
            validate: () => {
              if (getValues("password") !== getValues("rePassword"))
                return strings.password_not_match;
              else return true;
            },
          }}
          render={({ field }) => {
            return (
              <PasswordInputGroup>
                <GreyOutlinedInput
                  placeholder={strings.retype_new_password}
                  isInvalid={!!errors.rePassword}
                  isValid={!errors.rePassword && dirtyFields.rePassword}
                  type={isRePasswordShown ? "text" : "password"}
                  {...field}
                />
                <IconWrapper
                  onClick={() => setIsRePasswordShown((prev) => !prev)}
                >
                  <Icon
                    path={!isRePasswordShown ? mdiEyeOff : mdiEye}
                    size={1}
                  />
                </IconWrapper>
                <ErrorMessage
                  errors={errors}
                  name="rePassword"
                  render={({ message }) =>
                    message !== undefined ? (
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {message}
                      </Form.Control.Feedback>
                    ) : (
                      <></>
                    )
                  }
                />
              </PasswordInputGroup>
            );
          }}
        />
        <ActionWrapper>
          <GreyOutlinedButton
            disabled={
              !!errors.password ||
              !!errors.rePassword ||
              !dirtyFields.password ||
              !dirtyFields.rePassword ||
              isLoading
            }
            type="submit"
          >
            <Spinner
              animation="border"
              size="sm"
              className="me-2"
              hidden={!isLoading}
            />
            {strings.reset_password}
          </GreyOutlinedButton>
        </ActionWrapper>

        <SuccessModal
          title={strings.password_set_successful}
          content={strings.password_reset_successful_login_with_new_password}
          action={strings.login}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />

        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
          <Modal.Header closeButton />
          <Modal.Body>{strings.account_inactivate}</Modal.Body>
        </Modal>
      </Container>
    </NewPasswordFormLayout>
  );
}

export default NewPasswordForm;
