import { memo } from "react";
import { Radio, RadioGroupWrapper } from "./styles";
type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: string | boolean | null;
  setExternalValue: (value: string | boolean | null) => void;
  isInvalid?: boolean;
};
const RadioInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    isInvalid = undefined,
    onBlur,
    onFocus,
  }: Props) => {
    return (
      <RadioGroupWrapper>
        <Radio
          label="Yes"
          value={true}
          isInvalid={isInvalid}
          checked={
            externalValue !== undefined && externalValue !== null
              ? externalValue.toString().includes("true")
              : false
          }
          onChange={() => {
            setExternalValue(true);
          }}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <Radio
          label="No"
          value={false}
          isInvalid={isInvalid}
          checked={
            externalValue !== undefined && externalValue !== null
              ? externalValue.toString().includes("false")
              : false
          }
          onChange={() => {
            setExternalValue(false);
          }}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </RadioGroupWrapper>
    );
  }
);

export default RadioInput;
