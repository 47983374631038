import styled from "styled-components";

export const DropdownWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 1.25rem;

  > div {
    margin-left: auto;
    width: 60%;
  }
`;
