export const strings = {
  site_name: "B4SI",
  site_title: "B4SI Social Impact Compass",
  site_intro_header_line1: "Business for Societal Impact (B4SI)",
  site_intro_header_line2: "Social Impact Compass",
  assessment_intro_note:
    "The B4SI Social Impact Compass has been designed in conjunction with and for companies within the B4SI Network.",
  assessment_tool_bg_head: "Tool Background",
  welcome: "Welcome to CC Admin login",
  register: "Register",
  login: "Login",
  logout: "Logout",
  remember: "Remember Me",
  forgotten_password: "Forgotten Password",
  reset_password: "Reset Password",
  create_password: "Create Password",
  type_new_password: "*type new password",
  retype_new_password: "*re-type new password",
  new_password_note_line1:
    "The password must contain at least 8 characters, 1 upper case letter,",
  new_password_note_line2:
    "1 special character (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/), 1 numeric character (0-9).",
  new_password_not_meet_min_requirement:
    "Password provided does not meet minimum requirements.",
  new_password_weak_password_hint: `Password must contain at least 1 upper case letter, 1 special character (~!@#$%^&*_-+=\`|(){}[]:;"'<>,.?/), 1 numeric character (0-9).`,
  password_not_meet_requirement:
    "Password provided does not meet minimum requirements",
  password_not_match: "Passwords do not match",
  password_set_successful: "Success!",
  password_reset_successful_login_with_new_password:
    "Your password has been reset. Please login using your new password.",
  password_create_successful_login_with_new_password:
    "Your password has been created.",
  password_set_fail: "Your password has not been reset",
  password_set_fail_try_again:
    "An error occurred while reset your password. Please try to reset your password again.",
  password_reset_general_fail:
    "An error occurred while reset your password. Please check your email and try again later. If you feel it's an error, please contact us for support.",
  password_reset_network_fail:
    "Failed to connect to server. Please try again later",
  password_reset_email_not_registered:
    "Email not registered - please ensure it is correct or use the 'Contact Us' feature so we can help",
  password_reset_email_invalid:
    "Invalid email address - please check and re-enter",
  password_reset_sent: "Password reset sent",
  password_reset_modal_OK: "OK",
  password_reset_check_inbox:
    "Please click the link in the email we have just sent to reset your password",
  account_not_found: `This email address is not currently registered on the portal.<br/>If you are an SLR staff member, please contact the Digital Services Team - alternatively, please contact your Account Manager who will be able to help you.`,
  account_inactivate: `This user account is currently inactive and cannot access this site. If you are an SLR staff member, please contact the Digital Services Team - alternatively, please contact your SLR Account Manager who will be able to help you.`,
  contact_us_title: "Contact Request",
  contact_us_name_label: "Name",
  contact_us_company_label: "Company",
  contact_us_email_label: "Email",
  contact_us_phone_label: "Phone",
  contact_us_reason_placeholder: "reason for contact request",
  contact_us_invalid_email_format: "Please check email format.",
  contact_us_maximum_characters: "Maximum 500 characters",
  contact_us_thank_you_for_contact_us: "Thank you for contacting us",
  contact_us_contact_back_soon: "We will be in touch as soon as we can.",
  contact_us_contact: "Contact",
  contact_us_modal_OK: "OK",

  email_address: "Email Address",
  email_required_message:
    "You must enter the email address associated to this assessment link. Please try again.",
  password: "Password",
  username: "Username",
  username_required: "Username required",
  password_required: "Password required",
  email_required: "Email required",
  login_incorrect: "Email Address or Password incorrect",
  dashboard: "Dashboard",
  submitted_assessments: "Submitted Assessments",
  no_submitted_assessments: "No Submitted Assessments",
  no_unsubmitted_assessments: "No Unsubmitted Assessments",
  due_date: "Due Date",
  status: "Status",
  result: "Result",
  verification_steps: "Verification Steps",
  all_mandatory: "All fields are mandatory",
  firstname: "First Name",
  surname: "Surname",
  company: "Company",
  go_to_assessment: "Go to Assessment",
  view_result: "View Result",
  start_assessment: "Start Assessment",
  continue_assessment: "Continue Assessment",
  assessmentlink_notfound: "Assessment link not found.",
  pls_contact_manager: "Please contact your Account Manager.",
  invalid_assessment: "Invalid assessment",
  section: "Section",
  sub_section: "Sub-Section",
  qa: "Q's Answered",
  max_score: "Max Score",
  actual_score: "Actual Score",
  overall: "Overall",
  category: "Category",
  action: "Action",
  submit: "Submit",

  confirm_title: "Are you sure?",
  assessment_confirm_content:
    "Once the assessment is submitted you will not be able to amend it.",
  button_confirm: "Yes, I'm sure",
  button_refuse: "No, take me back to the assessment",

  assessment_msg_view_result:
    "Thank you for completing the assessment, click the below button to view the result.",
  assessment_btn_view_result: "View Result",
  assessment_modal_message_completed_assessment:
    "Assessment submitted. Select button below to view results.",

  warning_message:
    "All questions and priority for action responses are mandatory.",
  overall_label_content: "Overall Assessment Progress",

  button_next_content: "Next >",
  button_previous_content: "< Prev",
  button_save_content: "Save Draft",
  assessment_btn_submit: "Submit Assessment",

  assessment_incomplete: "Incomplete Assessment",
  assessment_incomplete_explain:
    "All responses & priority for action items must be completed before submission.",
  assessment_incomplete_cta:
    "Please complete the missing items before submitting the assessment.",
  assessment_incomplete_action: "OK",

  na_question: "N/A",
  action_priority: "Priority for action",
  action_priority_description_row1:
    " This priority for action is provided beside each question to allow self-assessment of priorities along.",
  action_priority_description_row2:
    "This aides prioritising next steps but will not affect final assessment results.",
  comment: "Comment",
  optional: "(optional)",

  contact_title_modal: "Reason for Contact Request",
  description_question: "Maximum {{characters}} characters",
  button_contact_us_content: "Contact Us",
  button_submit_content: "Submit Request",
  placeholder_first_name: "First Name",
  placeholder_sur_name: "Surname",
  placeholder_company_name: "Company Name",
  placeholder_email_address: "Email Address: email@example.com",
  placeholder_question: "Reason for request",

  assessment_saved: "Assessment Saved",
  assessment_not_thing: "Nothing to save",

  success: "success",
  warning: "warning",

  contact_request_sent_message: "Contact request sent",

  request_submitted_successfully: "Request submitted successfully.",

  assessment_result_header: "Social Impact Compass Results - ",
  assessment_result_content:
    "The results of this assessment aim to provide you with a gauge of where your company is currently sitting on its social impact journey and to provide suggestions for how to evolve the strategy. More detail about this tool and how it works can be found in the B4SI Social Impact Compass Guidance Manual.",
  assessment_result_intro:
    "This report has been produced based on the responses of a social impact practitioner within your organisation, their responses to a range of questions have been used to create an assessment of your organisations level of maturity.",
  overall_maturity_label: "Overall Maturity Level: ",
  export_to_pdf_button_uppercase: "Export to PDF",
  export_to_pdf_tooltip:
    "Click this button to export results, copy completed assessment and case study.",
  book_appointment_button: "Contact the B4SI Team",
  appointment_tooltip:
    "This will send an automated request for your Account Manager to arrange an appointment with you.",
  result_guidance_tooltip:
    "Click this button to download a comprehensive results guide and suggested next actions.",
  sub_section_breakdown_header: "Section Results Breakdown",

  // admin
  copyright: `Copyright © ${new Date().getFullYear()} corporate-citizenship.com`,

  // sidebar
  sidebar_dashboard: "ADMIN DASHBOARD",

  sidebar_section: "SECTIONS",
  sidebar_new_section: "Add Section",
  sidebar_manage_section: "Manage Sections",
  sidebar_view_section: "View Sections",

  sidebar_subsection: "SUB-SECTIONS",
  sidebar_new_subsection: "Add Sub-Section",
  sidebar_manage_subsection: "Manage Sub-Sections",
  sidebar_view_subsection: "View Sub-Sections",

  sidebar_question: "QUESTIONS",
  sidebar_new_question: "Add Question",
  sidebar_manage_question: "Manage Questions",
  sidebar_view_question: "View Questions",

  sidebar_help: "HELP",

  // dashboard
  dashboard_error_invalid_date: `Date range not allowed. 'From' date must be less than or the
  same as 'To' date.`,

  dashboard_not_started_label: "Not Started",
  dashboard_in_progress_label: "In Progress",
  dashboard_completed_label: "Submitted",
  dashboard_show_all_label: "Show All",

  dashboard_maturity_thoughtful_label: "Thoughtful",
  dashboard_maturity_purposeful_label: "Purposeful",
  dashboard_maturity_strategic_label: "Strategic",
  dashboard_maturity_transformational_label: "Transformational",

  dashboard_title: "B4SI Social Impact Compass: Administration Portal",
  dashboard_title_description:
    "A unique corporate citizenship administration dashboard and portal to create, modify and track B4SI assessments.",

  dashboard_create_assessment_title: "• Create & Send New Assessment",
  dashboard_create_assessment_placeholder: "Enter client email address here",
  dashboard_create_assessment_error_empty: "Email is required.",
  dashboard_create_assessment_error_format: "Wrong email format.",
  dashboard_create_assessment_send_button: "Send Assessment",
  dashboard_created_modal_title: "Assessment Sent",
  dashboard_created_modal_content: "Assessment sent to ",
  dashboard_created_modal_copy_button: "Copy",
  dashboard_created_modal_copied_button: "Copied",
  dashboard_created_modal_OK: "OK",

  dashboard_insight_title: "INSIGHTS",
  dashboard_insight_search_date_label: "Search by:",
  dashboard_insight_search_date_sent: "Date Sent",
  dashboard_insight_search_date_submitted: "Date Submitted",
  dashboard_insight_from_date_label: "From",
  dashboard_insight_to_date_label: "To",
  dashboard_insight_from_date_placeholder: "From Date",
  dashboard_insight_to_date_placeholder: "To Date",
  dashboard_insight_label_total: "Total",

  dashboard_assessment_resend_label_button: "Resend Assessment",
  dashboard_assessment_select_all: "Select all",
  dashboard_assessment_unselect_all: "Unselect all",
  dashboard_resend_modal_title: "Assessment(s) Resent",

  dashboard_assessment_table_search_label: "Search:",
  dashboard_assessment_table_maturity_label: "Maturity:",
  dashboard_assessment_table_search_placeholder: "Search",
  dashboard_assessment_table_status_label: "Status:",
  dashboard_assessment_table_show: "Show",
  dashboard_assessment_table_entries: "entries",
  dashboard_assessment_table_header_date_sent: "Date Sent",
  dashboard_assessment_table_header_first_name: "First Name",
  dashboard_assessment_table_header_surname: "Surname",
  dashboard_assessment_table_header_company: "Company",
  dashboard_assessment_table_header_status: "Status",
  dashboard_assessment_table_header_email: "Client Email Address",
  dashboard_assessment_table_header_link: "Assessment Link",
  dashboard_assessment_table_header_date_submitted: "Date Submitted",
  dashboard_assessment_table_header_maturity_attained: "Maturity Attained",
  dashboard_assessment_table_header_result_link: "Results Link",

  dashboard_assessment_table_link_content: "View Assessment",
  dashboard_assessment_table_link_result_content: "View Result",

  dashboard_assessment_table_not_found_email: "No email address found.",
  dashboard_assessment_table_empty: "No data found.",
  //common
  save_changes_button: "Save changes",
  label_answer_set: "Answer Set:",
  label_option_1: "Option 1",
  label_option_2: "Option 2",
  label_option_3: "Option 3",
  label_option_4: "Option 4",
  delete_confirm_title: "Delete confirmation",
  delete_confirm_modal_cancel: "Cancel",
  delete_confirm_modal_OK: "Yes, Delete it",
  delete_confirm_message: "Are you sure you want to delete this item?",
  table_header_edit: "Edit",
  table_header_delete: "Delete",

  //section
  section_add_title: "Add Section",
  section_edit_title: "Edit Section",
  section_delete_title: "Manage Sections",

  section_label_section: "Section Name:",
  section_label_description: "Description:",

  section_placeholder_section:
    "Add Section number and name here in the following format: #. Section Name",
  section_placeholder_description: "Add Section description here",

  section_error_section_empty: "Section Name cannot be empty.",
  section_error_section_format:
    "Wrong section name format. Please follow this format: #. Section Name",
  section_error_description_empty: "Description cannot be empty.",

  section_created_message: "Section Created.",
  section_edited_message: "Section Updated.",
  section_deleted_message: "Section Deleted.",

  section_table_header_section: "Section",

  //sub section
  subsection_add_title: "Add Sub-Section",
  subsection_edit_title: "Edit Sub-Section",

  subsection_label_subsection: "Name:",
  subsection_label_section: "Section:",

  subsection_placeholder_subsection:
    "Add Sub-Section number and name here in the following format: #. Sub-Section Name",
  subsection_placeholder_section: "Select section",

  subsection_error_subsection_empty: "Sub-Section Name cannot be empty.",
  subsection_error_subsection_format:
    "Wrong sub-section name format. Please follow this format: #. Sub-Section Name",
  subsection_error_section_empty: "Section must be selected.",
  subsection_error_all_options: "All options must have an answer.",

  subsection_created_message: "Sub-Section Created.",
  subsection_edited_message: "Sub-Section Updated.",
  subsection_deleted_message: "Sub-Section Deleted.",

  subsection_table_header_subsection: "Sub-Section",

  //question
  question_add_title: "Add Question",
  question_edit_title: "Edit Question",
  question_manage_title: "Manage Questions",

  question_error_subSection: "Sub-Section must be selected.",
  question_error_question: "Question cannot be empty.",
  question_error_order: "Order must be selected.",
  question_error_weighting: "Weight must be selected.",
  question_error_naOption: "'N/A' option must be selected.",

  question_label_question: "Question:",
  question_label_subsection: "Sub-Section:",
  question_label_order: "Question Order:",
  question_label_weighting: "Weighting:",
  question_label_NA: "N/A?",
  question_label_raw_score: "Raw Score:",
  question_label_guidance: "Guidance:",

  question_placeholder_subsection: "Select sub-section",
  question_placeholder_order: "Select order",
  question_placeholder_weighting: "Select weighting",
  question_placeholder_NA: "",

  question_tooltip_raw_score: "Value must be a whole number greater than 0.",

  question_created_message: "Question Created.",
  question_edited_message: "Question Updated.",
  question_deleted_message: "Question Deleted.",

  question_table_header_section: "Section",
  question_table_header_subsection: "Sub-Section",
  question_table_header_question: "Question",
  question_table_header_guidance: "Guidance",
  question_table_header_order: "Question Order",
  question_table_header_weighting: "Weighting",
  question_table_header_nAOptions: "N/A?",
  question_table_header_option1: "Option-1 Raw Value",
  question_table_header_option2: "Option-2 Raw Value",
  question_table_header_option3: "Option-3 Raw Value",
  question_table_header_option4: "Option-4 Raw Value",
  question_table_header_max_score: "Max Score",

  // help
  help_title: "Help & Guidance",
  help_title_description: "How to send assessments and track their progress",

  help_navigate_title: "• Navigating the portal",
  help_navigate_content:
    "Use the navigation pane menu (left hand side of page) to navigate around the portal.",

  help_create_assessment_title: "• Create & Send New Assessment",
  help_create_assessment_content:
    "To create & send a new assessment, enter the recipients email address in the blank field and select 'Send Assessment'.",

  help_insight_title: "• View Insights",
  help_insight_content_1: "Insights are defaulted to 'all time'.",
  help_insight_content_2:
    "To filter to a specific date range - use the date selection fields in the Insights section.",
  help_insight_content_3:
    "Insight numbers (Total, Not Started, In Progress & Completed) will all auto-adjust based on the date range selected.",
  help_insight_content_4:
    "Select 'Clear' to reset the filtering to 'All Time'.",

  help_assessment_list_title: "• Assessment List",
  help_assessment_list_content_1:
    "This is a dynamic list that will flex according to filters applied in the Insights section",
  help_assessment_list_content_2:
    "The 'Search Email Address' and Status type filtering can also be used to filter the assessment list.",
  help_assessment_list_content_3:
    "Select 'Show All' in the Status filter to revert to the full list.",

  // export
  export_to_pdf_button: "Export to PDF",
  export_to_csv_button: "CSV Export",
  export_to_csv_tooltip: "Export all lines showing in list below",
  export_to_csv_loading: "The file is being downloaded, please wait...",
  export_to_csv_completed: "Download complete. Check your download folder.",
  access_denied: "Access denied",

  // error
  server_error: "An error occurred. Please try again later.",
  unknown_error: "An unknown error occurred. Please try again later.",
  unauthorize_error: "Unauthorized Access. Please try to log in again.",
  session_timeout_error:
    "Your session timed out.<br />Please log in to continue.",
  forbidden_error:
    "Access Denied/Forbidden. You do not have permission to access this resource.",

  // error boundary
  boundary_error_header: "Oops!",
  boundary_error_content: "Something went wrong",
  boundary_error_description: "Please try again.",
  // page not found
  page_not_found_error_header: "404",
  page_not_found_error_content: "Page not found",

  login_error_email_registered: "Email not registered.",
  login_error_common_attempt:
    "Incorrect password. Please try again. You have {{X}} attempt(s) left.",
  login_error_max_attempt:
    "We have emailed you a link to reset your password. Please check your inbox or spam folder.",
  login_error_inactivate:
    "User inactivate. Please use 'Contact Us' feature so we can help",
  registration_email_verified_heading: "Your email has been verified!",
  registration_email_verified_body:
    "One of our Team will be in touch as soon as possible to complete your registration.",
};
