import { ErrorMessage } from "@hookform/error-message";
import { mdiEyeOff, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { Container, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { GreyOutlinedInput } from "../../components/common";
import {
  oneUpperCase,
  atLeastANumber,
  oneSpecialCharacter,
} from "../../helpers/regex-pattern";
import { strings } from "../../localization/en";
import {
  ActionWrapper,
  IconWrapper,
  CreatePasswordLayout,
  PasswordInputGroup,
  Title,
  Description,
  CreatePasswordWrapper,
  SaveButton,
} from "./styles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import sessionService from "../../services/session.service";
import { useNotification } from "../../components/NotificationProvider";
import LoadingSpinner from "../../components/LoadingSpinner";
import React from "react";
import SuccessModal from "../../components/SuccessModal";
import ErrorModal from "../../components/ErrorModal";

function CreatePassword() {
  const navigate = useNavigate();
  const { showError } = useNotification();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(true);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isRePasswordShown, setIsRePasswordShown] = useState<boolean>(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);

  const { control, handleSubmit, getValues, formState, trigger } = useForm({
    defaultValues: {
      password: "",
      rePassword: "",
    },
    mode: "all",
  });
  const { errors, dirtyFields } = formState;

  useEffect(() => {
    if (!email || !token) {
      return;
    }
    sessionService.checkEmailConfirmed(email).catch((err) => {
      if (err?.data && err.data?.status === "Inactive") {
        setShowErrorModal(true);
      }

      if (err?.data && err.data?.status === "NotFound") {
        navigate(`/contact-request?email=${email}`);
      }
      setIsLoadingPage(false);
      return;
    });

    sessionService
      .checkEmailToken(email, token)
      .then(({ isTokenValid }) => {
        setIsValidToken(isTokenValid);
      })
      .catch((err) => {
        if (err?.status === 404) {
          navigate(`/contact-request?email=${email}`);
        } else {
          showError(undefined, "Can't verify create password token");
          setIsValidToken(false);
        }
      })
      .finally(() => setIsLoadingPage(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((_data) => {
    if (email && token) {
      setIsLoading(true);
      sessionService
        .createPassword(email, _data.password, token)
        .then(
          (res) =>
            res?.token &&
            navigate("/mfa-setup", { state: { token: res.token } })
        )
        .catch((err) => {
          if (err?.data && err.data?.status === "Inactive") {
            setShowErrorModal(true);
          } else {
            showError(undefined, err?.error);
          }
        })
        .finally(() => setIsLoading(false));
    }
  });

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, []);

  const handleResendLink = useCallback(() => {
    if (email) {
      setIsLoading(true);
      sessionService
        .resendCreatePasswordEmail(email)
        .then(() => {
          setShowEmailSentModal(true);
        })
        .catch(() =>
          showError(undefined, "Can't resend create password email.")
        )
        .finally(() => setIsLoading(false));
    }
  }, [email, showError]);

  if (isLoadingPage) {
    return (
      <CreatePasswordLayout>
        <LoadingSpinner />
      </CreatePasswordLayout>
    );
  }

  if (!isValidToken) {
    return (
      <>
        <CreatePasswordLayout>
          <CreatePasswordWrapper>
            <Title>Your password creation link has expired</Title>
            <Description>
              Please click on the 'Resend Link' button below to receive a fresh
              link to the email address registered to your account.
            </Description>
            <SaveButton disabled={isLoading} onClick={handleResendLink}>
              <Spinner
                animation="border"
                size="sm"
                className="me-2"
                hidden={!isLoading}
              />
              Resend Link
            </SaveButton>
          </CreatePasswordWrapper>
        </CreatePasswordLayout>
        <SuccessModal
          showModal={showEmailSentModal}
          title="Password creation link resent"
          handleCloseModal={() => {
            setShowEmailSentModal(false);
            navigate("/login");
          }}
          content="Please check your mailbox and follow steps in email to create password for your account."
          action="OK"
        />
      </>
    );
  }

  return (
    <React.Fragment>
      <CreatePasswordLayout>
        <CreatePasswordWrapper>
          <Title>Create a new password</Title>
          <Description>{strings.new_password_note_line1}</Description>
          <Description>{strings.new_password_note_line2}</Description>
          <Container as={Form} onSubmit={onSubmit} className="mt-2">
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Required.",
                minLength: {
                  value: 8,
                  message: strings.new_password_not_meet_min_requirement,
                },
                validate: () => {
                  if (
                    oneUpperCase.test(getValues("password")) &&
                    atLeastANumber.test(getValues("password")) &&
                    oneSpecialCharacter.test(getValues("password"))
                  )
                    return true;
                  else return strings.new_password_weak_password_hint;
                },
              }}
              render={({ field }) => {
                const { onChange, ...rest } = field;
                return (
                  <PasswordInputGroup>
                    <GreyOutlinedInput
                      placeholder="New password"
                      isInvalid={!!errors.password}
                      isValid={!errors.password && dirtyFields.password}
                      type={isPasswordShown ? "text" : "password"}
                      minLength={8}
                      onChange={(e: any) => {
                        onChange(e);
                        if (dirtyFields.rePassword) trigger("rePassword");
                      }}
                      {...rest}
                    />
                    <IconWrapper
                      onClick={() => setIsPasswordShown((prev) => !prev)}
                    >
                      <Icon
                        path={!isPasswordShown ? mdiEyeOff : mdiEye}
                        size={1}
                      />
                    </IconWrapper>
                    <ErrorMessage
                      errors={errors}
                      name="password"
                      render={({ message }) =>
                        message !== undefined ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-start"
                          >
                            {message}
                          </Form.Control.Feedback>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </PasswordInputGroup>
                );
              }}
            />
            <Controller
              name="rePassword"
              control={control}
              rules={{
                validate: () => {
                  if (getValues("password") !== getValues("rePassword"))
                    return strings.password_not_match;
                  else return true;
                },
              }}
              render={({ field }) => {
                return (
                  <PasswordInputGroup>
                    <GreyOutlinedInput
                      placeholder="Confirm new password"
                      isInvalid={!!errors.rePassword}
                      isValid={!errors.rePassword && dirtyFields.rePassword}
                      type={isRePasswordShown ? "text" : "password"}
                      {...field}
                    />
                    <IconWrapper
                      onClick={() => setIsRePasswordShown((prev) => !prev)}
                    >
                      <Icon
                        path={!isRePasswordShown ? mdiEyeOff : mdiEye}
                        size={1}
                      />
                    </IconWrapper>
                    <ErrorMessage
                      errors={errors}
                      name="rePassword"
                      render={({ message }) =>
                        message !== undefined ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-start"
                          >
                            {message}
                          </Form.Control.Feedback>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </PasswordInputGroup>
                );
              }}
            />
            <ActionWrapper>
              <SaveButton
                disabled={
                  !!errors.password ||
                  !!errors.rePassword ||
                  !dirtyFields.password ||
                  !dirtyFields.rePassword ||
                  isLoading
                }
                type="submit"
              >
                <Spinner
                  animation="border"
                  size="sm"
                  className="me-2"
                  hidden={!isLoading}
                />
                Save
              </SaveButton>
            </ActionWrapper>
          </Container>
        </CreatePasswordWrapper>
      </CreatePasswordLayout>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton />
        <Modal.Body>{strings.account_inactivate}</Modal.Body>
      </Modal>
      <ErrorModal
        showModal={showNotFoundModal}
        handleCloseModal={() => {
          navigate("/login"); setShowNotFoundModal(false);
        }}
        title={"Error"}
        content={strings.account_not_found}
        action={"OK"} />
    </React.Fragment>
  );
}

export default CreatePassword;
