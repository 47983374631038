import { BsFillExclamationCircleFill } from "react-icons/bs";
import { RoundedModal, Body, Footer, Header } from "./styles";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  title: string;
  content: string;
  action: string;
  isStatic?: boolean;
};
function ErrorModal({
  showModal,
  handleCloseModal,
  title,
  content,
  action,
  isStatic = true,
}: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      backdrop={isStatic ? "static" : true}
      className="border-0"
    >
      <Header closeButton />
      <Body>
        <BsFillExclamationCircleFill size={64} color="rgb(237, 41, 57)" />
        <h5>{title}</h5>
        <p dangerouslySetInnerHTML={{
          __html: content,
        }}></p>
      </Body>

      <Footer onClick={handleCloseModal}>{action}</Footer>
    </RoundedModal>
  );
}

export default ErrorModal;
