import { IoClose } from "react-icons/io5";
import { GreenSolidButton, RedSolidButton } from "../../../components/common";
import {
  Body,
  ButtonWrapper,
  Header,
  RoundedModal,
  WarningContent,
} from "./styles";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  handleConfirmed: () => void;
};
function SlrBusinessConfirmationModal({
  showModal,
  handleCloseModal,
  handleConfirmed,
}: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      className="border-0"
    >
      <Header>
        <IoClose size={25} onClick={handleCloseModal} />
      </Header>
      <Body>
        <WarningContent>
          Are you sure you want to set this client account to a non-SLR company?
          <br />
          If you proceed, all admin accounts for this client will automatically
          be changed to standard user accounts and will no longer have any
          access to administer business modules.
        </WarningContent>
        <ButtonWrapper>
          <GreenSolidButton onClick={handleConfirmed}>Yes</GreenSolidButton>
          <RedSolidButton onClick={handleCloseModal}>No</RedSolidButton>
        </ButtonWrapper>
      </Body>
    </RoundedModal>
  );
}

export default SlrBusinessConfirmationModal;
