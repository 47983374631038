import { Form } from "react-bootstrap";
import styled from "styled-components";
import { BlueOutlinedButton } from "../../components/common";

export const CreatePasswordLayout = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreatePasswordWrapper = styled.div`
  width: 800px;

  form,
  .form-input {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  padding: 2rem 4rem;
  border: solid 2px #b9b4bb;
`;

export const Title = styled.h3`
  color: #00afdb;
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-weight: 600;
  text-align: left;
  margin: 0;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.layout.body};
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-align: left;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.styledInput.errorText};
  font-weight: 700;
  font-style: italic;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PasswordInputGroup = styled(Form.Group)`
  width: 100%;
  position: relative;
`;
export const IconWrapper = styled.div.attrs((_) => ({
  className: "btn btn-icon",
}))`
  position: absolute;
  right: -50px;
  top: 0;
  color: black !important;
  border: none;
`;

export const SaveButton = styled(BlueOutlinedButton)`
  border-radius: 2rem;
`;
