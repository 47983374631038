import {
  DocumentQuery,
  DocumentOrderByColumn,
} from "../../models/document-query";

export const initialState: DocumentQuery = {
  search: "",
  itemsPerPage: 10,
  currentPage: 1,
  module: "",
  type: "",
  activityType: "",
  activity: "",
  purpose: "",
  creator: "",
  orderBy: DocumentOrderByColumn.Date,
  sortDesc: true,
  showDeletedItems: false,
};

export enum DocumentQueryActionKind {
  UPDATE_FILTER = "UPDATE_FILTER",
  UPDATE_SORT = "UPDATE_SORT",
  UPDATE_PAGE = "UPDATE_PAGE",
}

export type DocumentQueryAction =
  | {
      type: DocumentQueryActionKind.UPDATE_FILTER;
      payload: {
        [target: string]: any;
      };
    }
  | {
      type: DocumentQueryActionKind.UPDATE_SORT;
      payload: {
        orderBy: DocumentOrderByColumn;
        sortDesc: boolean;
      };
    }
  | { type: DocumentQueryActionKind.UPDATE_PAGE; payload: number };

export const documentQueryReducer = (
  state: DocumentQuery,
  action: DocumentQueryAction
) => {
  const { type } = action;

  switch (type) {
    case DocumentQueryActionKind.UPDATE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        currentPage: 1,
        ...payload,
      };
    }

    case DocumentQueryActionKind.UPDATE_SORT: {
      const { payload } = action;

      return {
        ...state,
        orderBy: payload.orderBy,
        sortDesc: payload.sortDesc,
      };
    }

    case DocumentQueryActionKind.UPDATE_PAGE: {
      const { payload } = action;

      return {
        ...state,
        currentPage: payload,
      };
    }
    default:
      return initialState;
  }
};
