import { Layout, PlaceholderBox } from "./styles";

function AdminHome() {
  return (
    <Layout>
      <PlaceholderBox>Placeholder content</PlaceholderBox>
    </Layout>
  );
}

export default AdminHome;
