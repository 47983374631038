import styled from "styled-components";

type Props = {
  $isPaddedRight: boolean;
  $isInvalid: boolean;
  $isDisabled: boolean;
};

export const Toggle = styled.div.attrs(($isInvalid) => ({
  className: $isInvalid ? "is-invalid" : "",
}))<Props>`
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: transparent;

  transition: border-color 0.15s;
  font-family: ${({ theme }) => theme.fontFamily.body};
  padding: none;

  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
