import { useEffect } from "react";
import { useNavigate } from "react-router";
import sessionService from "../../services/session.service";

function LogOut() {
  const navigate = useNavigate();

  useEffect(() => {
    sessionService.logOut().then((_) => navigate("/login"));
  }, [navigate]);

  return <></>;
}

export default LogOut;
