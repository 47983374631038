import { debounce } from "lodash";
import React, { useState, useEffect } from "react";

import { InputBox, ClearIcon, SearchIcon } from "./styles";

interface SearchBoxProps {
  textFromQuery: string;
  onSearchDispatch: any;
  resetSearch: boolean;
  setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

function SearchBox({
  textFromQuery,
  onSearchDispatch,
  resetSearch,
  setResetSearch,
}: SearchBoxProps) {
  const [searchText, setSearchText] = useState<string | null>(null);

  useEffect(() => {
    if (resetSearch) {
      setSearchText("");
      setResetSearch(false);
    }
  }, [resetSearch, setResetSearch]);

  const debounceSearchText = React.useCallback(
    debounce((nextValue: string | null) => onSearchDispatch(nextValue), 250),
    []
  );

  return (
    <div className="position-relative">
      <InputBox
        data-testid="searchBox"
        value={searchText !== null ? searchText : textFromQuery}
        placeholder="Search"
        maxLength="255"
        onChange={(e: any) => {
          setSearchText(e.target.value);
          debounceSearchText(e.target.value !== "" ? e.target.value : null);
        }}
      />
      <SearchIcon />
      {searchText && (
        <ClearIcon
          onClick={() => {
            setSearchText("");
            onSearchDispatch("");
          }}
        />
      )}
    </div>
  );
}

export default SearchBox;
