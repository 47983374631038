import { handleResponse } from "../helpers/handle-response";

export function userRequestContact(payload: any) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/contacts/user`,
    requestOptions
  ).then(handleResponse);
}
