export interface DocumentQuery {
  search: string;
  itemsPerPage: number;
  currentPage: number;
  module: string;
  type: string;
  activityType: string;
  activity: string;
  purpose: string;
  creator: string;
  orderBy: DocumentOrderByColumn;
  sortDesc: boolean;
  showDeletedItems: boolean;
}
export enum DocumentOrderByColumn {
  Date = "Date",
  Name = "Name",
}
