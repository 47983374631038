import styled from "styled-components";
import { LightGreyOutlinedInput } from "../../common";

type Props = {
  $isPaddedRight: boolean;
};

export const Input = styled(LightGreyOutlinedInput)<Props>`
  padding: 0.375rem 0.75rem !important;
  &.is-invalid {
    background-image: none !important;
  }

  ${({ $isPaddedRight }) =>
    $isPaddedRight &&
    `
    padding-right: 2rem !important;
  `}
`;
