import { ModuleFeature } from "./module-feature";

export interface User {
  email: string;
  displayName: string;
  token?: string;
  refreshToken?: string;
  role?: string;
  allowedToMasquerade?: boolean;
  accessibleFeatures: Array<ModuleFeature>;
}

export interface SignInResult {
  landingPage: string;
}

export enum Role {
  masterAdmin = "MasterAdmin",
  moduleAdmin = "ModuleAdmin",
  user = "User",
}

export const isAdmin = (user: User) =>
  user.role === Role.masterAdmin || user.role === Role.moduleAdmin;

export const isOnlyDocumentFeature = (user: User) =>
  user.accessibleFeatures.every((x) => x === ModuleFeature.DocumentManagement);
