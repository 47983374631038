import { FaUser } from "react-icons/fa";
import styled from "styled-components";

export const ProfileInformationRow = styled.div.attrs((_) => ({
  className: "w-100 border d-flex align-items-center",
}))`
  border-radius: 1rem;
  height: 100%;
  padding-left: 1rem;
`;
export const ProfileImageWrapper = styled.div.attrs((_) => ({
  className: "d-flex align-content-center justify-content-center flex-wrap",
}))`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.userManagement.userIconPrimaryColor};
`;
export const ProfileImage = styled(FaUser).attrs((_) => ({
  size: 42,
}))`
  color: ${({ theme }) => theme.userManagement.userIconSecondaryColor};
`;

export const Logo = styled.img.attrs((_) => ({
  className: "d-flex align-content-center justify-content-center",
}))`
  border-style: solid;
  border-color: ${({ theme }) => theme.clientManagement.thirdBorder};
  border-radius: 50%;
  color: ${({ theme }) => theme.clientManagement.primaryText};
  background-color: ${({ theme }) => theme.clientManagement.fifthBgColor};

  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 72px;
  height: 72px;
`;
