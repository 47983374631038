import { NameValue } from "../../../../../models/name-value";
import { useTaskManagement } from "../../../contexts/TaskManagementProvider";
import SearchBox from "./SearchBox";
import { ToolBarLayout, FormSelect } from "./styles";

function ToolBar() {
  const { taskStates, setFilter, filter } = useTaskManagement();

  const onChangeState = (value: string) => {
    setFilter((currentFilter) => {
      return { ...currentFilter, state: value === "" ? null : value };
    });
  };

  return (
    <ToolBarLayout>
      {taskStates && taskStates.length > 0 && (
        <FormSelect
          data-testid="status-select"
          value={filter.state ? filter.state : ""}
          required
          aria-label="Status/Task"
          width="12rem"
          onChange={(e: any) => onChangeState(e.target.value)}
        >
          <option value="">Status/Task</option>
          {taskStates.map((state: NameValue) => (
            <option key={state.value} value={state.value}>
              {state.name}
            </option>
          ))}
        </FormSelect>
      )}
      <SearchBox />
    </ToolBarLayout>
  );
}

export default ToolBar;
