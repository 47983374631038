import { Form } from "react-bootstrap";
import styled from "styled-components";

export const NewPasswordFormLayout = styled.div`
  form,
  .form-input {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.styledInput.errorText};
  font-weight: 700;
  font-style: italic;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

export const PasswordNote = styled.span`
  text-align: left;
  font-size: 11px;
  width: 66%;
`;

export const PasswordInputGroup = styled(Form.Group)`
  width: 100%;
  position: relative;
`;
export const IconWrapper = styled.div.attrs((_) => ({
  className: "btn btn-icon",
}))`
  position: absolute;
  right: -50px;
  top: 0;
  color: black !important;
  border: none;
`;

export const Title = styled.h3`
  color: #00afdb;
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-weight: 600;
  text-align: left;
  margin: 0;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.layout.body};
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-align: left;
`;
