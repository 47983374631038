import ForgotPassword from "../../pages/ForgotPassword";
import Login from "../../pages/Login";
import NewPassword from "../../pages/NewPassword";
import Logout from "../../pages/LogOut";
import Login2Fa from "../../pages/Login2Fa";

export const AuthenticationRoutes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "login-2fa",
    element: <Login2Fa />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "new-password",
    element: <NewPassword />,
  }
];
