import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbBar from "../../components/BreadcrumbBar";
import { useNotification } from "../../components/NotificationProvider";
import { Client } from "../../models/client";
import { UserActivation } from "../../models/user-activation";
import { getClient } from "../ClientManagement/services/client.services";
import EditClientForm from "./EditClientForm";
import { MainContainer } from "./styles";

function EditClient() {
  const { clientId } = useParams();
  const { showError } = useNotification();
  const navigate = useNavigate();
  const [clientFeatures, setClientFeatures] = useState<number[] | undefined>(
    undefined
  );
  const methods = useForm<Client & { userActivations?: UserActivation[] }>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      name: "",
      internalSlr: false,
      logo: "",
      address: {
        street: "",
        city: "",
        zipCode: "",
        countryCode: "",
      },
      preferredReportingCurrency: "",
      fromDate: "",
      toDate: "",
      contact: {
        name: "",
        role: "",
        email: "",
        phone: "",
      },
      accountType: "Child",
      accountLevel: "PremiumB4SI",
      globalFeatures: [],
      userActivations: [],
      active: false,
      features: [],
    },
  });
  const { reset } = methods;
  useEffect(() => {
    clientId &&
      getClient(clientId)
        .then((res) => {
          reset(res);
          setClientFeatures(res.features);
        })
        .catch(() => {
          showError(undefined, "Can't fetch client");
          navigate("/clients");
        });
  }, [clientId]);

  return (
    <>
      <BreadcrumbBar
        items={[
          { name: "Client Management", link: "/clients" },
          { name: "Edit Client", active: true },
        ]}
      />
      <MainContainer>
        <FormProvider {...methods}>
          <EditClientForm isEdit={!!clientId} clientFeatures={clientFeatures} />
        </FormProvider>
      </MainContainer>
    </>
  );
}

export default EditClient;
