import _ from "lodash";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import {
  SlrAdminHeaders,
  SlrClientHeaders,
  SlrHeaderItem,
} from "../../constants/slrHeaderItems";
import { User, isAdmin } from "../../models/user";
import sessionService from "../../services/session.service";
import Footer from "../SlrFooter";
import SlrHeader from "../SlrHeader";
import { Layout } from "./styles";

function SlrPortalLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = sessionService.getCurrentUser() as User | undefined;

  const headers: Array<SlrHeaderItem> = useMemo(() => {
    if (
      _.isEmpty(currentUser) ||
      currentUser.accessibleFeatures === undefined
    ) {
      return [];
    } else if (isAdmin(currentUser)) {
      return SlrAdminHeaders(currentUser.accessibleFeatures);
    }

    return SlrClientHeaders(currentUser.accessibleFeatures);
  }, [currentUser]);

  useEffect(() => {
    if (pathname === "/login" && !!currentUser) {
      sessionService.getMyInfo()
        .then(() => {
          navigate("/");
        }).catch(() => { })
    }
  }, []);

  if (!_.isEmpty(currentUser) && currentUser.accessibleFeatures === undefined) {
    sessionService.logOut().finally(() => navigate("/login"));
  }

  return (
    <Layout>
      <SlrHeader headerItems={headers} />
      <Outlet />
      <Footer />
    </Layout>
  );
}

export default SlrPortalLayout;
