import { Container, Form, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ForgotPasswordFormLayout } from "./styles";
import {
  GreyOutlinedButton,
  GreyOutlinedInput,
} from "../../../components/common";
import { strings } from "../../../localization/en";

import React, { useState, useEffect } from "react";

import SuccessModal from "../../../components/SuccessModal";
import ErrorModal from "../../../components/ErrorModal";
import { ErrorMessage } from "@hookform/error-message";
import sessionService from "../../../services/session.service";
import { emailRegex } from "../../../helpers/regex-pattern";

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const getEmail = (_state: any) =>
    _state.hasOwnProperty("email") ? _state.email : "";
  const email = state !== null ? getEmail(state) : "";

  const {
    control,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      email: email,
    },
    mode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    if (data.email) {
      setIsLoading(true);
      sessionService
        .sendResetPasswordEmail(data.email)
        .then((_) => {
          setIsLoading(false);
          setShowModal(true);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.status) {
            if (error.status === 404) {
              setError("email", {
                type: "custom",
                message: strings.account_not_found,
              });
            } else if (
              error.status === 400 &&
              error.data.status === "Inactive"
            ) {
              setError("email", {
                type: "custom",
                message: strings.account_inactivate,
              });
            } else {
              setErrorModalText(strings.password_reset_general_fail);
              setShowErrorModal(true);
            }
          } else {
            setErrorModalText(strings.password_reset_network_fail);
            setShowErrorModal(true);
          }
        });
    }
  });

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (email) trigger("email");
  }, [email, trigger]);

  return (
    <ForgotPasswordFormLayout>
      <Container as={Form} onSubmit={onSubmit}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Required.",
            pattern: {
              value: emailRegex,
              message: strings.password_reset_email_invalid,
            },
          }}
          render={({ field }) => {
            const { onBlur, ...rest } = field;
            return (
              <React.Fragment>
                <GreyOutlinedInput
                  data-testid="email-input"
                  placeholder="Enter email address here"
                  isInvalid={!!errors.email}
                  type="email"
                  onBlur={() => {
                    if (!getValues("email"))
                      setError("email", {
                        type: "required",
                        message: "Required.",
                      });
                  }}
                  {...rest}
                />

                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) =>
                    message !== undefined ? (
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        <span dangerouslySetInnerHTML={{ __html: message }} />
                      </Form.Control.Feedback>
                    ) : (
                      <></>
                    )
                  }
                />
              </React.Fragment>
            );
          }}
        />
        <GreyOutlinedButton
          disabled={!isValid || isLoading}
          className="ms-auto mt-2"
          type="submit"
        >
          <Spinner
            animation="border"
            size="sm"
            className="me-2"
            hidden={!isLoading}
          />
          {strings.reset_password}
        </GreyOutlinedButton>
        <SuccessModal
          title={strings.password_reset_sent}
          content={strings.password_reset_check_inbox}
          action={strings.password_reset_modal_OK}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
        <ErrorModal
          title="Reset password requested failed"
          content={errorModalText}
          action={strings.dashboard_created_modal_OK}
          showModal={showErrorModal}
          handleCloseModal={handleCloseErrorModal}
        />
      </Container>
    </ForgotPasswordFormLayout>
  );
}

export default ForgotPasswordForm;
