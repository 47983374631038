import {
  Layout,
  MainContainer,
  DocumentListLayout,
  SideBarLayout,
} from "./styles";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import DocumentTable from "./DocumentTable";
import ToolBar from "./ToolBar";
import { DocumentFile } from "../../models/documentFile";
import { PaginationModel } from "../../models/pagination";
import TablePagination from "./TablePagination";
import { searchFiles } from "../../services/document.service";
import { DocumentQueryActionKind } from "./documentQueryReducer";
import useDocumentQuery from "./useDocumentQuery";

function DocumentManagement() {
  const {
    documentQuery,
    dispatch,
    filterOptions,
    fetchFilterOptions,
    isProcessingURL,
  } = useDocumentQuery();

  const [pagination, setPagination] = useState<PaginationModel | undefined>(
    undefined
  );
  const [resetSearch, setResetSearch] = useState(false);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshDocuments = () => {
    fetchFilterOptions();
    fetchFiles();
  };

  const fetchFiles = () => {
    if (!isProcessingURL) {
      setIsLoading(true);
      searchFiles({ ...documentQuery })
        .then((result) => {
          setDocuments(result.items);
          setPagination({
            currentPage: result.pageIndex,
            totalItems: result.totalItems,
            pageSize: result.pageSize,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [documentQuery, isProcessingURL]);

  const handleSearch = (searchText?: string) => {
    dispatch({
      type: DocumentQueryActionKind.UPDATE_FILTER,
      payload: {
        search: searchText ? searchText : "",
      },
    });
  };

  const handlePageChange = (page: number) => {
    dispatch({
      type: DocumentQueryActionKind.UPDATE_PAGE,
      payload: page,
    });
  };

  return (
    <Layout>
      <MainContainer>
        <SideBarLayout>
          <SideBar
            documentQuery={documentQuery}
            dispatch={dispatch}
            modules={filterOptions.modules}
            setResetSearch={setResetSearch}
          />
        </SideBarLayout>
        <DocumentListLayout>
          <ToolBar
            textFromQuery={documentQuery.search}
            onSearchDispatch={handleSearch}
            fetchFiles={refreshDocuments}
            resetSearch={resetSearch}
            setResetSearch={setResetSearch}
          />
          <DocumentTable
            documentQuery={documentQuery}
            filterOptions={filterOptions}
            dispatch={dispatch}
            documents={documents}
            isLoading={isLoading}
            fetchFiles={refreshDocuments}
            isDeletedDocumentFolder={documentQuery.showDeletedItems}
          />
          {!!pagination && (
            <TablePagination
              totalItems={pagination.totalItems}
              currentPage={pagination.currentPage}
              pageSize={pagination.pageSize}
              pageChanged={handlePageChange}
            />
          )}
        </DocumentListLayout>
      </MainContainer>
    </Layout>
  );
}

export default DocumentManagement;
