import { useMemo } from "react";
import { RadioListInputRHF } from "../../../components/InputsWithRHF";
import { ClientAccountType } from "../../../enums/client-account-type";
import { HeaderText } from "../common/styles";

function ClientAccountTypeSection() {
  const disabledOptions = useMemo(
    () =>
      Object.entries(ClientAccountType)
        .filter((x) => x[1] !== ClientAccountType.Child)
        .reduce<{ [key: string]: string }>((prev, cur) => {
          return { ...prev, [cur[0]]: cur[1] };
        }, {}),
    []
  );
  return (
    <div>
      <HeaderText>Account Type</HeaderText>
      <div className="ps-4 pt-3">
        <RadioListInputRHF
          name="accountType"
          options={ClientAccountType}
          disabledOptions={disabledOptions}
        />
      </div>
    </div>
  );
}

export default ClientAccountTypeSection;
