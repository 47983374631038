import {
  ProfileImageWrapper,
  ProfileImage,
  ProfileInformationRow,
  Logo,
} from "./styles";
interface AppProps {
  title?: string;
  subTitle?: string;
  image?: string;
}
export default function DisplayProfile({ title, subTitle, image }: AppProps) {
  return (
    <ProfileInformationRow>
      <div className="d-inline-block ms-3">
        {!!image ? (
          <ProfileImageWrapper>
            <Logo src={image} alt="Client Logo" />
          </ProfileImageWrapper>
        ) : (
          <ProfileImageWrapper>
            <ProfileImage />
          </ProfileImageWrapper>
        )}
      </div>
      <div className="d-inline-block ms-5">
        <h4 data-testid="user-name">{title}</h4>
        <span data-testid="user-role">{subTitle}</span>
      </div>
    </ProfileInformationRow>
  );
}
