import { Spinner, Button } from "react-bootstrap";
import styled from "styled-components";
import { OrangeSolidButton, RedSolidButton } from "../../../components/common";

export const HeaderText = styled.h5.attrs((_) => ({
  className: "fw-bold",
}))`
  color: ${({ theme }) => theme.layout.text};
`;

export const SpinnerLoading = styled(Spinner).attrs((_) => ({
  animation: "border",
  variant: "primary",
}))`
  width: 1rem;
  height: 1rem;
`;

export const LoadingPageWrapper = styled.div.attrs((_) => ({}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 1.5rem;
`;

export const PrimaryButton = styled(OrangeSolidButton).attrs((_) => ({
  className: "px-4 mt-3",
}))`
  border-radius: 1rem;
`;

export const DeleteButton = styled(RedSolidButton).attrs((_) => ({
  className: "px-4 mt-3",
}))`
  border-radius: 1rem;
`;