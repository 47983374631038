import Icon from "@mdi/react";
import { useRef, useState } from "react";
import {
  FromFileUpload,
  InputFileUpload,
  LabelFileUpload,
  DragFileElement,
  ContentFileUploadWrapper,
  FileNameWrapper,
  FileName,
  FileNameTooltip,
  FullFileName,
} from "./styles";
import { mdiClose, mdiFileCheckOutline, mdiFolderUploadOutline } from "@mdi/js";
import { Button, OverlayTrigger } from "react-bootstrap";
import { ModalContentState } from "../../models/modal-content-state";
import { DarkBlueSolidButton } from "../common";

type Props = {
  files: FileList | null;
  setFiles: React.Dispatch<React.SetStateAction<FileList | null>>;
  setModalContentState: React.Dispatch<React.SetStateAction<ModalContentState>>;
};

function DragDropFile({ files, setFiles, setModalContentState }: Props) {
  const disallowedExtensions = /(\.exe|\.cmd|\.bat|\.bin|\.run)$/i;
  const inputRef = useRef<any | null>(null);

  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      setFiles(e.dataTransfer.files as FileList);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();

    if (e.target.files) {
      const { files } = e.target;
      for (let i = 0; i < files.length; i++)
        if (disallowedExtensions.exec(files[i].name)) {
          setModalContentState(ModalContentState.FileTypeError);
          return;
        }
      setModalContentState(ModalContentState.Common);
      setFiles(e.target.files as FileList);
    }
  };

  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const clearFile = (e: any, indexToBeCleared: number) => {
    e.preventDefault();
    e.stopPropagation();
    setFiles((prev) => {
      const newFiles = new DataTransfer();
      for (let i = 0; i < prev!.length; i++) {
        const file = prev![i];
        if (indexToBeCleared !== i) newFiles.items.add(file);
      }
      return newFiles.files;
    });
    // setUploadedFile(null);
  };

  return (
    <FromFileUpload
      data-testid="dragForm"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <InputFileUpload
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple
        onChange={handleChange}
      />
      <LabelFileUpload htmlFor="input-file-upload" $active={dragActive}>
        <ContentFileUploadWrapper
          $hasFile={files === null ? false : files.length !== 0 ? true : false}
        >
          <Icon
            path={
              files === null
                ? mdiFolderUploadOutline
                : files.length !== 0
                ? mdiFileCheckOutline
                : mdiFolderUploadOutline
            }
            size={1.5}
          />
          <p>Select file(s) or drag here</p>
          <DarkBlueSolidButton onClick={onButtonClick}>
            Select file(s)
          </DarkBlueSolidButton>
          {!!files &&
            Array.from(files).map((file, i) => {
              const { name } = file;
              return (
                <FileNameWrapper key={i}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <FileNameTooltip>
                        <FullFileName>{name}</FullFileName>
                      </FileNameTooltip>
                    }
                  >
                    <FileName>{name}</FileName>
                  </OverlayTrigger>

                  <Button
                    variant="outline-light"
                    onClick={(e) => clearFile(e, i)}
                  >
                    <Icon path={mdiClose} size={1} />
                  </Button>
                </FileNameWrapper>
              );
            })}
        </ContentFileUploadWrapper>
      </LabelFileUpload>
      {dragActive && (
        <DragFileElement
          data-testid="dropZone"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </FromFileUpload>
  );
}

export default DragDropFile;
