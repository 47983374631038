import NumberInput from "..";
import withFormatValidation from "../withFormatValidation";

export const positiveIntegerMaskFormatter = (value: string) => {
  if (!/^\d+$/.test(value)) {
    return value;
  } else {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const positiveIntegerValueFormatter = (value: string) => {
  return +value;
};
export const additionalCondition = (value: string) => {
  return /^\d+$/.test(value);
};

const PositiveIntegerInput = withFormatValidation(
  NumberInput,
  positiveIntegerMaskFormatter,
  positiveIntegerValueFormatter,
  additionalCondition
);

export default PositiveIntegerInput;
