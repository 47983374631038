import { DocumentOption } from "../../../models/document-option";
import { Select } from "../../common";
import { DropdownInputRHF } from "../../InputsWithRHF";
import { DropdownWrapper } from "./styles";

type Props = {
  label: string;
  options: {
    [key: string]: string;
  };
  name: string;
};

function ModalDropdown({ label, options, name }: Props) {
  return (
    <DropdownWrapper>
      <label>{label}</label>
      <div>
        <DropdownInputRHF name={name} placeholder="All" options={options} />
      </div>
    </DropdownWrapper>
  );
}
export default ModalDropdown;
