import React, { useCallback, useState } from "react";
import { RadioInputRHF } from "../../../components/InputsWithRHF";
import LabelWrapper from "../LabelWrapper";
import { useFormContext } from "react-hook-form";
import SlrBusinessConfirmationModal from "../SlrBusinessConfirmationModal";

function SlrBusiness() {
  const { resetField } = useFormContext();
  const [isShowConfirmedModal, setIsShowConfirmedModal] =
    useState<boolean>(false);

  const changeHandler = useCallback((value: boolean) => {
    setIsShowConfirmedModal(!value);
  }, []);

  const rejected = useCallback(() => {
    resetField("internalSlr", { defaultValue: true });
    setIsShowConfirmedModal(false);
  }, [resetField]);

  const confirmed = useCallback(() => {
    setIsShowConfirmedModal(false);
  }, []);

  return (
    <React.Fragment>
      <LabelWrapper
        name="internalSlr"
        label="Is this an SLR business"
        withoutControlId
        style={{ flexBasis: "100%" }}
      >
        <div className="mt-2">
          <RadioInputRHF name="internalSlr" onValueChange={changeHandler} />
        </div>
      </LabelWrapper>

      <SlrBusinessConfirmationModal
        showModal={isShowConfirmedModal}
        handleCloseModal={rejected}
        handleConfirmed={confirmed}
      />
    </React.Fragment>
  );
}

export default SlrBusiness;
