import { DocumentOption } from "../../../models/document-option";
import { DocumentQuery } from "../../../models/document-query";
import { Folders } from "../Enums/folders";
import {
  DocumentQueryAction,
  DocumentQueryActionKind,
  initialState,
} from "../documentQueryReducer";
import { Menu, MenuItem, SideBarLayout, VerticalDivider } from "./styles";

interface SideBarProps {
  documentQuery: DocumentQuery;
  dispatch: React.Dispatch<DocumentQueryAction>;
  modules: string[];
  setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideBar({
  documentQuery,
  modules,
  dispatch,
  setResetSearch,
}: SideBarProps) {
  const handleClickOnFolder = (module: string | null) => {
    if (documentQuery.showDeletedItems === false)
      dispatch({
        type: DocumentQueryActionKind.UPDATE_FILTER,
        payload: {
          module: module,
        },
      });
    else {
      setResetSearch(true);
      dispatch({
        type: DocumentQueryActionKind.UPDATE_FILTER,
        payload: {
          ...initialState,
          module: module,
          showDeletedItems: false,
        },
      });
    }
  };

  return (
    <SideBarLayout>
      <Menu>
        <MenuItem
          selected={!documentQuery.module && !documentQuery.showDeletedItems}
          onClick={(_) => {
            handleClickOnFolder(null);
          }}
        >
          All
        </MenuItem>
        {modules.map((module, i) => (
          <MenuItem
            key={i}
            selected={
              documentQuery.module === module && !documentQuery.showDeletedItems
            }
            onClick={(_) => {
              handleClickOnFolder(module);
            }}
          >
            {module}
          </MenuItem>
        ))}
        <MenuItem
          selected={!!documentQuery.showDeletedItems}
          onClick={(_) => {
            setResetSearch(true);
            dispatch({
              type: DocumentQueryActionKind.UPDATE_FILTER,
              payload: {
                ...initialState,
                showDeletedItems: true,
              },
            });
          }}
        >
          Deleted documents
        </MenuItem>
      </Menu>
      <VerticalDivider></VerticalDivider>
    </SideBarLayout>
  );
}
export default SideBar;
