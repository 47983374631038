import styled from "styled-components";

export const SideBarLayout = styled.div.attrs((_) => ({
  className: "hstack d-flex",
}))`
  margin-top: 2rem;
  height: calc(100% - 3rem);
  align-items: flex-start;
`;

export const VerticalDivider = styled.div.attrs((_) => ({
  className: "vr",
}))`
  width: 2px;
`;

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollTrack};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollBar};
    border-radius: 4px;
  }
`;

export const MenuItem = styled.a<{ selected?: boolean }>`
  display: block;
  padding: 0.25rem 0.75rem;
  padding-left: ${({ selected }) => (selected ? "0.625rem" : "0.75rem")};
  margin: 0.5rem 1rem;
  text-decoration: none;
  color: ${({ selected, theme }) =>
    selected
      ? theme.sideBarMenuItem.secondaryColor
      : theme.sideBarMenuItem.primaryColor};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 1.2rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.sideBarMenuItem.secondaryColor};
  }

  border-left: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.sideBarMenuItem.secondaryColor}` : "none"};
`;
