import styled from "styled-components";
import { StyledSpinner } from "../../common";

export const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Spinner = styled(StyledSpinner)`
  color: ${({ theme }) => theme.spinnerColor.secondaryBgColor};
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
  margin: 0;
`;
