import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { EditUserContainer } from "./styles";
import UserInformation from "../../Users/UserInformation";
import DisplayProfile from "../../Users/DisplayProfile";
import ProfileStatus from "../../Users/UserStatus";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { ClientUser } from "../../../models/client-user";
import { useFormContext, useWatch } from "react-hook-form";
import UserRole from "../../Users/UserRole";
import {
  createUser,
  getClient,
  updateUser,
} from "../../ClientManagement/services/client.services";
import { Client } from "../../../models/client";
import UserBusinessModule from "../../Users/UserBusinessModules";
import MasqueradeAsAUser from "../../Users/MasqueradeAsAUser";
import { useNavigate, useParams } from "react-router-dom";
import { UserRoleType } from "../../../enums/user-role";
import UserGlobalFeatures from "../../Users/UserGlobalFeatures";
import MasterAdminConfirmationModal from "../../Users/MasterAdminConfirmationModal";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";
import {
  getAccessibleModuleFeatures,
  getAllModuleFeatures,
} from "../../../services/module.services";
import { Module } from "../../../models/module";
import sessionService from "../../../services/session.service";
import DeleteModal from "../../../components/DeleteModal";
import { deletePermanentlyUser } from "../../../services/user.service";
import { DeleteButton } from "../../Users/common/styles";

interface Props {
  isEdit: boolean;
}

export default function EditUserForm({ isEdit }: Props) {
  const { role: loggedInRole } = sessionService.getCurrentUser() || {};
  const { showInfo, showError } = useNotification();
  const { control } = useFormContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState<UserRoleType | undefined>(undefined);
  const [selectedClientId, setSelectedClientId] = useState<number | undefined>(
    undefined
  );
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(
    undefined
  );
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [userData, setUserData] = useState<ClientUser | undefined>(undefined);
  const [modules, setModules] = useState<Module[] | undefined>(undefined);

  const clientId = useWatch({ control, name: "clientId" });
  const userName = useWatch({ control, name: "contact.name" });
  const email = useWatch({ control, name: "contact.email" });
  const userRoleType = useWatch({
    control,
    name: "userRole",
  }) as keyof typeof UserRoleType;
  const selectedModules = useWatch({ control, name: "modules" }) as
    | number[]
    | [];

  const [accessibleModule, setAccessibleModule] = useState<
    Module[] | undefined
  >(undefined);

  const keyOfRole = useCallback((value: UserRoleType) => {
    return getKeyByValueEnum(value, UserRoleType) as keyof typeof UserRoleType;
  }, []);

  const disabledByPermission = useMemo(() => {
    if (!isEdit || !clientId) return false;

    if (loggedInRole === keyOfRole(UserRoleType.MasterAdmin)) return false;

    if (userRoleType === keyOfRole(UserRoleType.MasterAdmin)) return true;

    if (!accessibleModule || !selectedModules) return false;
    return !accessibleModule.some((am) =>
      selectedModules.some((sm) => sm === am.id)
    );
  }, [
    isEdit,
    loggedInRole,
    keyOfRole,
    userRoleType,
    accessibleModule,
    selectedModules,
    clientId,
  ]);

  useEffect(() => {
    if (clientId) {
      getClient(clientId)
        .then((client: Client) => {
          setSelectedClient(client);
        })
        .catch((e) => console.log(e));
    } else {
      setSelectedClient(undefined);
    }
  }, [clientId]);

  useEffect(() => {
    getAllModuleFeatures()
      .then((m) => {
        setModules(m);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    getAccessibleModuleFeatures()
      .then((amf) => {
        setAccessibleModule(amf);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useLayoutEffect(() => {
    setShowDeleteButton(isEdit && 
      email &&
      email !== sessionService.getCurrentUser().email
      && !disabledByPermission);
  }, [email, isEdit]);


  const handleCreateUser = (clientId: string, data: any) => {
    createUser(clientId, data)
      .then(() => {
        showInfo("User saved.", TypeNotification.success, 6000);
        navigate("/users");
      })
      .catch((e) => {
        showError(undefined, "An error occurred when create user");
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleUpdateUser = (clientId: string, data: any) => {
    updateUser(clientId, data)
      .then(() => {
        showInfo("User saved.", TypeNotification.success, 6000);
        navigate("/users");
      })
      .catch((e) => {
        showError(undefined, "An error occurred when update user");
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteUser = () => {
    if (email) {
      deletePermanentlyUser(email)
        .then(() => {
          showInfo("User deleted", TypeNotification.success, 6000);
          navigate("/users");
        })
        .catch((e) => {
          showError(undefined, "An error occurred when delete user");
          console.log(e);
        })
        .finally(() => setIsLoading(false));
    }
  }

  const onSubmit = (data: ClientUser) => {
    if (userRoleType === keyOfRole(UserRoleType.MasterAdmin)) {
      setUserData(data);
      setShowConfirmModel(true);
    } else {
      setIsLoading(true);
      if (isEdit) {
        clientId && handleUpdateUser(clientId, data);
      } else {
        clientId && handleCreateUser(clientId, data);
      }
    }
  };

  const normalModules = useMemo(
    () => modules?.filter((m) => !m.globalFeature),
    [modules]
  );
  const globalFeatures = useMemo(
    () => modules?.find((m) => m.globalFeature)?.features,
    [modules]
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const onDelete = (e: any) => {
    e.preventDefault();
    setShowConfirmDelete(true);
  }

  return (
    <EditUserContainer>
      <Row>
        <Col xs="6">
          <h3>Edit User</h3>
        </Col>
      </Row>
      <Form>
        <Row className="mt-3">
          <Col xs="9">
            <DisplayProfile
              title={userName}
              subTitle={UserRoleType[userRoleType]}
            />
          </Col>
          <Col xs="3">
            <ProfileStatus
              isLoading={isLoading}
              onSubmit={onSubmit}
              disabled={disabledByPermission}
            />
          </Col>
        </Row>
        <UserInformation
          isEdit={isEdit}
          setClientId={(clientId) => setSelectedClientId(clientId)}
          disabled={disabledByPermission}
        />
        <UserRole
          client={selectedClient}
          selectedClientId={selectedClientId}
          setUserRole={(role) => setUserRole(role)}
          disabled={disabledByPermission}
        />

        <UserBusinessModule
          modules={normalModules}
          selectedClientModules={selectedClient?.modules}
          accessibleModule={accessibleModule}
        />

        <UserGlobalFeatures
          userRole={userRole}
          globalFeatures={globalFeatures}
          clientGlobalFeatures={selectedClient?.globalFeatures}
        />

        <MasqueradeAsAUser
          userRole={userRole}
          disabled={disabledByPermission}
        />
        <MasterAdminConfirmationModal
          showModal={showConfirmModel}
          handleCloseModal={() => setShowConfirmModel(false)}
          handleSaveUser={() => {
            setShowConfirmModel(false);
            setIsLoading(true);
            if (isEdit) {
              clientId && handleUpdateUser(clientId, userData);
            } else {
              clientId && handleCreateUser(clientId, userData);
            }
          }}
        />

        {
          showDeleteButton &&
          <Row className="mt-3">
            <Col xs="9">
            </Col>
            <Col xs="3" className="text-center">
                <DeleteButton onClick={onDelete} >Delete User</DeleteButton>
            </Col>
          </Row>
        }

        <DeleteModal
          showModal={showConfirmDelete}
          handleCloseModal={() => setShowConfirmDelete(false)}
          handleDelete={() => handleDeleteUser()}
          messageContent="Are you sure you want to delete this user from the SLR Portal (no client data will be deleted)?"
        />
      </Form>
    </EditUserContainer >
  );
}
