import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { HeaderText } from "../common/styles";
import LabelWrapper from "../LabelWrapper";
import {
  DropdownInputRHF,
  TextInputRHF,
} from "../../../components/InputsWithRHF";
import {
  getUserInfo,
  getManageableClientItems,
} from "../../ClientManagement/services/client.services";
import { useNotification } from "../../../components/NotificationProvider";
import { emailRegex } from "../../../helpers/regex-pattern";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { debounceAsync } from "../../../helpers/debounce-async";

interface Props {
  isEdit?: boolean;
  setClientId: (clientId: number) => void;
  disabled?: boolean;
}

interface CheckedEmail {
  email: string;
  lastMessage: any;
}

interface UserExisted {
  id: number;
  name: string;
}

export default function UserInformation({
  isEdit,
  setClientId,
  disabled,
}: Props) {
  const { reset } = useFormContext();
  const contactFormPrefix = "contact";
  const { showError } = useNotification();
  const [clientOptions, setClientOptions] = useState<{ [key: number]: string }>(
    {}
  );
  const [userExisted, setUserExisted] = useState<UserExisted | null>(null);
  const [checkedEmail, setCheckedEmail] = useState<CheckedEmail | null>(null);

  useEffect(() => {
    if (isEdit) {
      setCheckedEmail(null);
      setUserExisted(null);
    }
  }, [isEdit]);

  const validateEmail = debounceAsync(async (value: string) => {
    if (isEdit) return true;

    let message: any = true;
    let newExistedUser: UserExisted | null = null;

    if (checkedEmail && value === checkedEmail.email) {
      return checkedEmail.lastMessage;
    } else if (!value) {
      message = "Required.";
    } else if (!emailRegex.test(value)) {
      message = "Wrong email format.";
    } else {
      try {
        const data = (await getUserInfo(value)) as UserExisted | undefined;
        if (data) {
          newExistedUser = data;
          message = "";
        }
      } catch {}
    }

    setUserExisted(newExistedUser);
    setCheckedEmail({ email: value, lastMessage: message });

    return message;
  }, 700);

  useEffect(() => {
    getManageableClientItems()
      .then((clients) => {
        let _clients = {};
        clients.forEach((c: { id: number; name: string }) => {
          _clients = { ..._clients, [c.id]: c.name };
        });
        setClientOptions(_clients);
      })
      .catch(() => {
        showError(undefined, "An error occurred while fetch companies");
      });
  }, []);

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col xs="9">
          <HeaderText>Contact Information</HeaderText>
          <Row className="mt-4">
            <Col xs="6">
              <LabelWrapper
                name={`${contactFormPrefix}.name`}
                label="Name"
                fixedWidth="4rem"
              >
                <TextInputRHF
                  name={`${contactFormPrefix}.name`}
                  rules={{ required: "Required." }}
                  disabled={disabled}
                />
              </LabelWrapper>
            </Col>
            <Col xs="6">
              <LabelWrapper
                name={`${contactFormPrefix}.role`}
                label="Role"
                fixedWidth="4rem"
              >
                <TextInputRHF
                  name={`${contactFormPrefix}.role`}
                  rules={{ required: "Required." }}
                  disabled={disabled}
                />
              </LabelWrapper>
            </Col>
          </Row>
          <Row className="mt-3">
            <LabelWrapper name={`clientId`} label="Company" fixedWidth="4rem">
              <DropdownInputRHF
                name="clientId"
                placeholder="Company"
                options={clientOptions}
                rules={{ required: "Required." }}
                onValueChange={(value) => setClientId(value)}
                withSearch
                withDisplaySort
                disabled={disabled}
              />
            </LabelWrapper>
          </Row>
          <Row className="mt-3">
            <Col xs="6">
              <LabelWrapper
                name={`${contactFormPrefix}.email`}
                label="Email"
                fixedWidth="4rem"
              >
                <React.Fragment>
                  <TextInputRHF
                    disabled={isEdit}
                    name={`${contactFormPrefix}.email`}
                    onBlurInput={() => {}}
                    rules={{
                      required: "Required.",
                      validate: validateEmail,
                    }}
                  />
                  {userExisted && (
                    <span className="text-danger fst-italic">
                      Email address already exists in the user record for{" "}
                      <Link
                        to={`/users/${userExisted.id}`}
                        onClick={() => {
                          reset();
                        }}
                      >
                        {userExisted.name}
                      </Link>
                      . Please either update the existing user or use another
                      email address for this new record.
                    </span>
                  )}
                </React.Fragment>
              </LabelWrapper>
            </Col>
            <Col xs="6">
              <LabelWrapper
                name={`${contactFormPrefix}.phone`}
                label="Phone"
                fixedWidth="4rem"
              >
                <TextInputRHF
                  name={`${contactFormPrefix}.phone`}
                  placeholder="optional"
                  disabled={disabled}
                />
              </LabelWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}
