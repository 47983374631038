import styled from "styled-components";

export const Layout = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.layout.text};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;
export const PlaceholderBox = styled.div`
  background-color: ${({ theme }) => theme.placeholderBox.background};
  color: ${({ theme }) => theme.placeholderBox.text};
  padding: 60px 80px;
  border-radius: 20px;
  font-size: 20px;
`;
