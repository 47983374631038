export function formatDateString(date: string) {
  if (!date) return null;
  const dateObject = new Date(date);
  const extractedDate =
    dateObject.getDate() < 10
      ? `0${dateObject.getDate()}`
      : dateObject.getDate();
  const extractedMonth = dateObject.toLocaleString("en-US", { month: "short" });
  return [extractedDate, extractedMonth, dateObject.getFullYear()].join(" - ");
}
