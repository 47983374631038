import { FallbackProps } from "react-error-boundary";
import { strings } from "../../localization/en";
import { isErrorResponse } from "../../models/error-response";

function ErrorHandler(props: FallbackProps) {
  const { error } = { ...props };
  const errorResponse = isErrorResponse(error);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold text-danger">
          {strings.boundary_error_header}
        </h1>
        <p className="fs-3 text-center">
          <span className="">{strings.boundary_error_content}</span>
        </p>
        <p className="lead text-center">{strings.boundary_error_description}</p>
        {errorResponse && (
          <div>
            <p>Error tracking id: {error.id}</p>
            <p>Error message: {error.message}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorHandler;
