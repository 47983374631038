import { Breadcrumb } from "react-bootstrap";
import { StyledBreadcrumb } from "./styles";
import { useNavigate } from "react-router";
import { useCallback } from "react";

export interface BreadcrumbItem {
  name: string;
  link?: string;
  active?: boolean;
}
interface Props {
  items: BreadcrumbItem[];
}
function BreadcrumbBar({ items }: Props) {
  const navigate = useNavigate();

  const onClick = useCallback(
    (link?: string) => {
      link && navigate(link);
    },
    [navigate]
  );

  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item onClick={() => onClick("/")}>Home</Breadcrumb.Item>
      {items.map((item) => (
        <Breadcrumb.Item
          key={item.name}
          active={item.active}
          onClick={() => onClick(item.link)}
        >
          {item.name}
        </Breadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  );
}

export default BreadcrumbBar;
