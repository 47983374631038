import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { ClientAccountLevel } from "../../../enums/client-account-level";
import { Module } from "../../../models/module";
import { UserActivation } from "../../../models/user-activation";
import { getAllModuleFeatures } from "../../../services/module.services";
import {
  createClient,
  updateClient,
  updateUserActivations,
} from "../../ClientManagement/services/client.services";
import BusinessModules from "../../Users/BusinessModules";
import ClientAccountLevelSection from "../../Users/ClientAccountLevel";
import ClientAccountTypeSection from "../../Users/ClientAccountType";
import ClientContactInformation from "../../Users/ClientContactInformation";
import ClientInformation from "../../Users/ClientInformation";
import DisplayProfile from "../../Users/DisplayProfile";
import GlobalFeatures from "../../Users/GlobalFeatures";
import UserList from "../../Users/UserList";
import ProfileStatus from "../../Users/UserStatus";
import { Title } from "./styles";
import ErrorModal from "../../../components/ErrorModal";
import { strings } from "../../../localization/en";
import { checkLeapYear } from "../../../helpers/check-leap-year";
interface Props {
  clientFeatures: number[] | undefined;
  isEdit: boolean;
}

function EditClientForm({ clientFeatures, isEdit }: Props) {
  const { clientId } = useParams();
  const { showInfo, showError } = useNotification();
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState<Module[] | undefined>(undefined);
  const [data, setData] = useState<any>(undefined);
  const { control } = useFormContext();
  const companyName = useWatch({ control, name: "name" });
  const accountLevel = useWatch({ control, name: "accountLevel" });
  const clientImage = useWatch({ control, name: "logo" });
  const userActivations = useWatch({
    control,
    name: "userActivations",
  }) as UserActivation[];

  useEffect(() => {
    getAllModuleFeatures()
      .then((m) => {
        setModules(m);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validateDateRange = (fromDate: string, toDate: string) => {
    if (
      (fromDate === "29/02" && toDate === "28/02") ||
      (fromDate === "01/03" && toDate === "29/02") ||
      (fromDate === "01/03" && toDate === "28/02")
    ) {
      return true;
    }

    const dayFrom = Number(fromDate.split("/")[0]);
    const monthFrom = Number(fromDate.split("/")[1]);
    const dayTo = Number(toDate.split("/")[0]);
    const monthTo = Number(toDate.split("/")[1]);

    let year = getYear();

    const fromDateValue = new Date(year, monthFrom - 1, dayFrom);
    fromDateValue.setDate(fromDateValue.getDate() + 364);

    if (
      fromDateValue.getMonth() === monthTo - 1 &&
      fromDateValue.getDate() === dayTo
    ) {
      return true;
    }
    return false;
  };

  const getYear = () => {
    const currentYear = new Date().getFullYear();
    if (checkLeapYear(currentYear) || checkLeapYear(currentYear + 1)) {
      return currentYear - 2;
    }
    return currentYear;
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    data && submitData(data);
  };
  const onSubmit = (data: any) => {
    setIsLoading(true);
    if (validateDateRange(data.fromDate, data.toDate)) {
      submitData(data);
    } else {
      setData(data);
      setShowErrorModal(true);
    }
  };

  const submitData = (data: any) => {
    if (isEdit) {
      if (!clientId) {
        return;
      }
      updateUserActivations(clientId, {
        userActivations: userActivations,
      }).catch((e) => {
        console.log(e);
        showError(undefined, "Can't update user activations.");
      });

      updateClient(clientId, data, file)
        .then(() => {
          showInfo("Client saved.", TypeNotification.success, 6000);
          navigate("/clients");
        })
        .catch((e) => {
          console.log(e);
          showError(undefined, "Can't update client.");
        })
        .finally(() => setIsLoading(false));
    } else {
      createClient(data, file)
        .then(() => {
          showInfo("Client saved.", TypeNotification.success, 6000);
          navigate("/clients");
        })
        .catch((e) => {
          console.log(e);
          showError(undefined, "Can't create client.");
        })
        .finally(() => setIsLoading(false));
    }
  };

  const normalModules = useMemo(
    () => modules?.filter((m) => !m.globalFeature),
    [modules]
  );
  const globalFeatures = useMemo(
    () => modules?.find((m) => m.globalFeature)?.features,
    [modules]
  );

  const [showErrorModal, setShowErrorModal] = useState(false);

  return (
    <div>
      <ErrorModal
        title="Financial year"
        content="Financial year dates should be 12 months apart"
        action={strings.dashboard_created_modal_OK}
        showModal={showErrorModal}
        handleCloseModal={() => handleCloseErrorModal()}
        isStatic={false}
      />
      <Row className="mb-3">
        <Col xs="6">
          <Title>Edit Client</Title>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="9">
          <DisplayProfile
            title={companyName}
            subTitle={
              ClientAccountLevel[
              accountLevel as keyof typeof ClientAccountLevel
              ]
            }
            image={clientImage}
          />
        </Col>
        <Col xs="3">
          <ProfileStatus isLoading={isLoading} onSubmit={onSubmit} />
        </Col>
      </Row>
      <ClientInformation file={file} setFile={setFile} isEdit={isEdit} />
      <ClientContactInformation />
      <Row className="mt-3">
        <Col xs="4">
          <ClientAccountTypeSection />
        </Col>
        <Col xs="8">
          <ClientAccountLevelSection />
        </Col>
      </Row>
      <BusinessModules modules={normalModules} />
      <GlobalFeatures addByDefault={!isEdit} globalFeatures={globalFeatures} />
      <UserList isUploadedFile={!!file} clientFeatures={clientFeatures} />
    </div>
  );
}

export default EditClientForm;
