import { Module } from "../../../models/module";
import { Row, Col } from "react-bootstrap";
import { HeaderText } from "../common/styles";
import { useCallback, useEffect, useState } from "react";
import { CheckboxInputRHF } from "../../../components/InputsWithRHF";
import { useFormContext, useWatch } from "react-hook-form";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";
import { UserRoleType } from "../../../enums/user-role";
import React from "react";
import _ from "lodash";

interface Props {
  modules?: Module[];
  selectedClientModules?: number[];
  accessibleModule: Module[] | undefined;
}

export default function UserBusinessModule({
  modules,
  selectedClientModules,
  accessibleModule,
}: Props) {
  const modelName = "modules";

  const { control, setValue, getValues } = useFormContext();

  const [modulesOptions, setModulesOptions] = useState<{
    [key: number]: string;
  }>({});
  const [selectableModulesOptions, setSelectableModulesOptions] = useState<
    | {
        [key: number]: string;
      }
    | undefined
  >(undefined);

  const userRoleType = useWatch({
    control,
    name: "userRole",
  }) as keyof typeof UserRoleType | undefined;

  const clientId = useWatch({ control, name: "clientId" }) as
    | number
    | undefined;

  const convertCheckBoxOptions = (
    values: Module[]
  ): { [key: number]: string } => {
    let options = {};
    values.forEach((value) => {
      options = { ...options, [value.id]: value.name };
    });
    return options;
  };

  const keyOfRole = useCallback((value: UserRoleType) => {
    return getKeyByValueEnum(value, UserRoleType);
  }, []);

  useEffect(() => {
    !clientId && setValue(modelName, []);
  }, [clientId, setValue]);

  useEffect(() => {
    if (modules) {
      setModulesOptions(convertCheckBoxOptions(modules));
    }
  }, [modules]);

  useEffect(() => {
    if (!accessibleModule || !selectedClientModules) return;
    if (userRoleType === keyOfRole(UserRoleType.MasterAdmin)) {
      setSelectableModulesOptions(undefined);
    } else if (userRoleType === keyOfRole(UserRoleType.ModuleAdmin)) {
      setSelectableModulesOptions(convertCheckBoxOptions(accessibleModule));
    } else {
      const selectableModules = accessibleModule.filter((m) =>
        selectedClientModules?.includes(m.id)
      );
      setSelectableModulesOptions(convertCheckBoxOptions(selectableModules));
    }
  }, [userRoleType, accessibleModule, selectedClientModules, keyOfRole]);

  useEffect(() => {
    if (!userRoleType || !selectedClientModules) return;
    if (userRoleType === keyOfRole(UserRoleType.MasterAdmin)) {
      setValue(
        modelName,
        Object.keys(modulesOptions).map((x) => +x)
      );
    } else {
      if (!selectableModulesOptions) return;
      const selectedModules = getValues(modelName) as Array<number> | [];

      selectedModules?.length &&
        setValue(
          modelName,
          selectedModules.filter(
            (sm) => sm.toString() in selectableModulesOptions
          )
        );
    }
  }, [
    userRoleType,
    selectableModulesOptions,
    modulesOptions,
    keyOfRole,
    setValue,
    getValues,
    selectedClientModules,
  ]);

  if (selectedClientModules === undefined) return <React.Fragment />;

  return (
    <Row className="mt-3">
      <Col xs="9">
        <HeaderText>Business Modules</HeaderText>
      </Col>
      <div className="ms-4 pt-3">
        <CheckboxInputRHF
          name={modelName}
          options={modulesOptions}
          enabledOptions={selectableModulesOptions}
          direction="column"
          disabled={userRoleType === keyOfRole(UserRoleType.MasterAdmin)}
        />
      </div>
    </Row>
  );
}
