import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { mdiCheck, mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import { strings } from "../localization/en";

export const TypeNotification = {
  success: "success",
  warning: "warning",
};

interface NotificationContextType {
  showInfo: (message: string, type: string, delayInMs: number) => void;
  showError: (statusCode: number | undefined, message: string) => void;
}

const NotificationContext = React.createContext<NotificationContextType>(null!);
interface NotificationContextTypeProps {
  children: React.ReactNode;
}

export function NotificationProvider(props: NotificationContextTypeProps) {
  const { children } = props;
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("");
  const [delayInMs, setDelayInMs] = useState<number>(3000);
  let errorTimeoutId: any, infoTimeoutId: any;

  const showInfo = (
    _message: string,
    _type: string,
    _delayInMs: number = 3000
  ) => {
    setDelayInMs(_delayInMs);
    if (!!_message) {
      setMessage(_message);
      createTimeout(() => {
        setMessage("");
      }, infoTimeoutId);
      setType(_type);
    }
  };

  const showError = (statusCode: number | undefined, _error: string) => {
    setDelayInMs(6000);
    if (!!statusCode) {
      if (statusCode === 401) {
        setError(strings.unauthorize_error);
      } else if (statusCode === 403) {
        setError(strings.forbidden_error);
      } else {
        setError(strings.unknown_error);
      }
    } else {
      if (!!_error) {
        setError(_error);
      } else {
        setError(strings.unknown_error);
      }
    }
    createTimeout(() => {
      setError("");
    }, errorTimeoutId);
  };

  const createTimeout = (
    callback: VoidFunction,
    id: any,
    delay: number = delayInMs
  ) => {
    id = setTimeout(() => {
      callback();
      clearTimeout(id);
    }, delay);
  };

  const value = {
    showError,
    showInfo,
  };

  const renderIcon = () => {
    if (type === TypeNotification.success) {
      return <Icon path={mdiCheck} size={1} className="mx-1" />;
    } else if (type === TypeNotification.warning) {
      return (
        <Icon
          path={mdiInformation}
          size={1 + 0.25 * message.split("<br />").length}
          className="mx-1"
        />
      );
    }
  };
  return (
    <NotificationContext.Provider value={value}>
      <ToastContainer
        containerPosition="fixed"
        position="top-center"
        style={{ zIndex: 1042 }}
      >
        <Toast
          hidden={!message}
          show={!!message}
          onClose={() => setMessage("")}
          bg={type}
        >
          <Toast.Body
            className={`${
              type === TypeNotification.warning ? "text-black" : "text-white"
            } text-center fs-6`}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="me-2">{renderIcon()}</div>
              <div>
                {message.split("<br />").map((splitMessage, index) =>
                  index === 0 ? (
                    <React.Fragment key={index}>{splitMessage}</React.Fragment>
                  ) : (
                    <React.Fragment key={index}>
                      <br />
                      {splitMessage}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          </Toast.Body>
        </Toast>

        <Toast show={!!error} onClose={() => setError("")} bg="danger">
          <Toast.Header closeButton={false} className="justify-content-center">
            <strong>Error</strong>
          </Toast.Header>
          <Toast.Body className="text-center text-white">{error}</Toast.Body>
        </Toast>
      </ToastContainer>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return React.useContext(NotificationContext);
}
