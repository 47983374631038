import { Container, Form, Spinner, Col, FormGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ControlledInput from "../ControlledInput";
import { useNotification } from "../../../components/NotificationProvider";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import {
  ContactRequestFormLayout, Counter, MaximumCharacters, ContactUsError, LabelTitle, ContactButton, HeaderPage, ContainerFluidLayout,
  ContainerLayout, Logo, ReasonTextArea
} from "./styles";
import SuccessModal from "../../../components/SuccessModal";
import { strings } from "../../../localization/en";
import { emailRegex } from "../../../helpers/regex-pattern";
import { userRequestContact } from "../../../services/contact.service";
import { useSearchParams } from "react-router-dom";
const MAX_LENGTH_REASON = 500;

function ContactUsForm() {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });
  const { showError } = useNotification();
  const [numCharacters, setNumCharacters] = useState<number>(MAX_LENGTH_REASON);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmittedContact, setIsSubmittedContact] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    userRequestContact(data)
      .then((_) => setIsSubmittedContact(true))
      .catch((err) => {
        showError(undefined, "Can't send contact request");
        setIsSubmittedContact(false);
      })
      .finally(() => setIsLoading(false));
  });
  useEffect(() => {
    reset({
      email: !!email ? email : "",
    });
  }, [email, reset]);
  useEffect(() => {
    if (textAreaRef.current !== null) {
      textAreaRef.current.style.height = "0px";
      const newHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = newHeight + "px";
    }
  }, [textAreaRef]);

  return (
    <ContainerFluidLayout>
      <ContainerLayout>
        <HeaderPage>
          <a title="SLR Logo" href="https://slrconsulting.com/en" className="navbar-brand" target="blank">
            <Logo
              src="/images/slr-logo.svg"
              alt="SLR"></Logo>
          </a>
          <span className="ms-auto">Making Sustainability Happen</span>
        </HeaderPage>
        <ContactRequestFormLayout>
          <Container as={Form} onSubmit={onSubmit}>

            <ContactUsError>
              <span dangerouslySetInnerHTML={{ __html: strings.account_not_found }}></span>
            </ContactUsError>
            <LabelTitle>{strings.contact_us_title}</LabelTitle>
            <ControlledInput
              label={strings.contact_us_name_label}
              name="name"
              control={control}
              rules={{
                required: "Required.",
              }}
              type="text"
              placeholder="required"
            />

            <ControlledInput
              label={strings.contact_us_company_label}
              name="company"
              control={control}
              rules={{
                required: "Required.",
              }}
              type="text"
              placeholder="required"
            />

            <ControlledInput
              label={strings.contact_us_email_label}
              name="email"
              control={control}
              rules={{
                required: "Required.",
                pattern: {
                  value: emailRegex,
                  message: strings.contact_us_invalid_email_format,
                },
              }}
              type="email"
              placeholder="required"
            />

            <ControlledInput
              label={strings.contact_us_phone_label}
              name="phone"
              control={control}
              type="tel"
              placeholder="optional"
              rules={{}}
              maxLength={20}
            />
            <Row className="w-100">
              <Col xs={2} />
              <Col xs={10}>
                <Controller
                  name="reason"
                  defaultValue=""
                  control={control}
                  render={({ field }) => {
                    const { onChange, ...rest } = field;
                    return (
                      <FormGroup>
                        <ReasonTextArea
                          as="textarea"
                          placeholder={strings.contact_us_reason_placeholder}
                          rows={1}
                          onChange={(e: any) => {
                            if (textAreaRef.current !== null) {
                              textAreaRef.current.style.height = "0px";
                              const newHeight = textAreaRef.current.scrollHeight;
                              textAreaRef.current.style.height = newHeight + "px";
                            }
                            onChange(e);
                            setNumCharacters(
                              MAX_LENGTH_REASON - e.target.value.length
                            );
                          }}
                          maxLength={MAX_LENGTH_REASON}
                          {...rest}
                          ref={textAreaRef}
                          className={!!errors.reason ? "is-invalid w-100" : "w-100"}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="reason"
                          render={({ message }) =>
                            message !== undefined ? (
                              <Form.Control.Feedback
                                type="invalid"
                                className="text-start"
                              >
                                {message}
                              </Form.Control.Feedback>
                            ) : (
                              <></>
                            )
                          }
                        />
                        <MaximumCharacters>
                          <span>{strings.contact_us_maximum_characters}</span>
                          <Counter>{numCharacters}</Counter>
                        </MaximumCharacters>
                      </FormGroup>
                    );
                  }}
                  rules={{
                    required: "Required.",
                  }}
                />
              </Col>
            </Row>
            <Row className="w-100">
              <Col xs={2} />
              <Col xs={10} className="text-end">
                <ContactButton disabled={isLoading} type="submit">
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-2"
                    hidden={!isLoading}
                  />
                  {strings.contact_us_contact}
                </ContactButton>
              </Col>
            </Row>
          </Container>
          <SuccessModal
            title={strings.contact_us_thank_you_for_contact_us}
            content={strings.contact_us_contact_back_soon}
            action={strings.contact_us_modal_OK}
            showModal={isSubmittedContact}
            handleCloseModal={() => setIsSubmittedContact(false)}
          />
        </ContactRequestFormLayout>
      </ContainerLayout>
    </ContainerFluidLayout>
  );
}

export default ContactUsForm;
