import { TaskModule } from "./task-module";

export interface Task {
  id: string;
  company: string;
  module: TaskModule;
  name: string;
  status: string;
  taskType: string;
  updatedOn: string;
  statusDays: number;
}

export const getSubSection = (task: Task) => {
  return task.module.name;
};

export const getSection = (task: Task) => {
  return task.module.parentModule?.name;
};
