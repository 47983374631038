import { Modal, Row } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import styled from "styled-components";

export const AccountRow = styled(Row).attrs((_) => ({
  className: "ms-4 px-3 py-3 mt-3",
}))`
  display: flex;
  background-color: ${({ theme }) => theme.userManagement.rowPrimaryColor};
  border-radius: 1rem;
  align-items: center;
`;

export const StyledUserIcon = styled(FaUserAlt).attrs((_) => ({
  size: 18,
}))`
  color: ${({ theme }) => theme.userManagement.userIconSecondaryColor};
`;

export const UserDetailCol = styled.div<{ $width?: string }>`
  flex: 0 0 auto;
  width: ${({ $width }) => (!$width ? "fit-content" : $width)};
`;

export const RowTitleText = styled.div`
  color: ${({ theme }) => theme.userManagement.tabText};
`;

export const BodyWarningModal = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
