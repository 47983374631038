import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import styled from "styled-components";

export const RoundedModal = styled(Modal)`
  & .modal-content {
    border-radius: 16px;
    border: none;
  }
`;

export const Body = styled(ModalBody).attrs((_) => ({
  className: "p-4",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 16px;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.body};
    margin-bottom: 40px;
  }
`;
export const Header = styled(ModalHeader).attrs((_) => ({
  className: "pt-2 pb-0 px-3",
}))`
  border: none;
  display: flex;
  justify-content: flex-end;
  & svg {
    cursor: pointer;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
