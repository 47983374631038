import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { UserRoleType } from "../../../enums/user-role";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";
import { HeaderText } from "../common/styles";
import { Checkbox } from "./styles";
import React from "react";

interface Props {
  userRole?: UserRoleType;
  disabled?: boolean;
}

function MasqueradeAsAUser({ userRole, disabled }: Props) {
  const modelName = "allowedToMasquerade";
  const { control, setValue } = useFormContext();
  const userRoleType = useWatch({ control, name: "userRole" });

  const {
    field: { onChange: onControllerChange, value },
  } = useController({
    control,
    name: modelName,
    defaultValue: false,
  });

  useEffect(() => {
    if (
      userRole === getKeyByValueEnum(UserRoleType.MasterAdmin, UserRoleType)
    ) {
      setValue(modelName, true);
    }
    if (
      userRole === getKeyByValueEnum(UserRoleType.ModuleAdmin, UserRoleType) ||
      userRole === getKeyByValueEnum(UserRoleType.User, UserRoleType)
    ) {
      setValue(modelName, false);
    }
  }, [userRole]);

  if (
    !userRoleType ||
    userRoleType === getKeyByValueEnum(UserRoleType.User, UserRoleType)
  )
    return <React.Fragment />;

  return (
    <Row className="mt-3">
      <Col xs="9">
        <HeaderText>Masquerade as a user</HeaderText>
      </Col>
      <div className="ms-4 pt-3">
        <Checkbox
          label="Masquerade As A User"
          className="d-inline-block"
          data-testid="user-allow-to-masquerade"
          type="checkbox"
          id="user-allow-to-masquerade"
          checked={value}
          onChange={onControllerChange}
          disabled={disabled}
        />
      </div>
    </Row>
  );
}

export default MasqueradeAsAUser;
