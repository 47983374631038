import { Form } from "react-bootstrap";
import styled from "styled-components";

export const Checkbox = styled(Form.Check).attrs((_) => ({
  type: "checkbox",
}))`
  .form-check-input[disabled] ~ label.form-check-label {
    color: ${({ theme }) => theme.layout.text};
    opacity: 0.7;
  }
  .form-check-input[disabled] {
    background-color: ${({ theme }) => theme.lightGreyInput.primaryColor};
  }
`;
