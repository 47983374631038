import { useMemo } from "react";
import { PaginationModel } from "../../../../models/pagination";
import { FilterLayout } from "./styles";
import DropdownNoneOutline from "../../../../components/DropdownNoneOutline";
import { SortedByName } from "../../../../models/sorted-by-name";
import { SortedByStatus } from "../../../../models/sorted-by-status";

type Props = {
  itemPerPage: number;
  pagination: PaginationModel | undefined;
  sortedByName: SortedByName;
  sortedByNameChange: (value: string | null) => void;
  sortedByStatus: SortedByStatus | null;
  sortedByStatusChange: (value: string | null) => void;
};

function UserManagementFilter({
  pagination,
  itemPerPage,
  sortedByName,
  sortedByNameChange,
  sortedByStatus,
  sortedByStatusChange,
}: Props) {
  const displayingContent = useMemo(() => {
    if (!pagination || pagination.totalItems < 1) return "";

    const minItems = (pagination.currentPage - 1) * itemPerPage + 1;
    const maxItems =
      pagination.totalItems < pagination.currentPage * itemPerPage
        ? pagination.totalItems
        : pagination.currentPage * itemPerPage;

    return `Displaying ${minItems} - ${maxItems} of ${pagination.totalItems} users`;
  }, [pagination, itemPerPage]);

  return (
    <FilterLayout>
      <div>{displayingContent}</div>
      <DropdownNoneOutline
        label="Sort by"
        options={SortedByName}
        value={sortedByName}
        dropdownChange={sortedByNameChange}
      />
      <DropdownNoneOutline
        label="User status"
        options={SortedByStatus}
        value={sortedByStatus}
        hasDefaultValue
        dropdownChange={sortedByStatusChange}
      />
    </FilterLayout>
  );
}

export default UserManagementFilter;
