import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { TextInputRHF } from "../../../components/InputsWithRHF";
import { emailRegex } from "../../../helpers/regex-pattern";
import { HeaderText } from "../common/styles";
import LabelWrapper from "../LabelWrapper";

function ClientContactInformation() {
  const contactFormPrefix = "contact";

  return (
    <Fragment>
      <div>
        <HeaderText>Contact Information</HeaderText>
        <Row className="pt-3 px-4 gy-3">
          <Col xs="4">
            <LabelWrapper
              name={`${contactFormPrefix}.name`}
              label="Name"
              fixedWidth="3rem"
            >
              <TextInputRHF
                name={`${contactFormPrefix}.name`}
                placeholder="optional"
              />
            </LabelWrapper>
          </Col>
          <Col xs="4">
            <LabelWrapper
              name={`${contactFormPrefix}.role`}
              label="Role"
              fixedWidth="3rem"
            >
              <TextInputRHF
                name={`${contactFormPrefix}.role`}
                placeholder="optional"
              />
            </LabelWrapper>
          </Col>
          <Col xs="4"></Col>
          <Col xs="4">
            <LabelWrapper
              name={`${contactFormPrefix}.email`}
              label="Email"
              fixedWidth="3rem"
            >
              <TextInputRHF
                name={`${contactFormPrefix}.email`}
                placeholder="optional"
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: "Wrong email format.",
                  },
                }}
              />
            </LabelWrapper>
          </Col>
          <Col xs="4">
            <LabelWrapper
              name={`${contactFormPrefix}.phone`}
              label="Phone"
              fixedWidth="3rem"
            >
              <TextInputRHF
                name={`${contactFormPrefix}.phone`}
                placeholder="optional"
              />
            </LabelWrapper>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default ClientContactInformation;
