import React from "react";
import { isAdmin, User } from "../../../models/user";
import sessionService from "../../../services/session.service";
import AdminTaskView from "../Admin/TaskView";
import ClientTaskView from "../Client/TaskView";
import { Navigate } from "react-router";

function TaskView() {
  const user = sessionService.getCurrentUser() as User;
  if (!user) {
    return <Navigate to="/login" />;
  }

  return isAdmin(user) ? (
    <React.Fragment>
      <AdminTaskView />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <ClientTaskView />
    </React.Fragment>
  );
}

export default TaskView;
