import { useCallback, useEffect, useMemo, useState } from "react";
import { UserManagementLayout } from "./styles";
import ListingUser from "./models/listing-user";
import { searchUsers } from "./services/user.services";
import UserManagementTable from "./components/UserManagementTable";
import SearchUser from "./models/search-user";
import { PaginationModel } from "../../models/pagination";
import UserManagementHeader from "./components/UserManagementHeader";
import UserManagementFilter from "./components/UserManagementFilter";
import getKeyByValueEnum from "../../helpers/get-key-by-value-enum";
import SlrTablePagination from "../../components/SlrTablePagination";
import { SortedByName } from "../../models/sorted-by-name";
import { SortedByStatus } from "../../models/sorted-by-status";
import React from "react";
import LoadingSpinner from "../../components/LoadingSpinner";

function UserManagement() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<ListingUser>>([]);
  const [pagination, setPagination] = useState<PaginationModel | undefined>(
    undefined
  );

  const defaultPageValue = useMemo(() => {
    return {
      currentPage: 1,
      itemPerPage: 10,
    };
  }, []);

  const [filter, setFilter] = useState<SearchUser>({
    sortedByName: getKeyByValueEnum(SortedByName.ASC, SortedByName),
    sortedByStatus: null,
    searchText: "",
    ...defaultPageValue,
  });

  const sortedByNameChange = useCallback(
    (direction: string | null) => {
      setFilter((prevState: SearchUser) => {
        return {
          ...prevState,
          ...defaultPageValue,
          sortedByName: direction ?? "",
        };
      });
    },
    [defaultPageValue]
  );

  const sortedByStatusChange = useCallback(
    (status: string | null) => {
      setFilter((prevState: SearchUser) => {
        return {
          ...prevState,
          ...defaultPageValue,
          sortedByStatus: status,
        };
      });
    },
    [defaultPageValue]
  );

  const searchTextChange = useCallback(
    (searchText: string) => {
      setFilter((prevState: SearchUser) => {
        return {
          ...prevState,
          ...defaultPageValue,
          searchText: searchText,
        };
      });
    },
    [defaultPageValue]
  );

  const paginationChange = useCallback((toPage: number) => {
    setFilter((prevState: SearchUser) => {
      return {
        ...prevState,
        currentPage: toPage,
      };
    });
  }, []);

  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    searchUsers(filter)
      .then((data) => {
        setUsers(data.items);
        setPagination({
          currentPage: data.pageIndex,
          totalItems: data.totalItems,
          pageSize: data.pageSize,
        });
      })
      .catch((errs) => {
        console.log(errs);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    fetchUsers();
  }, [filter, fetchUsers]);

  const isApplyFilter = useMemo(() => {
    return !!filter.searchText || !!filter.sortedByStatus;
  }, [filter]);

  return (
    <UserManagementLayout>
      <UserManagementHeader searchTextChange={searchTextChange} />
      <UserManagementFilter
        pagination={pagination}
        itemPerPage={filter.itemPerPage}
        sortedByName={filter.sortedByName as SortedByName}
        sortedByNameChange={sortedByNameChange}
        sortedByStatus={filter.sortedByStatus as SortedByStatus}
        sortedByStatusChange={sortedByStatusChange}
      />

      {!isLoading ? (
        <React.Fragment>
          <UserManagementTable users={users} isSearching={isApplyFilter} />

          {pagination && (
            <SlrTablePagination
              {...pagination}
              pageChanged={paginationChange}
            />
          )}
        </React.Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </UserManagementLayout>
  );
}

export default UserManagement;
