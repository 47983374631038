import { handleResponse } from "../helpers/handle-response";
import { SignInResult, User } from "../models/user";
import { EditUserFilter } from "../models/edit-user-filter";

class SessionService {
  private _sessionKey = "_user";

  private setCurrentUser(user: User | any): User | any {
    if (!user) {
      localStorage.removeItem(this._sessionKey);
    } else {
      localStorage.setItem(this._sessionKey, JSON.stringify(user));
    }
    return user;
  }

  getCurrentUser(): User | any {
    const user = localStorage.getItem(this._sessionKey);
    if (user) {
      return JSON.parse(user);
    }

    return undefined;
  }

  refreshToken(): Promise<User | undefined> {
    const { token, refreshToken } = this.getCurrentUser()!;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, refreshToken }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/refreshToken`,
      requestOptions
    )
      .then(handleResponse)
      .then((tokenModel) => {
        return this.updateToken(tokenModel.token, tokenModel.refreshToken);
      });
  }

  updateToken(token: string, refreshToken: string) {
    const oldUser = this.getCurrentUser();
    const newUser = {
      ...oldUser,
      token,
      refreshToken,
    } as User;

    this.setCurrentUser(newUser);

    return newUser;
  }

  getMyInfo(token?: any): Promise<any> {
    const optionsWithProvidedToken = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const defaultOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/me`,
      token ? optionsWithProvidedToken : defaultOptions
    )
      .then(handleResponse)
      .then((user) => {
        this.setCurrentUser(user);
        return user;
      });
  }

  logOut(): Promise<void> {
    const currentUser = this.getCurrentUser();
    if (!currentUser) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    const { token, refreshToken } = currentUser;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, refreshToken }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/logout`,
      requestOptions
    )
      .then(handleResponse)
      .then(() => this.setCurrentUser(undefined));
  }

  login(email: string, password: string) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/login`,
      requestOptions
    )
      .then(handleResponse)
      .then((result: any) => {
        if (result.landingPage) window.location.href = result.landingPage;
        else return result;
      });
  }

  resetPassword(email: string, password: string, token: string): Promise<any> {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, token }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/resetpassword`,
      requestOptions
    ).then(handleResponse);
  }

  checkEmailConfirmed(email: string): Promise<any> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/confirm-email/${email}`,
      requestOptions
    )
      .then(handleResponse)
      .then((result: any) => {
        if (result.landingPage) window.location.href = result.landingPage;
        else return result;
      });
  }

  checkEmailToken(email: string, token: string): Promise<any> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetch(
      `${
        process.env.REACT_APP_API_END_POINT
      }/auth/verify-email-token?email=${encodeURIComponent(
        email
      )}&token=${encodeURIComponent(token)}`,
      requestOptions
    ).then(handleResponse);
  }

  resendCreatePasswordEmail(email: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/resend-create-password`,
      requestOptions
    ).then(handleResponse);
  }

  createPassword(email: string, password: string, token: string): Promise<any> {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, token }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/create-password`,
      requestOptions
    )
      .then(handleResponse)
      .then((result: any) => {
        if (result.landingPage) window.location.href = result.landingPage;
        else return result;
      });
  }

  sendResetPasswordEmail(email: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, module: "SLR" }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/resetpassword`,
      requestOptions
    ).then(handleResponse);
  }

  getAllUsers(filter: EditUserFilter) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/users?email=${filter.email}&itemsPerPage=${filter.itemsPerPage}&currentPage=${filter.currentPage}`,
      requestOptions
    ).then(handleResponse);
  }

  getLandingPage(module: string) {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.getCurrentUser()?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/modules/${module}/landing-page`,
      requestOptions
    ).then(handleResponse);
  }

  masqueradeClient(clientId: number, module: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ clientId, module }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/masquerade`,
      requestOptions
    ).then(handleResponse);
  }

  getAuthenticatorUri(token: string): Promise<any> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/authenticator/uri`,
      requestOptions
    ).then(handleResponse);
  }

  verifyAuthenticatorToken(token: string, code: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ code, module: "B4SI" }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/authenticator/verify`,
      requestOptions
    )
      .then(handleResponse)
      .then(
        (result: SignInResult) => (window.location.href = result.landingPage)
      );
  }

  login2Fa(token: string, code: string, module?: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ code, module }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/login-2fa`,
      requestOptions
    )
      .then(handleResponse)
      .then((result: SignInResult) => {
        window.location.href = result.landingPage;
      });
  }
}

const sessionService = new SessionService();

export default sessionService;
