import { capitalizeFirstLetter } from "../../../helpers/capitalize-first-letter";
import { handleResponse } from "../../../helpers/handle-response";
import { parseDayMonth } from "../../../helpers/parse-date-month";
import { Client } from "../../../models/client";
import { ClientUser } from "../../../models/client-user";
import { IdName } from "../../../models/id-name";
import { UserActivation } from "../../../models/user-activation";
import sessionService from "../../../services/session.service";
import SearchClient from "../models/search-client";

export function searchClientAccounts(filter: SearchClient) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };

  const { sortedByName, sortedByStatus, currentPage, itemPerPage, searchText } =
    { ...filter };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients?` +
      new URLSearchParams({
        sortedByName,
        sortedByStatus: sortedByStatus ?? "",
        currentPage: currentPage.toString(),
        itemPerPage: itemPerPage.toString(),
        searchText: searchText ?? "",
      }),
    requestOptions
  ).then(handleResponse);
}

function createClientFormData(payload: Client, file: File | null) {
  const formData = new FormData();
  for (let dataKey in payload) {
    if (dataKey === "logo") {
    } else if (dataKey === "address") {
      for (let addressKey in payload[dataKey]) {
        const value = (payload[dataKey] as any)[addressKey] as any;
        formData.append(
          `Address[${addressKey}]`,
          !!value ? value.toString() : ""
        );
      }
    } else if (dataKey === "contact") {
      for (let addressKey in payload[dataKey]) {
        const value = (payload[dataKey] as any)[addressKey] as any;
        formData.append(
          `Contact[${addressKey}]`,
          !!value ? value.toString() : ""
        );
      }
    } else if (dataKey === "features") {
      (payload[dataKey] as number[]).forEach((f) =>
        formData.append(`Features[]`, f.toString())
      );
    } else if (dataKey === "globalFeatures") {
      (payload[dataKey] as number[]).forEach((f) =>
        formData.append(`GlobalFeatures[]`, f.toString())
      );
    } else if (dataKey === "sector") {
      formData.append(
        capitalizeFirstLetter(dataKey),
        (payload[dataKey] as string | undefined) ?? ""
      );
    } else if (dataKey === "fromDate" || dataKey === "toDate") {
      const dayMonthValue = parseDayMonth(payload[dataKey]);
      if (dayMonthValue) {
        formData.append(
          `${capitalizeFirstLetter(dataKey)}[Day]`,
          dayMonthValue.day
        );
        formData.append(
          `${capitalizeFirstLetter(dataKey)}[Month]`,
          dayMonthValue.month
        );
      }
    } else {
      formData.append(
        capitalizeFirstLetter(dataKey),
        ((payload as any)[dataKey] as any)?.toString()
      );
    }
  }
  if (file !== null) {
    formData.append("LogoFile", file, file.name);
  }
  return formData;
}

export function createClient(payload: Client, file: File | null) {
  const requestOptions = {
    method: "POST",
    body: createClientFormData(payload, file),
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients`,
    requestOptions
  ).then(handleResponse);
}

export function updateClient(
  clientId: string,
  payload: Client,
  file: File | null
) {
  const formData = createClientFormData(payload, file);
  formData.append("ClientId", clientId);
  const requestOptions = {
    method: "PUT",
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients`,
    requestOptions
  ).then(handleResponse);
}

export function updateUserActivations(
  clientId: string,
  payload: { userActivations: UserActivation[] }
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/${clientId}/users/activations`,
    requestOptions
  ).then(handleResponse);
}

export function getClient(clientId: string) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/${clientId}`,
    requestOptions
  ).then(handleResponse);
}

export function getClientUsers(clientId: string) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/${clientId}/users`,
    requestOptions
  ).then(handleResponse);
}

export function getClientItems(): Promise<IdName[]> {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/items`,
    requestOptions
  ).then(handleResponse);
}

export function getManageableClientItems(): Promise<IdName[]> {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/items/mine`,
    requestOptions
  ).then(handleResponse);
}

export function createUser(clientId: string, payload: ClientUser) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/${clientId}/users`,
    requestOptions
  ).then(handleResponse);
}

export function getUser(userId: string) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/users/${userId}`,
    requestOptions
  ).then(handleResponse);
}

export function updateUser(clientId: string, payload: ClientUser) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/${clientId}/users`,
    requestOptions
  ).then(handleResponse);
}

export function getUserInfo(email: string): Promise<IdName> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_CLIENT_MANAGEMENT_API}/clients/users/${email}/info`,
    requestOptions
  ).then(handleResponse);
}
