import _ from "lodash";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ModalContentState } from "../../../models/modal-content-state";

import { uploadFiles } from "../../../services/document.service";
import { OrangeSolidButton } from "../../common";
import DragDropFile from "../../DragDropFile";
import { useNotification, TypeNotification } from "../../NotificationProvider";
import { ButtonWrapper, FileTypeErrorWrapper, NothingWrapper } from "./styles";
import sessionService from "../../../services/session.service";
import { User, isOnlyDocumentFeature } from "../../../models/user";
import FileInfoForm from "./FileInfoForm";

type Props = {
  setModalContentState: React.Dispatch<React.SetStateAction<ModalContentState>>;
  modalContentState: ModalContentState;
};

function UploadFilesForm({ setModalContentState, modalContentState }: Props) {
  const { showError, showInfo } = useNotification();
  const methods = useForm({
    mode: "all",
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const [files, setFiles] = useState<FileList | null>(null);

  const currentUser = sessionService.getCurrentUser() as User | undefined;
  const isEmptyModules = useMemo(
    () => !!currentUser && isOnlyDocumentFeature(currentUser),
    [currentUser]
  );

  const handleUpload = handleSubmit((data) => {
    let dataModel = {
      module: null,
      activityType: null,
      documentPurpose: null,
      activityId: null,
      activity: null,
      files: files!,
      assignable: module ? true : false,
    };
    if (!isEmptyModules) {
      dataModel = {
        ...dataModel,
        module: data.module,
        activityType: data.activityType,
        documentPurpose: data.documentPurpose,
        activityId: null,
        activity: data.activity,
      };
    }

    setModalContentState(ModalContentState.Importing);
    uploadFiles(dataModel)
      .then((_) => {
        showInfo("File(s) uploaded!", TypeNotification.success, 5000);
        setModalContentState(ModalContentState.Successful);
      })
      .catch((errors) => {
        showError(
          errors.status,
          "Have error(s) occurred when uploading file(s)"
        );
        setModalContentState(ModalContentState.Common);
      });
  });

  return (
    <>
      <DragDropFile
        files={files}
        setFiles={setFiles}
        setModalContentState={setModalContentState}
      />
      <FormProvider {...methods}>
        {!isEmptyModules && <FileInfoForm />}

        <ButtonWrapper>
          <OrangeSolidButton
            onClick={handleUpload}
            disabled={!isValid || files === null ? true : files.length === 0}
          >
            UPLOAD
          </OrangeSolidButton>
        </ButtonWrapper>
      </FormProvider>

      {modalContentState === ModalContentState.Nothing && (
        <NothingWrapper>This file is empty. Please try again.</NothingWrapper>
      )}

      {modalContentState === ModalContentState.FileTypeError && (
        <FileTypeErrorWrapper>
          Please select a valid file type (Invalid file types: .exe, .cmd, .bat,
          .bin, .run).
        </FileTypeErrorWrapper>
      )}
    </>
  );
}

export default UploadFilesForm;
