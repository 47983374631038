import styled, { css } from "styled-components";
import { FaChevronUp } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { DarkBlueOutlinedButton } from "../../../../../components/common";

interface SortChevronProps {
  $desc: boolean;
  $show: boolean;
}

export const TableHeaderCell = styled.th<{ width?: string }>`
  font-weight: bold !important;
  text-align: center;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`;

export const SortableWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const SortChevron = styled(FaChevronUp)<SortChevronProps>`
  position: absolute;
  font-size: 0.75rem;
  top: 0.125rem;
  right: 0rem;
  transition-duration: 0.2s;
  transition-property: transform;
  display: ${({ $show }) => ($show ? "inline-block" : "none")};
  ${({ $desc }) =>
    !$desc &&
    css`
      transform: rotate(180deg);
    `};
`;

export const CustomTable = styled(Table).attrs((_) => ({
  bordered: true,
  hover: true,
}))`
  color: ${({ theme }) => theme.documentTable.primaryColor};
  border-color: ${({ theme }) => theme.documentTable.secondaryColor};
  td {
    border-color: ${({ theme }) => theme.documentTable.secondaryColor};
    vertical-align: middle;
  }
  & > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: ${({ theme }) => theme.documentTable.stripedBgColor};
    color: ${({ theme }) => theme.documentTable.stripedColor};
  }
`;

export const CenterWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${({ theme }) => theme.documentTable.primaryColor};
  font-size: 1.5rem;
`;

export const ReviewButton = styled(DarkBlueOutlinedButton)`
  font-weight: normal;
  :disabled {
    background-color: ${({ theme }) =>
      theme.darkBlueButton.secondaryColor}!important;
    color: ${({ theme }) => theme.darkBlueButton.primaryColor}!important;
    border-color: ${({ theme }) => theme.darkBlueButton.primaryColor}!important;
    opacity: 0.25;
  }
`;
