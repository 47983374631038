import _ from "lodash";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

type Props = {
  name: string;
  rules?: any;
  onValueChange?: (value: any) => void;
  onBlurInput?: (e: any) => void;
};
function withReactHookForm<T>(WrappedComponent: React.ComponentType<T>) {
  const InputComponentWithReactHookForm = React.forwardRef(
    (
      {
        name,
        rules,
        onValueChange,
        onBlurInput,
        ...passedProps
      }: Omit<T, "externalValue" | "setExternalValue"> & Props,
      ref
    ) => {
      const { control } = useFormContext();
      const {
        field: {
          onChange: onControllerChange,
          onBlur: onControllerBlur,
          value,
          ...restOfField
        },
        fieldState: { error },
      } = useController({
        control,
        name,
        rules: rules,
        defaultValue: null,
      });

      return (
        <WrappedComponent
          {...(passedProps as unknown as T)}
          externalValue={value}
          setExternalValue={(value: any) => {
            onControllerChange(value);
            onValueChange && onValueChange(value);
          }}
          onBlur={(e: any) => {
            onBlurInput ? onBlurInput(e) : onControllerBlur();
          }}
          isInvalid={!!error}
          {...restOfField}
          ref={ref}
        />
      );
    }
  );
  return InputComponentWithReactHookForm;
}

export default withReactHookForm;
