import React, { useEffect, useMemo, useState } from "react";
import ModalDropdown from "../../ModalDropdown";
import ModalTextInput from "../../ModalTextInput";
import { getModules } from "../../../../services/document.service";

function FileInfoForm() {
  const [modules, setModules] = useState<any[]>([]);
  useEffect(() => {
    getModules().then((res) => {
      setModules(res);
    });
  }, []);

  const moduleOptions = useMemo(() => {
    const res: {
      [key: string]: string;
    } = {};
    modules.forEach((m) => {
      res[m.name] = m.name;
    });
    return res;
  }, [modules]);

  return (
    <React.Fragment>
      <ModalDropdown
        name="module"
        label="Business Module"
        options={moduleOptions}
      />
      <ModalTextInput name="activityType" label="Activity Type" />
      <ModalTextInput name="documentPurpose" label="Document Purpose" />
      <ModalTextInput name="activity" label="Activity ID/Name" />
    </React.Fragment>
  );
}

export default FileInfoForm;
