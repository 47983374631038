import { TaskManagementProvider } from "./contexts/TaskManagementProvider";
import SideBar from "./SideBar";
import { Layout, MainContainer, SideBarLayout, TaskListLayout } from "./styles";
import TaskView from "./TaskView";

function TaskManagement() {
  return (
    <TaskManagementProvider>
      <Layout>
        <MainContainer>
          <SideBarLayout>
            <SideBar />
          </SideBarLayout>
          <TaskListLayout>
            <TaskView />
          </TaskListLayout>
        </MainContainer>
      </Layout>
    </TaskManagementProvider>
  );
}

export default TaskManagement;
