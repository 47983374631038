import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Chevron,
  DropDownWrapper,
  Input,
  MasqueradeValueWrapper,
  DropdownValue,
  GoButton,
  MasqueradeLabel,
} from "./styles";
import { Form } from "react-bootstrap";
import AccessibleModuleModal from "./AccessibleModuleModal";
import useOnScreen from "../../hooks/useOnScreen";
import { MdOutlineSearch } from "react-icons/md";

interface Props {
  options: { [key: string | number]: string };
  placeholder: string;
}

function MasqueradeDropdown({ options, placeholder }: Props) {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<{
    [key: string | number]: string;
  }>(options);

  const isVisible = useOnScreen(dropDownRef);

  const handleSearch = (text: string) => {
    setSearch(text);
    const filteredArray = Object.entries(options).filter((o) => {
      return o[1].toLowerCase().includes(text.toLowerCase());
    });

    let filteredObject = {};
    filteredArray.forEach((option) => {
      filteredObject = { ...filteredObject, [option[0]]: option[1] };
    });
    setFilteredOptions(filteredObject);
  };

  useEffect(() => {
    if (!isVisible) {
      setShow(false);
      setSearch("");
      setFilteredOptions(options);
    }
  }, [isVisible, options]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (!show) {
      setSearch("");
      setFilteredOptions(options);
    }
  }, [options, show]);

  const clickGoButton = useCallback((clientId: number) => {
    setShowModal(true);
    setSelectedItem(clientId);
  }, []);

  return (
    <React.Fragment>
      <DropDownWrapper ref={dropDownRef}>
        <div ref={inputWrapperRef} onClick={() => setShow((prev) => !prev)}>
          <Chevron />
          <Input $paddingEnd={show}>{placeholder}</Input>
        </div>
        <div className={show ? "options show" : "options"}>
          <div className="search-box">
            <Form.Label htmlFor="masquerade-search-clients">
              <MdOutlineSearch />
            </Form.Label>
            <Form.Control
              id="masquerade-search-clients"
              type="input"
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <DropdownValue>
            {Object.entries(filteredOptions)
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map((opt) => (
                <MasqueradeValueWrapper key={opt[0]}>
                  <MasqueradeLabel>{opt[1]}</MasqueradeLabel>
                  <div>
                    <GoButton onClick={() => clickGoButton(Number(opt[0]))}>
                      Go
                    </GoButton>
                  </div>
                </MasqueradeValueWrapper>
              ))}
          </DropdownValue>
        </div>
      </DropDownWrapper>
      <AccessibleModuleModal
        clientId={selectedItem}
        show={showModal}
        onShow={setShowModal}
      />
    </React.Fragment>
  );
}

export default MasqueradeDropdown;
