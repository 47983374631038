import styled from "styled-components";
import { Form } from "react-bootstrap";
import { GreyOutlinedButton, GreyOutlinedInput } from "../../../components/common";
import ContactRequestHeader from "../ContactRequestForm/images/ContactRequestHeader.png";

export const ContainerFluidLayout = styled.div.attrs(() => (
  {
    className: "container-fluid"
  }
))`
  width: 768px !important;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  padding: 0px;
`;


export const ContainerLayout = styled.div.attrs(() => (
  {
    className: "container align-middle"
  }
))`
    max-width: 1180px;
    border: 3px solid rgb(38, 51, 38);
    padding: 0px;    
`;

export const ContactRequestFormLayout = styled.div.attrs((_) => ({
  className: "pt-5",
}))`
  padding: 24px;
  form {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  label {
    color: #575C70;
    font-weight: bold;
    font-size: 1.25rem;
  }
  label:nth-child(1) {
    margin-bottom: 15px;
  }
  input {
    border-color: rgb(185, 180, 187) !important;
  }
`;

export const LabelTitle = styled.h2`
  color: #575C70;
  font-weight: bold;
`;

export const MaximumCharacters = styled(Form.Control.Feedback)`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.styledInput.limitPrimaryText};
  border-color: rgb(185, 180, 187) !important;
  span {
    font-size: 1.1rem;
  }
`;
export const Counter = styled.span`
  color: ${({ children, theme }) =>
    children === 0 ? theme.styledInput.limitSecondaryText : "auto"};
  font-weight: ${({ children }) => (children === 0 ? "bold" : "normal")};
`;

export const ContactUsError = styled.div`
    width: 100%;
    font-size: 1.1em;
    color: #dc3545;
`;

export const ContactButton = styled(GreyOutlinedButton)`
  border-radius: 1rem;
`;

export const HeaderPage = styled.nav.attrs(() => (
  {
    className: "navbar navbar-expand-md navbar-light"
  }
))`
  background-image: url(${ContactRequestHeader});
  background-size: cover;  
  padding: 0.25rem 24px;
  margin: -1px;

  span {
    font-size: 0.9rem;
    color: rgb(255, 255, 255);
  }
`;

export const Logo = styled.img`
  height: 36px;
`;


export const ReasonTextArea = styled(GreyOutlinedInput)`
  overflow:hidden;
  border-color: rgb(185, 180, 187) !important;
`;