import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle<{ theme: any }>`
.invalid-feedback{
    color:"#e51935";
    font-style: italic;
}

body {
  margin: 0;
  //TODO: Remove font-family definition at component level if there is only one font family needed for branding
  font-family: "Calibri", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

`;
