import ClientManagement from "../../pages/ClientManagement";
import EditClient from "../../pages/EditClient";
import ProtectedRoute from "../../components/ProtectedRoute";
import { ModuleFeature } from "../../models/module-feature";

export const ClientManagementRoutes = [
  {
    path: "clients",
    element: (
      <ProtectedRoute feature={ModuleFeature.ClientManagement} isAdminFeature />
    ),
    children: [
      {
        path: "",
        element: <ClientManagement />,
      },
      {
        path: "new",
        element: <EditClient />,
      },
      {
        path: ":clientId",
        element: <EditClient />,
      },
    ],
  },
];
