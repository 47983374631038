import { useReducer, useEffect, useState, useCallback } from "react";
import {
  DocumentQueryActionKind,
  documentQueryReducer,
  initialState,
} from "./documentQueryReducer";
import { useLocation } from "react-router";
import { getFilterOptions } from "../../services/document.service";

function useDocumentQuery() {
  const [isProcessingURL, setIsProcessingURL] = useState(true);
  const urlQuery = new URLSearchParams(useLocation().search);
  const [filterOptions, setFilterOptions] = useState({
    modules: [],
    types: [],
    activityTypes: [],
    activities: [],
    purposes: [],
    creators: [],
  });
  const [documentQuery, dispatch] = useReducer(
    documentQueryReducer,
    initialState
  );
  const fetchFilterOptions = () => {
    getFilterOptions(documentQuery.showDeletedItems).then((res) => {
      if (res) {
        const {
          modules,
          types,
          activityTypes,
          activities,
          purposes,
          creators,
        } = res;
        setFilterOptions({
          modules: modules ? modules : [],
          types: types ? types : [],
          activityTypes: activityTypes ? activityTypes : [],
          activities: activities ? activities : [],
          purposes: purposes ? purposes : [],
          creators: creators ? creators : [],
        });
        dispatch({
          type: DocumentQueryActionKind.UPDATE_FILTER,
          payload: {
            module: modules.includes(documentQuery.module)
              ? documentQuery.module
              : initialState.module,
            type: types.includes(documentQuery.type)
              ? documentQuery.type
              : initialState.type,
            activityType: activityTypes.includes(documentQuery.activityType)
              ? documentQuery.activityType
              : initialState.activityType,
            activity: activities.includes(documentQuery.activity)
              ? documentQuery.activity
              : initialState.activity,
            purpose: purposes.includes(documentQuery.purpose)
              ? documentQuery.purpose
              : initialState.purpose,
            creator: creators.includes(documentQuery.creator)
              ? documentQuery.creator
              : initialState.creator,
          },
        });
      }
    });
  };
  useEffect(() => {
    fetchFilterOptions();
  }, [documentQuery.showDeletedItems]);
  useEffect(() => {
    getFilterOptions(documentQuery.showDeletedItems)
      .then((res) => {
        if (res) {
          const {
            modules,
            types,
            activityTypes,
            activities,
            purposes,
            creators,
          } = res;
          setFilterOptions({
            modules: modules ? modules : [],
            types: types ? types : [],
            activityTypes: activityTypes ? activityTypes : [],
            activities: activities ? activities : [],
            purposes: purposes ? purposes : [],
            creators: creators ? creators : [],
          });

          dispatch({
            type: DocumentQueryActionKind.UPDATE_FILTER,
            payload: {
              search: urlQuery.get("search") ?? initialState.search,
              module:
                urlQuery.get("module") &&
                modules.includes(urlQuery.get("module"))
                  ? urlQuery.get("module")
                  : initialState.module,
              type:
                urlQuery.get("type") && types.includes(urlQuery.get("type"))
                  ? urlQuery.get("type")
                  : initialState.type,
              activityType:
                urlQuery.get("activityType") &&
                activityTypes.includes(urlQuery.get("activityType"))
                  ? urlQuery.get("activityType")
                  : initialState.activityType,
              activity:
                urlQuery.get("activity") &&
                activities.includes(urlQuery.get("activity"))
                  ? urlQuery.get("activity")
                  : initialState.activity,
              purpose:
                urlQuery.get("purpose") &&
                purposes.includes(urlQuery.get("purpose"))
                  ? urlQuery.get("purpose")
                  : initialState.purpose,
              creator:
                urlQuery.get("creator") &&
                creators.includes(urlQuery.get("creator"))
                  ? urlQuery.get("creator")
                  : initialState.creator,
              showDeletedItems:
                urlQuery.get("showDeletedItems") === "true" ??
                initialState.showDeletedItems,
            },
          });
        }
      })
      .finally(() => {
        setIsProcessingURL(false);
      });
  }, []);
  useEffect(() => {
    if (!isProcessingURL) {
      const { itemsPerPage, orderBy, sortDesc, currentPage, ...restOfQuery } =
        documentQuery;
      const newQuery: { [key: string]: any } = {};
      Object.keys(restOfQuery).forEach((filter) => {
        if (restOfQuery[filter as keyof typeof restOfQuery])
          newQuery[filter] = restOfQuery[filter as keyof typeof restOfQuery];
      });
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;

      if (Object.keys(newQuery).length !== 0) {
        newurl += "?" + new URLSearchParams(newQuery).toString();
      }
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }, [documentQuery]);
  return {
    documentQuery,
    filterOptions,
    dispatch,
    fetchFilterOptions,
    isProcessingURL,
  };
}

export default useDocumentQuery;
