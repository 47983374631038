import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import styled from "styled-components";

export const RoundedModal = styled(Modal)`
  & .modal-content {
    border-radius: 16px;
    border: none;
  }
`;

export const Body = styled(ModalBody).attrs((_) => ({
  className: "px-4",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    color: rgb(36, 185, 7);
    margin-top: 10px;
    margin-bottom: 15px;
    font-family: ${({ theme }) => theme.fontFamily.headlines};
    font-weight: bold;
  }
  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamily.body};
  }
`;
export const Footer = styled(ModalFooter)`
  background-color: rgb(36, 185, 7);
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: -1px;
  margin-right: -1px;
`;
