import { handleResponse } from "../helpers/handle-response";
import { NameValue } from "../models/name-value";
import { TaskFilter } from "../models/task-filter";
import sessionService from "./session.service";

export function filterTasks(payload: TaskFilter) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };
  const {
    module,
    search,
    state,
    itemsPerPage,
    currentPage,
    orderBy,
    sortDesc,
  } = payload;
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/tasks?` +
      new URLSearchParams({
        module,
        search: search ?? "",
        state: state ?? "",
        itemsPerPage: itemsPerPage.toString(),
        currentPage: currentPage.toString(),
        orderBy: orderBy.toString(),
        sortDesc: sortDesc.toString(),
      }),
    requestOptions
  ).then(handleResponse);
}

export function getTaskUrl(id: string) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };
  console.log(id, `${process.env.REACT_APP_API_END_POINT}/tasks/${id}/url`);

  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/tasks/${id}/url`,
    requestOptions
  ).then(handleResponse);
}

export function getTaskStatistic() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/tasks/statistic`,
    requestOptions
  ).then(handleResponse);
}

export function getTaskStates(module: string): Promise<NameValue[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/tasks/states?` +
      new URLSearchParams({
        module,
      }),
    requestOptions
  ).then(handleResponse);
}

export function getAllTaskModules() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/tasks/modules`,
    requestOptions
  ).then(handleResponse);
}
