import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import { CustomMenu } from "./CustomMenu";
import CustomMenuWithSearch from "./CustomMenuWithSearch";
import { CustomToggle } from "./CustomToggle";
import _ from "lodash";
type Props = React.HTMLProps<HTMLSelectElement> & {
  externalValue: number | null;
  setExternalValue: (value: string | null) => void;

  options: { [key: string]: string };

  isInvalid?: boolean;
  isPaddedRight?: boolean;
  optionsDefault?: { [key: string]: string };
  withSearch?: boolean;
  withDisplaySort?: boolean;
  externalOnBlur?: () => any;
};

const DropdownInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    options,
    isInvalid = undefined,
    isPaddedRight = false,
    optionsDefault,
    withSearch,
    withDisplaySort,
    externalOnBlur = () => {},
    onBlur,
    onFocus,
    name,
    placeholder,
  }: Props) => {
    const wrapperRef = useRef<any | null>(null);

    const [isFocused, setIsFocused] = useState<boolean | null>(null);
    useEffect(() => {
      if (isFocused === false) externalOnBlur();
    }, [isFocused]);
    useEffect(() => {
      function handleFocus(event: any) {
        if (wrapperRef.current)
          if (wrapperRef.current.contains(event.target)) {
            onFocus && onFocus(event);
            setIsFocused(true);
          } else
            setIsFocused((prev) => {
              if (prev === null) return null;
              else {
                onBlur && onBlur(event);
                return false;
              }
            });
      }

      document.addEventListener("mousedown", handleFocus);
      return () => {
        document.removeEventListener("mousedown", handleFocus);
      };
    }, [wrapperRef]);

    const renderValue = () => {
      if (!externalValue) return !placeholder ? "Select" : placeholder;
      if (optionsDefault) return optionsDefault[externalValue];
      return options[externalValue];
    };

    const optionsArray = useMemo(
      () =>
        !!withDisplaySort
          ? _.sortBy(Object.entries(options), "1")
          : Object.entries(options),
      [options, withDisplaySort]
    );

    return (
      <Dropdown ref={wrapperRef}>
        <Dropdown.Toggle
          name={name}
          as={CustomToggle}
          disabled={disabled}
          $isPaddedRight={isPaddedRight}
          $isInvalid={isInvalid}
          $isDisabled={disabled}
        >
          {renderValue()}
        </Dropdown.Toggle>

        <Dropdown.Menu as={!withSearch ? CustomMenu : CustomMenuWithSearch}>
          <Dropdown.Item
            value=""
            onClick={() => {
              setExternalValue(null);
            }}
          >
            {!placeholder ? "Select" : placeholder}
          </Dropdown.Item>
          {optionsArray.map((o) => (
            <Dropdown.Item
              key={o[0]}
              value={o[1]}
              onClick={() => {
                setExternalValue(o[0]);
              }}
            >
              {o[1]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
export default DropdownInput;
