import { Form } from "react-bootstrap";
import styled, { css } from "styled-components";

export const ToolBarLayout = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-center;
`;

export const FormSelect = styled(Form.Select)<{ width?: string }>`
  font-family: ${({ theme }) => theme.fontFamily.body};
  border-radius: 4px;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `};
  :invalid {
    color: rgb(117, 117, 117);
    color: gray;
    font-style: italic;
    font-weight: 500;
  }
  option:first-child {
    font-style: italic;
    font-weight: 400;
  }
  option:not(:first-child) {
    color: black;
    font-style: normal;
    font-weight: normal;
  }
`;
