import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { RadioListInputRHF } from "../../../components/InputsWithRHF";
import { UserRoleType } from "../../../enums/user-role";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";
import { Client } from "../../../models/client";
import sessionService from "../../../services/session.service";
import { HeaderText } from "../common/styles";
import SelectModuleAdminModal from "./SelectModuleAdminModal";
import SelectUserWarningModal from "./SelectUserWarningModal";

interface AppProps {
  client?: Client;
  selectedClientId?: number;
  setUserRole: (value: UserRoleType) => void;
  disabled?: boolean;
}

export default function UserRole({
  client,
  selectedClientId,
  setUserRole,
  disabled,
}: AppProps) {
  const { userId } = useParams();
  const modelName = "userRole";
  const {
    control,
    setValue,
    resetField,
    formState: { isDirty },
  } = useFormContext();

  const [displayRole, setDisplayRole] = useState<{ [key: string]: string }>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [backupSelectedRole, setBackupSelectedRole] = useState<
    UserRoleType | undefined
  >(undefined);
  const [showSelectModuleAdminModal, setShowSelectModuleAdminModal] =
    useState<boolean>(false);

  const { role: loggedInRole } = sessionService.getCurrentUser() || {};
  const userRole = useWatch({ control, name: modelName }) as
    | UserRoleType
    | undefined;

  const keyOfRole = useCallback(
    (value: UserRoleType) =>
      getKeyByValueEnum(value, UserRoleType) as keyof typeof UserRoleType,
    []
  );

  useEffect(() => {
    if (!client) {
      setDisplayRole({});
      return;
    }

    if (client.internalSlr) {
      setDisplayRole(UserRoleType);
      !userId && setValue(modelName, "");
    } else {
      setDisplayRole({ [keyOfRole(UserRoleType.User)]: UserRoleType.User });
    }
  }, [client, userId, keyOfRole]);

  useEffect(() => {
    if (selectedClientId) {
      if (client?.internalSlr) {
        resetField(modelName, {
          defaultValue: !isDirty && userRole ? keyOfRole(userRole) : "",
        });
      } else {
        resetField(modelName, {
          defaultValue: keyOfRole(UserRoleType.User),
        });
      }
    }
  }, [selectedClientId, client]);

  const disabledOptions = useMemo(() => {
    return loggedInRole === keyOfRole(UserRoleType.ModuleAdmin)
      ? {
          [keyOfRole(UserRoleType.MasterAdmin)]: UserRoleType.MasterAdmin,
        }
      : {};
  }, [keyOfRole, loggedInRole]);

  const handleCloseModal = useCallback(() => {
    if (backupSelectedRole) {
      setValue(modelName, backupSelectedRole);
      setUserRole(backupSelectedRole);
    }

    setShowModal(false);
    setBackupSelectedRole(undefined);
  }, [backupSelectedRole, setUserRole, setValue]);

  const handleConfirmed = useCallback(() => {
    setShowModal(false);
    setBackupSelectedRole(undefined);
  }, []);

  const roleChanged = useCallback(
    (value: UserRoleType) => {
      setBackupSelectedRole(userRole);
      setUserRole(value);

      if (
        userRole &&
        value &&
        userRole !== keyOfRole(UserRoleType.User) &&
        value === keyOfRole(UserRoleType.User)
      ) {
        setShowModal(true);
      } else if (value === keyOfRole(UserRoleType.ModuleAdmin)) {
        setShowSelectModuleAdminModal(true);
      }
    },
    [keyOfRole, setUserRole, userRole]
  );

  if (!client) return <React.Fragment />;

  return (
    <React.Fragment>
      <div className="mt-3">
        <HeaderText>User Roles/Permission</HeaderText>
        <div className="ps-4 p-3">
          <RadioListInputRHF
            name={modelName}
            options={displayRole}
            disabledOptions={disabledOptions}
            rules={{ required: "Required." }}
            onValueChange={(value) => {
              roleChanged(value);
            }}
            disabled={disabled}
          />
        </div>
      </div>

      <SelectUserWarningModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleConfirmed={handleConfirmed}
      />

      <SelectModuleAdminModal
        showModal={showSelectModuleAdminModal}
        handleCloseModal={() => setShowSelectModuleAdminModal(false)}
      />
    </React.Fragment>
  );
}
