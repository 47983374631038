import slrConstants from "./branding/slrConstants";

const clientHomePage = {
  homePageMenu: {
    text: slrConstants.black,
    activeText: slrConstants.darkBlue,
  },
};

const landingPage = {
  placeholderBox: {
    background: slrConstants.grey,
    text: slrConstants.black,
  },
};
const buttons = {
  disabledButton: {
    primaryColor: slrConstants.grey,
    secondaryColor: slrConstants.white,
  },
  orangeButton: {
    primaryColor: slrConstants.orange,
    secondaryColor: slrConstants.white,
  },
  darkBlueButton: {
    primaryColor: slrConstants.darkBlue,
    secondaryColor: slrConstants.white,
  },
  blueButton: {
    primaryColor: slrConstants.blue,
    secondaryColor: slrConstants.white,
  },
  mediumBlueButton: {
    primaryColor: slrConstants.mediumBlue,
    secondaryColor: slrConstants.white,
  },
  redButton: {
    primaryColor: slrConstants.red,
    secondaryColor: slrConstants.white,
  },
  greenButton: {
    primaryColor: slrConstants.green1,
    secondaryColor: slrConstants.white,
  },
  greyButton: {
    primaryColor: slrConstants.charcoal,
    secondaryColor: slrConstants.white,
  },
};
const inputs = {
  styledInput: {
    placeholderText: slrConstants.grey,
    limitPrimaryText: slrConstants.charcoal,
    limitSecondaryText: slrConstants.red,
    errorText: slrConstants.red,
    successText: slrConstants.green1,
  },
  greyInput: {
    primaryColor: slrConstants.charcoal,
    secondaryColor: slrConstants.white,
  },
  whiteInput: {
    primaryColor: slrConstants.white,
    secondaryColor: slrConstants.charcoal,
  },
  lightGreyInput: {
    primaryColor: slrConstants.grey,
    secondaryColor: slrConstants.white,
    tertiaryColor: slrConstants.charcoal,
  },
  dropdownInput: {
    background: slrConstants.white,
    disabledBackground: "#e9ecef",
    disabledText: slrConstants.charcoal,
    text: slrConstants.charcoal,
    borderColor: slrConstants.grey,
    borderInvalidColor: slrConstants.red,
  },
};
const auth = {
  blueLink: {
    primaryColor: slrConstants.blue,
    secondaryColor: slrConstants.charcoal,
  },
  darkBlueLink: {
    primaryColor: slrConstants.blue,
    secondaryColor: slrConstants.darkBlue,
  }
};
const userManagement = {
  userManagement: {
    title: slrConstants.darkBlue,
    text: slrConstants.black,
    approvalTableText: slrConstants.charcoal,
    approvalIconPrimaryColor: slrConstants.grey,
    approvalIconSecondaryColor: slrConstants.black,
    userIconPrimaryColor: slrConstants.blue20,
    userIconSecondaryColor: slrConstants.darkBlue,
    tabText: slrConstants.grey,
    tabActiveText: slrConstants.charcoal,
    tabActiveBorder: slrConstants.darkBlue,
    commentModalTitleText: slrConstants.charcoal,
    commentModalBodyText: slrConstants.grey,
    rejectedModalText: slrConstants.black,
    rowPrimaryColor: slrConstants.lightGrey,
  },
  addCompanyButton: {
    primaryColor: slrConstants.blue,
  },
};

const clientManagement = {
  clientManagement: {
    primaryText: slrConstants.grey1,
    secondaryText: slrConstants.grey2,
    thirdText: slrConstants.white,
    fourthText: slrConstants.red,

    primaryBorder: slrConstants.lightGrey,
    secondaryBorder: slrConstants.grey1,
    thirdBorder: slrConstants.grey3,

    primaryBgColor: slrConstants.lightGrey,
    secondaryBgColor: slrConstants.grey3,
    thirdBgColor: slrConstants.red,
    fourthBgColor: slrConstants.green1,
    fifthBgColor: slrConstants.white,
  },
};
const documentManagement = {
  uploadFiles: {
    background: slrConstants.white,
    iconColor: slrConstants.grey,
    iconColorActive: slrConstants.green1,
    removeFileButtonColor: slrConstants.red,
    fileNameColor: slrConstants.darkBlue,
    errorColor: slrConstants.red,
  },
  sideBarMenuItem: {
    primaryColor: slrConstants.black,
    secondaryColor: slrConstants.darkBlue,
    scrollTrack: slrConstants.lightGrey2,
    scrollBar: slrConstants.grey,
  },
  documentTable: {
    primaryColor: slrConstants.black,
    secondaryColor: slrConstants.white,
    stripedColor: slrConstants.black,
    stripedBgColor: slrConstants.lightGrey,
  },
};

export const slr = {
  ...clientHomePage,

  ...landingPage,

  layout: {
    background: slrConstants.white,
    text: slrConstants.charcoal,
    headlines: slrConstants.darkBlue,
    redHighlightedText: slrConstants.red,
  },
  tooltip: {
    text: slrConstants.black,
    background: slrConstants.white,
  },
  slrPageHeader: {
    background: slrConstants.darkBlue,
    link: slrConstants.white,
    activeLink: slrConstants.yellowGreen,
    activeLinkBorderColor: slrConstants.yellowGreen,
    dropdownItemText: slrConstants.darkBlue,
    dropdownSelectedItemText: slrConstants.teal,
    fontSize: "16px",
    userGreetingText: slrConstants.white,
    userIconPrimaryColor: slrConstants.grey,
    userIconSecondaryColor: slrConstants.black,
    allWhiteLogo: slrConstants.logoAllWhite,

    primaryTextColor: slrConstants.black,
  },
  pageFooter: {
    background: slrConstants.darkBlue,
  },
  spinnerColor: {
    background: slrConstants.blue,
    secondaryBgColor: slrConstants.darkBlue,
  },

  fontFamily: {
    headlines: slrConstants.fontFamilyHeadlines,
    body: slrConstants.fontFamilyBody,
  },

  checkbox: {
    color: slrConstants.darkBlue,
  },

  taskCount: {
    color: slrConstants.white,
    background: slrConstants.red,
  },

  ...buttons,

  ...inputs,

  ...auth,

  ...documentManagement,

  ...userManagement,

  ...clientManagement,

  pagination: {
    primaryColor: slrConstants.black,
    primaryBackgroundColor: slrConstants.white,
    primaryBorderColor: slrConstants.lightGrey,
    secondaryColor: slrConstants.darkBlue,
    secondaryBackgroundColor: slrConstants.lightGrey2,
    secondaryBorderColor: slrConstants.darkBlue,
    thirdColor: slrConstants.grey,
    disableBackgroundColor: slrConstants.mediumGrey,
    disableColor: slrConstants.lightGrey2,
    hoverColor: slrConstants.darkBlue,
    hoverTextColor: slrConstants.white,
  },

  breadcrumb: {
    link: slrConstants.grey3,
    linkHover: slrConstants.grey1,
    textActive: slrConstants.grey1,
    separator: slrConstants.grey1,
  },
};
