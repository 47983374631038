import { Module } from "../../../models/module";
import { Row, Col, Form } from "react-bootstrap";
import { HeaderText } from "../common/styles";
import { getAccessibleModuleFeatures } from "../../../services/module.services";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface Props {
  modules?: Module[];
}

export default function BusinessModules({ modules }: Props) {
  const modelName = "features";
  const [accessibleModules, setAccessibleModules] = useState<Module[]>([]);
  const [selectedModules, setSelectedModules] = useState<number[]>([]);

  const { setValue } = useFormContext();
  const businessModuleFeatures = useWatch({ name: modelName }) as number[];

  useEffect(() => {
    getAccessibleModuleFeatures()
      .then((amf) => {
        setAccessibleModules(amf);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (modules) {
      modules.forEach((module) => {
        if (
          module.features.some((f) => businessModuleFeatures.includes(f.id))
        ) {
          if (!selectedModules.includes(module.id)) {
            setSelectedModules((prev) => [...prev, module.id]);
          }
        }
      });
    }
  }, [modules, businessModuleFeatures]);

  const onModuleChanged = (id: number) => {
    if (selectedModules.includes(id)) {
      const deselectedModule = modules?.find((m) => m.id === id)!;
      const remains = selectedModules.filter((moduleId) => moduleId !== id);
      setSelectedModules(remains);
      const _businessModuleFeatures = businessModuleFeatures.filter(
        (n) => !deselectedModule.features.some((f) => f.id === n)
      );
      setValue(modelName, _businessModuleFeatures);
    } else {
      setSelectedModules((prev) => [...prev, id]);
    }
  };

  const onFeatureChanged = (id: number) => {
    if (businessModuleFeatures.includes(id)) {
      const remains = businessModuleFeatures.filter(
        (featureId) => featureId !== id
      );
      setValue(modelName, remains, { shouldDirty: true });
    } else {
      setValue(modelName, [...businessModuleFeatures, id], {
        shouldDirty: true,
      });
    }
  };

  return (
    <Row className="mt-4">
      <Col xs="9">
        <HeaderText>Business Modules</HeaderText>
      </Col>
      <div className="ms-4 pt-3">
        {modules &&
          modules.map((module) => (
            <Row key={`module-${module.id}`}>
              <Col xs="12">
                <Form.Check
                  type="checkbox"
                  id={`module-${module.name}`}
                  label={module.displayName}
                  disabled={!accessibleModules.some((m) => m.id === module.id)}
                  checked={selectedModules.includes(module.id)}
                  onChange={(_) => onModuleChanged(module.id)}
                />
                {selectedModules.includes(module.id) && (
                  <Row className="my-2">
                    <Col xs="12">
                      <HeaderText>Module Features</HeaderText>
                    </Col>
                    <Row className="ms-5">
                      {module.features.map((feature) => (
                        <Col xs="2" key={feature.id}>
                          <Form.Check
                            type="checkbox"
                            label={feature.displayName}
                            id={`module-${module.id}-feature-${feature.id}`}
                            onChange={() => onFeatureChanged(feature.id)}
                            checked={businessModuleFeatures.includes(
                              feature.id
                            )}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>
          ))}
      </div>
    </Row>
  );
}
