export function checkDayMonth(value: string) {
  if (!/(0[1-9]|[12]\d|3[01])\/(1[0-2]|0[1-9])/.test(value)) {
    return false;
  }
  const [day, month] = value.split("/");

  const dayNumber = +day;
  const monthNumber = +month;

  if (
    (monthNumber === 4 ||
      monthNumber === 6 ||
      monthNumber === 9 ||
      monthNumber === 11) &&
    dayNumber > 30
  ) {
    return false;
  }

  if (monthNumber === 2 && dayNumber > 29) {
    return false;
  }

  return true;
}
