import { Outlet } from "react-router-dom";
import WithInterceptor from "../pages/WithInterceptor";
import SlrPortalLayout from "../components/SlrPortalLayout";
import LandingPage from "../pages/LandingPage";
import Callback from "../pages/Callback";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { ClientManagementRoutes } from "./ClientManagementRoutes";
import { UserManagementRoutes } from "./UserManagementRoutes";
import DocumentManagement from "../pages/DocumentManagement";
import TaskManagement from "../pages/TaskManagement";
import CreatePassword from "../pages/CreatePassword";
import PageNotFound from "../components/error-handler/PageNotFound";
import ProtectedRoute from "../components/ProtectedRoute";
import { ModuleFeature } from "../models/module-feature";
import MfaSetup from "../pages/MfaSetup";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "../components/error-handler/ErrorHandler";
import ContactRequest from "../pages/ContactRequest";

export const routes = [
  {
    path: "*",
    element: (
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <WithInterceptor>
          <Outlet />
        </WithInterceptor>
      </ErrorBoundary>
    ),
    children: [
      {
        path: "",
        element: <SlrPortalLayout />,
        children: [
          {
            path: "",
            element: <LandingPage />,
          },

          ...AuthenticationRoutes,
          ...ClientManagementRoutes,
          ...UserManagementRoutes,

          {
            path: "tasks",
            element: (
              <ProtectedRoute feature={ModuleFeature.TaskManagement}>
                <TaskManagement />
              </ProtectedRoute>
            ),
          },
          {
            path: "documents",
            element: (
              <ProtectedRoute feature={ModuleFeature.DocumentManagement}>
                <DocumentManagement />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "callback",
        element: <Callback />,
      }, 
      {
        path: "create-password",
        element: <CreatePassword />,
      },
      {
        path: "mfa-setup",
        element: <MfaSetup />,
      },
      {
        path: "contact-request",
        element: <ContactRequest />
      },
      {
        path: "404",
        element: <PageNotFound />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
];
