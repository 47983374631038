import styled from "styled-components";

type Props = {
  $isPaddedRight: boolean;
  $isInvalid: boolean;
  $isDisabled: boolean;
};

export const DropdownValue = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
`;

export const Toggle = styled.div.attrs(($isInvalid) => ({
  className: $isInvalid ? "is-invalid" : "",
}))<Props>`
  width: 100%;

  user-select: none;
  background-color: ${({ $isDisabled, theme }) =>
    $isDisabled
      ? theme.dropdownInput.disabledBackground
      : theme.dropdownInput.background};
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  color: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.dropdownInput.disabledText : theme.dropdownInput.text};
  border-color: ${({ $isInvalid, theme }) =>
    $isInvalid
      ? theme.dropdownInput.borderInvalidColor
      : theme.dropdownInput.borderColor};
  border-width: 2px;
  border-style: solid;
  border-radius: 0.375rem;
  transition: border-color 0.15s;
  font-family: ${({ theme }) => theme.fontFamily.body};
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;

  ::after {
    content: "";
    opacity: 0;
    display: inline-block;
    margin: 0;
    border: none;
  }

  ${({ $isPaddedRight }) =>
    $isPaddedRight &&
    `
      padding-right: 3.5rem !important;
    `}
  > div {
    position: absolute;
  }
  > ${DropdownValue} {
    ${({ $isPaddedRight }) =>
      $isPaddedRight &&
      `
      padding-right: 3.5rem !important;
    `}
  }
`;
