import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
interface Props {
  name: string;
  children: JSX.Element;
}

function ValidationLayout({ name, children }: Props) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className="d-inline-block flex-grow-1" style={{ flexBasis: "80%" }}>
      {children}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return message !== undefined ? (
            <div className="text-danger fst-italic text-left">{message}</div>
          ) : (
            <></>
          );
        }}
      />
    </div>
  );
}

export default ValidationLayout;
