import { handleResponse } from "../helpers/handle-response";
import { Country } from "../models/country";
import { Currency } from "../models/currency";

export function getAllCurrencies(): Promise<Currency[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/Currencies`,
    requestOptions
  ).then(handleResponse);
}

export function getAllCountries(): Promise<Country[]> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/Countries`,
    requestOptions
  ).then(handleResponse);
}
