export interface TaskFilter {
  module: string;
  name: string | null;
  state: string | null;
  search: string | null;
  itemsPerPage: number;
  currentPage: number;
  orderBy: TaskOrderByColumn;
  sortDesc: boolean;
}

export enum TaskOrderByColumn {
  UpdatedDate = "UpdatedDate",
  Name = "Name",
  Company = "Company",
  Feature = "Feature",
  SubFeature = "SubFeature",
}
