import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNotification } from "../../../../../components/NotificationProvider";
import { TaskOrderByColumn } from "../../../../../models/task-filter";
import { useTaskManagement } from "../../../contexts/TaskManagementProvider";
import {
  TableHeaderCell,
  SortableWrapper,
  SortChevron,
  CustomTable,
  CenterWrapper,
  ReviewButton,
} from "./styles";
import { getTaskUrl } from "../../../../../services/task.service";
import { getSection, getSubSection } from "../../../../../models/task";

function TaskTable() {
  const { showError } = useNotification();
  const { isLoading, tasks, filter, setFilter } = useTaskManagement();

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(value: string) {
    const date = new Date(value);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const handleSort = (column: TaskOrderByColumn) => {
    if (filter.orderBy !== column) {
      setFilter((currentFilter) => {
        return {
          ...currentFilter,
          orderBy: column,
          sortDesc: column === TaskOrderByColumn.Name ? true : false,
        };
      });
    } else {
      setFilter((currentFilter) => {
        return { ...currentFilter, sortDesc: !currentFilter.sortDesc };
      });
    }
  };

  return (
    <div className="mt-4">
      <CustomTable>
        <thead>
          <tr>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(TaskOrderByColumn.Feature)}
              >
                Section
                <SortChevron
                  $show={filter.orderBy === TaskOrderByColumn.Feature}
                  $desc={!filter.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(TaskOrderByColumn.SubFeature)}
              >
                Sub-Section
                <SortChevron
                  $show={filter.orderBy === TaskOrderByColumn.SubFeature}
                  $desc={filter.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(TaskOrderByColumn.Name)}
              >
                Name
                <SortChevron
                  $show={filter.orderBy === TaskOrderByColumn.Name}
                  $desc={!filter.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(TaskOrderByColumn.Company)}
              >
                Company
                <SortChevron
                  $show={filter.orderBy === TaskOrderByColumn.Company}
                  $desc={filter.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">
                    Date of submission for review
                  </Tooltip>
                }
              >
                <span>Date</span>
              </OverlayTrigger>
            </TableHeaderCell>
            <TableHeaderCell>
              <SortableWrapper
                onClick={() => handleSort(TaskOrderByColumn.UpdatedDate)}
              >
                Status Days
                <SortChevron
                  $show={filter.orderBy === TaskOrderByColumn.UpdatedDate}
                  $desc={filter.sortDesc}
                />
              </SortableWrapper>
            </TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            {tasks.map((task) => (
              <tr key={task.id}>
                <td className="text-center">{getSection(task)}</td>
                <td className="text-center">{getSubSection(task)}</td>
                <td className="text-center">{task.name}</td>
                <td className="text-center">{task.company}</td>
                <td className="text-center">
                  {task.taskType || "missing task.taskType"}
                </td>
                <td className="text-center">{formatDate(task.updatedOn)}</td>
                <td className="text-center">{task.statusDays}</td>
                <td className="text-center">
                  <ReviewButton
                    onClick={() =>
                      getTaskUrl(task.id)
                        .then((res) => {
                          window.location.href =
                            res.landingPage +
                            encodeURIComponent(
                              `?returnUrl=${window.location.href}`
                            );
                        })
                        .catch(() =>
                          showError(
                            undefined,
                            "An error occurred when get task link"
                          )
                        )
                    }
                  >
                    Review
                  </ReviewButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </CustomTable>
      {isLoading && (
        <CenterWrapper>
          <Spinner animation="border" />
          <span>Loading ...</span>
        </CenterWrapper>
      )}
      {!isLoading && tasks.length === 0 && (
        <CenterWrapper>No data found.</CenterWrapper>
      )}
    </div>
  );
}

export default TaskTable;
