import styled, { css } from "styled-components";
import { FaChevronUp } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { GrRotateLeft } from "react-icons/gr";

interface SortChevronProps {
  $desc: boolean;
  $show: boolean;
}

export const TableHeaderCell = styled.th`
  font-weight: bold !important;
  text-align: center;
`;
export const TableFilterHeaderCell = styled.th`
  font-weight: bold !important;
  text-align: center;

  height: inherit;
`;

export const SortableWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const SortChevron = styled(FaChevronUp)<SortChevronProps>`
  position: absolute;
  font-size: 0.75rem;
  top: 0.125rem;
  right: 0rem;
  transition-duration: 0.2s;
  transition-property: transform;
  display: ${({ $show }) => ($show ? "inline-block" : "none")};
  ${({ $desc }) =>
    $desc &&
    css`
      transform: rotate(180deg);
    `};
`;

export const CustomTable = styled(Table).attrs((_) => ({
  bordered: true,
  hover: true,
}))`
  color: ${({ theme }) => theme.documentTable.primaryColor};
  border-color: ${({ theme }) => theme.documentTable.secondaryColor};
  td {
    border-color: ${({ theme }) => theme.documentTable.secondaryColor};
  }
  & > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: ${({ theme }) => theme.documentTable.stripedBgColor};
    color: ${({ theme }) => theme.documentTable.stripedColor};
  }
`;

export const DownloadLink = styled.a`
  cursor: pointer;
`;

export const DocumentCenterWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${({ theme }) => theme.documentTable.primaryColor};
  font-size: 1.5rem;
`;
export const DeleteIcon = styled(BiTrash)`
  width: 1.5rem;
  height: 1.5rem;

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const RestoreIcon = styled(GrRotateLeft)`
  width: 1.5rem;
  height: 1.5rem;

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
