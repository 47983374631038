import styled, { css } from "styled-components";
import { HiChevronRight } from "react-icons/hi";

export const SideBarLayout = styled.div.attrs((_) => ({
  className: "hstack d-flex",
}))`
  margin-top: 2rem;
  height: calc(100% - 3rem);
  align-items: flex-start;
`;

export const VerticalDivider = styled.div.attrs((_) => ({
  className: "vr",
}))`
  width: 2px;
`;

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollTrack};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.sideBarMenuItem.scrollBar};
    border-radius: 4px;
  }
`;

export const MenuItem = styled.a<{ selected?: boolean; level: number }>`
  display: block;
  position: relative;
  padding: 0rem 0.5rem;
  padding-left: ${({ selected, level }) =>
    selected
      ? `${1.375 + level * 0.75}rem`
      : `${1.25 + level * 0.75 + 0.25}rem`};
  margin: 0.5rem 0.25rem 0.5rem 1rem;
  text-decoration: none;
  color: ${({ selected, theme }) =>
    selected
      ? theme.sideBarMenuItem.secondaryColor
      : theme.sideBarMenuItem.primaryColor};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 1.125rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.sideBarMenuItem.secondaryColor};
  }

  border-left: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.sideBarMenuItem.secondaryColor}` : "none"};
`;

export const ExpandIndicator = styled(HiChevronRight)<{
  selected?: boolean;
  $subLevel?: boolean;
}>`
  position: absolute;
  left: ${({ $subLevel }) => ($subLevel ? "1.25rem" : "0.5rem;")};
  top: 0.25rem;
  transition-duration: 0.2s;
  transition-property: transform;
  ${({ selected }) =>
    selected &&
    css`
      transform: rotate(90deg);
    `};
`;

export const MenuWrapper = styled.div<{ $hide?: boolean }>`
  display: ${({ $hide }) => ($hide ? "none" : "block")};
`;
