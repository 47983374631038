import styled from "styled-components";

export const CallbackLayout = styled.div.attrs((_) => ({
  className: "pt-5",
}))`
  form,
  .form-input {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  text-align: center;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.styledInput.errorText};
  font-weight: 700;
  font-style: italic;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
