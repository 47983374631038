import _ from "lodash";
import { useState, useEffect, useMemo, useCallback } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  DarkBlueOutlinedButton,
  DarkBlueSolidButton,
} from "../../../components/common";
import { UserRoleType } from "../../../enums/user-role";
import { ClientUser } from "../../../models/client-user";
import { UserActivation } from "../../../models/user-activation";
import { getClientUsers } from "../../ClientManagement/services/client.services";
import { HeaderText } from "../common/styles";
import {
  AccountRow,
  RowTitleText,
  UserDetailCol,
  StyledUserIcon,
  BodyWarningModal,
} from "./styles";
import React from "react";
import { getAccessibleModuleFeatures } from "../../../services/module.services";
import { Module } from "../../../models/module";
import { Feature } from "../../../models/feature";

type Props = {
  isUploadedFile: boolean;
  clientFeatures: number[] | undefined;
};

function UserList({ isUploadedFile, clientFeatures }: Props) {
  const modelName = "userActivations";
  const navigate = useNavigate();
  const {
    control,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { clientId } = useParams();
  const [managedModules, setManagedModules] = useState<Module[] | undefined>(
    undefined
  );

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [users, setUsers] = useState<ClientUser[]>([]);
  const userActivations = useWatch({
    control,
    name: modelName,
  }) as UserActivation[];

  useEffect(() => {
    clientId &&
      getClientUsers(clientId)
        .then((users: ClientUser[]) => setUsers(users))
        .catch((e) => console.log(e));
  }, [clientId]);

  useEffect(() => {
    getAccessibleModuleFeatures()
      .then((modules) => setManagedModules(modules))
      .catch((e) => console.log(e));
  }, []);

  const onUserActiveChange = (user: ClientUser, status: boolean) => {
    const userRemains = users.filter((u) => u.id !== user.id);
    setUsers([...userRemains, { ...user, active: status }]);
    if (!userActivations) {
      setValue(modelName, [{ userId: user.id, active: status }]);
    } else {
      const userActivationRemains = userActivations.filter(
        (u) => u.userId !== user.id
      );
      setValue(modelName, [
        ...userActivationRemains,
        { userId: user.id, active: status },
      ]);
    }
  };

  const onClickAddUser = useCallback(() => {
    if (isDirty || isUploadedFile) {
      setShowWarningModal(true);
    } else {
      navigate("/users/new", { state: { clientId } });
    }
  }, [isDirty, isUploadedFile, navigate]);

  const closeHandler = useCallback(() => {
    setShowWarningModal(false);
  }, []);

  const sortedUsers = useMemo(() => {
    return _.sortBy(users, "id");
  }, [users]);

  const isSelectedManagedModule = useMemo(() => {
    if (!managedModules) return false;
    return managedModules?.some((module) =>
      module.features.some(
        (f) => clientFeatures && clientFeatures.includes(f.id)
      )
    );
  }, [clientFeatures, managedModules]);

  return (
    <React.Fragment>
      <div>
        <Row className="mt-3">
          <Col xs="9" className="d-flex">
            <div className="pt-2">
              <HeaderText>Users</HeaderText>
            </div>
            <DarkBlueOutlinedButton
              className="ms-3"
              disabled={!isSelectedManagedModule}
              onClick={() => isSelectedManagedModule && onClickAddUser()}
            >
              Add a new user
            </DarkBlueOutlinedButton>
          </Col>
          <Col xs="9">
            {sortedUsers.map((user) => {
              return (
                <AccountRow key={user.id}>
                  <UserDetailCol>
                    <StyledUserIcon />
                  </UserDetailCol>
                  <UserDetailCol $width="15%">
                    <span className="text-break">{user.contact?.name}</span>
                  </UserDetailCol>
                  <UserDetailCol $width="25%">
                    <span className="text-break">{user.contact?.email}</span>
                  </UserDetailCol>
                  <UserDetailCol className="text-center" $width="15%">
                    <RowTitleText>Permission</RowTitleText>
                    <div>{UserRoleType[user.userRole]}</div>
                  </UserDetailCol>
                  <UserDetailCol $width="23%">
                    <div className="text-end">
                      <span className="me-2">Inactive</span>
                      <Form.Check
                        className="d-inline-block"
                        type="switch"
                        id="custom-switch"
                        checked={user.active}
                        onChange={(e) =>
                          onUserActiveChange(user, e.target.checked)
                        }
                      />
                      <span className="ms-2 pe-3">Active</span>
                    </div>
                  </UserDetailCol>
                  <UserDetailCol $width="15%">
                    <DarkBlueOutlinedButton
                      className="ms-3"
                      onClick={() => navigate(`/users/${user.id}`)}
                    >
                      Edit
                    </DarkBlueOutlinedButton>
                  </UserDetailCol>
                </AccountRow>
              );
            })}
          </Col>
        </Row>
      </div>

      <Modal show={showWarningModal} onHide={closeHandler} centered>
        <BodyWarningModal>
          <div>Save all changes before adding a new user.</div>
          <DarkBlueSolidButton onClick={closeHandler}>OK</DarkBlueSolidButton>
        </BodyWarningModal>
      </Modal>
    </React.Fragment>
  );
}

export default UserList;
