import { Form } from "react-bootstrap";
import styled from "styled-components";

interface RadioButtonProps {
  fullWidth: boolean;
}

export const RadioButton = styled(Form.Check).attrs((_) => ({
  type: "radio",
}))<RadioButtonProps>`
  flex: 0 0 1;
  .form-check-input[disabled] ~ label.form-check-label {
    color: ${({ theme }) => theme.layout.text};
    opacity: 0.7;
  }
  .form-check-input[disabled] {
    background-color: ${({ theme }) => theme.lightGreyInput.primaryColor};
  }
`;

export const RadioButtonGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
