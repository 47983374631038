import { Container, Nav, Navbar } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";
import { NavDropDownAuto } from "../NavDropDownAuto";

export const NavbarLayout = styled(Navbar).attrs((_) => ({
  expand: "lg",
}))`
  background: ${({ theme }) => theme.slrPageHeader.background};
`;

export const NavbarContainerLayout = styled(Container).attrs((_) => ({}))`
  display: flex;
  justify-content: space-between;
  padding: 0.95rem 3rem;
`;
export const LeftSideContent = styled.div`
  flex-basis: 15%;
  height: 80px;
  position: relative;
`;
export const Logo = styled.img.attrs(({ theme }) => ({
  src: theme.slrPageHeader.allWhiteLogo,
}))`
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
`;
export const RightSideContent = styled.div`
  flex-basis: 15%;
  display: flex;
  justify-content: end;
`;
export const UserWrapper = styled.div``;
export const UserImageWrapper = styled.div.attrs((_) => ({
  className:
    "d-flex align-content-center justify-content-center ms-auto me-auto flex-wrap mb-1",
}))`
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.slrPageHeader.userIconPrimaryColor};
`;
export const UserImage = styled(FaUser).attrs((_) => ({
  size: 24,
}))`
  color: ${({ theme }) => theme.slrPageHeader.userIconSecondaryColor};
`;
export const UserText = styled.p.attrs((_) => ({
  className: "text-center ms-auto me-auto",
}))`
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.slrPageHeader.userGreetingText};
  margin-bottom: 0;
`;
export const NavItemWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.headlines};

  & .nav-link {
    color: ${({ theme }) => theme.slrPageHeader.link} !important;
    font-weight: bold;
  }
  & .navbar-nav {
    gap: 40px;
  }
  & .dropdown-menu {
    transform: none !important;
  }
`;
export const NavLink = styled(Nav.Link)`
  & {
    font-size: 14px;
    font-weight: 600;
  }
  &:hover {
    color: ${({ theme }) => theme.slrPageHeader.activeLink} !important;
  }
  &.active.nav-link {
    color: ${({ theme }) => theme.slrPageHeader.activeLink} !important;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) =>
      theme.slrPageHeader.activeLinkBorderColor} !important;
  }
`;

export const NavDropDown = styled(NavDropDownAuto)`
  & {
    font-size: ${({ theme }) => theme.slrPageHeader.fontSize};
  }
  & > .dropdown-menu {
    &.show {
      display: block;
    }
    display: none;
    border-radius: 0;
  }
  &:hover .nav-link {
    color: ${({ theme }) => theme.slrPageHeader.activeLink} !important;
  }
  & .dropdown-menu {
    margin-top: 0;
  }
  & .dropdown-item {
    font-size: ${({ theme }) => theme.slrPageHeader.fontSize};
    color: ${({ theme }) => theme.slrPageHeader.primaryTextColor};
    font-weight: 600;
    padding: 0.65rem 1.5rem;
  }
  & .dropdown-item {
    font-size: ${({ theme }) => theme.slrPageHeader.fontSize};
    color: ${({ theme }) => theme.slrPageHeader.primaryTextColor};
    font-weight: 600;
  }
  .selected-item.dropdown-item {
    color: ${({ theme }) => theme.slrPageHeader.dropdownSelectedItemText};
  }
  &.nav-profile .dropdown-toggle::after {
    display: none;
  }
  & .dropdown-item:hover {
    opacity: 0.7;
  }
  &.nav-profile .dropdown-menu {
    right: 0;
    left: auto;
    transform: translate(0, 0) !important;
  }
  @keyframes dropdownAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const AccountInfoWrapper = styled.div`
  margin: 0 8px;
  border: 2px solid;
  border-color: ${({ theme }) => theme.slrPageHeader.userIconPrimaryColor};
  color: ${({ theme }) => theme.slrPageHeader.primaryTextColor};
  min-width: 12.5rem;
`;

export const NameWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme }) => theme.slrPageHeader.userIconPrimaryColor};

  p {
    font-weight: normal;
    margin: 0;
  }

  h4 {
    color: ${({ theme }) => theme.slrPageHeader.dropdownItemText} !important;
    font-weight: bold;
    margin: 0;
  }
`;

export const ActionLayout = styled.div`
  display: flex;
  gap: 1rem;
  padding: 8px;
  min-width: 300px;
`;

export const IconActionWrapper = styled.div`
  flex-basis: 0%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionWrapper = styled.div`
  flex-basis: 0%;
  flex-grow: 9;
`;

export const AccountIcon = styled.img`
  width: 1.5rem;
`;
export const HelpIcon = styled.img`
  width: 1rem;
`;
export const LogOutLayout = styled(ActionLayout)`
  justify-content: center;
`;
