import { ColTitle, ColWrapper } from "./styles";

type Props = {
  label: string;
  value: string | number;
};

function AccountInfoCol({ label, value }: Props) {
  return (
    <td>
      <ColWrapper>
        <ColTitle>{label}</ColTitle>
        <div>{!value ? "-" : value}</div>
      </ColWrapper>
    </td>
  );
}

export default AccountInfoCol;
