import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sessionService from "../../services/session.service";
import {
  NavbarLayout,
  NavbarContainerLayout,
  Logo,
  UserWrapper,
  UserImageWrapper,
  UserImage,
  UserText,
  NavDropDown,
  NavItemWrapper,
  NavLink,
  LeftSideContent,
  RightSideContent,
  AccountInfoWrapper,
  NameWrapper,
  ActionLayout,
  IconActionWrapper,
  ActionWrapper,
  AccountIcon,
  HelpIcon,
  LogOutLayout,
} from "./styles";
import MasqueradeDropdown from "../MasqueradeDropdown";
import { OrangeSolidButton } from "../common";
import { useEffect, useState } from "react";
import { getClientItems } from "../../pages/ClientManagement/services/client.services";
import _ from "lodash";
import { SlrHeaderItem, SlrHeaderType } from "../../constants/slrHeaderItems";
import React from "react";
import { IdName } from "../../models/id-name";

type Props = {
  headerItems: Array<SlrHeaderItem>;
};

function SlrHeader({ headerItems }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = sessionService.getCurrentUser();
  const [clients, setClients] = useState<{
    [key: string]: string;
  }>({});

  const convertCheckBoxOptions = (
    values: IdName[]
  ): { [key: number]: string } => {
    let options = {};
    values?.forEach((value) => {
      options = { ...options, [value.id]: value.name };
    });
    return options;
  };

  const currentUser = sessionService.getCurrentUser();
  useEffect(() => {
    if (currentUser && currentUser.role !== "User" && _.isEmpty(clients)) {
      getClientItems()
        .then((response) => {
          setClients(convertCheckBoxOptions(response));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentUser, clients]);

  return (
    <NavbarLayout>
      <NavbarContainerLayout>
        <LeftSideContent>
          <Logo></Logo>
        </LeftSideContent>

        {currentUser && (
          <>
            <NavItemWrapper>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  {headerItems.map((headerItem) => {
                    if (headerItem.type === SlrHeaderType.Link)
                      return (
                        <NavLink
                          key={headerItem.title}
                          forwardedAs={Link}
                          to={headerItem.path}
                          className={
                            location.pathname === headerItem.path
                              ? "active"
                              : ""
                          }
                        >
                          {headerItem.title}
                        </NavLink>
                      );
                    else if (headerItem.type === SlrHeaderType.Dropdown)
                      return (
                        <NavDropDown
                          key={headerItem.title}
                          title={headerItem.title}
                        >
                          {headerItem.items!.map((subItem) => (
                            <NavDropdown.Item
                              forwardedAs={Link}
                              to={subItem.path}
                              key={subItem.title}
                            >
                              {subItem.title}
                            </NavDropdown.Item>
                          ))}
                        </NavDropDown>
                      );
                    else {
                      return <React.Fragment />;
                    }
                  })}
                </Nav>
              </Navbar.Collapse>
            </NavItemWrapper>
            <RightSideContent>
              {user && (
                <NavDropDown
                  toggle={(onClickCallback: any) => (
                    <UserWrapper onClick={onClickCallback}>
                      <UserImageWrapper>
                        <UserImage />
                      </UserImageWrapper>
                      <UserText>Hi, {currentUser.displayName}</UserText>
                    </UserWrapper>
                  )}
                  className="nav-item nav-profile"
                  id="basic-nav-dropdown"
                >
                  <AccountInfoWrapper>
                    <NameWrapper>
                      <h4>{user.displayName}</h4>
                      <p>{user.email}</p>
                    </NameWrapper>
                    {currentUser.allowedToMasquerade && (
                      <ActionLayout>
                        <IconActionWrapper>
                          <AccountIcon
                            src="/images/masquerade_icon.svg"
                            alt="Masquerade Icon"
                          />
                        </IconActionWrapper>
                        <ActionWrapper>
                          <MasqueradeDropdown
                            options={clients}
                            placeholder={"Masquerade"}
                          />
                        </ActionWrapper>
                      </ActionLayout>
                    )}

                    <ActionLayout>
                      <IconActionWrapper>
                        <HelpIcon
                          src="/images/help_support.svg"
                          alt="Masquerade Icon"
                        />
                      </IconActionWrapper>
                      <ActionWrapper>Help & Support</ActionWrapper>
                    </ActionLayout>
                    <LogOutLayout>
                      <OrangeSolidButton onClick={() => navigate("/logout")}>
                        Log out
                      </OrangeSolidButton>
                    </LogOutLayout>
                  </AccountInfoWrapper>
                </NavDropDown>
              )}
            </RightSideContent>
          </>
        )}
      </NavbarContainerLayout>
    </NavbarLayout>
  );
}

export default SlrHeader;
