import { MdOutlineSearch, MdClose } from "react-icons/md";
import {
  HeaderLayout,
  TitleWrapper,
  NewBtnWrapper,
  SearchUserWrapper,
  SearchUserIcon,
  SearchUserInput,
  Title,
  AddNewButton,
  SearchUserCloseIcon,
} from "./styles";
import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router";

type Props = {
  searchTextChange: (searchText: string) => void;
};

function UserManagementHeader({ searchTextChange }: Props) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<string>("");

  function handleChange(event: any) {
    returnedFunction(event.target.value);
  }

  const returnedFunction = debounce(function (nextValue: string) {
    setSearchText(nextValue);
    searchTextChange(nextValue);
  }, 500);

  const clearSearchHandler = useCallback(() => {
    if (inputRef && inputRef.current?.value) {
      inputRef.current.value = "";
      setSearchText("");
      searchTextChange("");
    }
  }, [searchTextChange]);

  const onClickNewAccount = useCallback(() => {
    navigate("new");
  }, [navigate]);

  return (
    <HeaderLayout>
      <TitleWrapper>
        <Title>User Management</Title>
      </TitleWrapper>
      <NewBtnWrapper>
        <AddNewButton onClick={onClickNewAccount}>Add a new user</AddNewButton>
      </NewBtnWrapper>
      <SearchUserWrapper>
        <SearchUserIcon htmlFor="search-user-management">
          <MdOutlineSearch />
        </SearchUserIcon>
        <SearchUserInput
          ref={inputRef}
          maxLength={255}
          id="search-user-management"
          placeholder="Search user"
          onChange={handleChange}
        />

        {!!searchText && (
          <SearchUserCloseIcon onClick={clearSearchHandler}>
            <MdClose />
          </SearchUserCloseIcon>
        )}
      </SearchUserWrapper>
    </HeaderLayout>
  );
}

export default UserManagementHeader;
