import { Navigate, Outlet } from "react-router";
import sessionService from "../../services/session.service";
import { User, isAdmin } from "../../models/user";
import { ModuleFeature } from "../../models/module-feature";
import React, { useMemo } from "react";

type Props = {
  feature: ModuleFeature;
  isAdminFeature?: boolean;
  children?: React.ReactNode;
};

function ProtectedRoute({ feature, isAdminFeature, children }: Props) {
  const currentUser = sessionService.getCurrentUser() as User | undefined;

  const hasPermission = useMemo(
    () =>
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.some((x) => x === feature),
    [currentUser, feature]
  );

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (isAdminFeature && !isAdmin(currentUser)) {
    return <Navigate to="/404" />;
  }

  if (!isAdminFeature && !hasPermission) {
    return <Navigate to="/404" />;
  }

  if (children) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <Outlet />;
}

export default ProtectedRoute;
