import { Form } from "react-bootstrap";
import styled, { css } from "styled-components";

export const ToolBarLayout = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-center;
`;

export const FormSelect = styled(Form.Select)<{ width?: string }>`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 0.875rem;
  border: none;
  :focus {
    box-shadow: none;
  }
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `};
`;
