import { useEffect, useState } from "react";
import sessionService from "../../services/session.service";
import QRCode from "qrcode.react";
import { FormProvider, useForm } from "react-hook-form";
import {
  PositiveIntegerInputRHF,
  TextInputRHF,
} from "../../components/InputsWithRHF";
import { PrimaryButton, SpinnerLoading } from "../Users/common/styles";
import { useNotification } from "../../components/NotificationProvider";
import { useLocation, useNavigate } from "react-router";
import { ErrorMessage } from "@hookform/error-message";
import { MfaSetupLayout } from "./styles";

function MfaSetup() {
  const { state } = useLocation();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [uri, setUri] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showError } = useNotification();

  const methods = useForm<{ code: string }>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      code: "",
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (state?.hasOwnProperty("token")) {
      setToken(state.token);
    } else {
      showError(undefined, "An error occurred. Please try again.");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    token &&
      sessionService
        .getAuthenticatorUri(token)
        .then((res) => setUri(res.uri))
        .catch((err) => {
          if (err.status) {
            if (err.data.status === "MFAEnabled") {
              navigate("/");
            }
          }
          console.log(err);
        });
  }, [token]);

  const submitForm = handleSubmit((data) => {
    if (token) {
      setIsLoading(true);
      sessionService
        .verifyAuthenticatorToken(token, data.code)
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          setError("code", {
            type: "custom",
            message: "Invalid verification code. Please try again.",
          });
        })
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <MfaSetupLayout>
      <div className="d-flex align-items-center justify-content-center flex-column p-3 mt-3 w-50">
        <div>
          <div className="mb-3">
            <strong>Step 1: Download authenticator application</strong>
            <div>
              Download the Microsoft Authenticator from App Store on iOS or Play
              Store on Android or use any other authenticator app of your
              choice.
            </div>
          </div>
          <div className="mb-3">
            <strong>Step 2: Scan the QR Code</strong>
            <div>
              Open the “Microsoft Authenticator” app on your phone, select
              “Verified IDs”, select “Scan QR code”, or click on the QR code
              icon on the top right corner of the screen.
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center justify-content-center">
            <div>{uri && <QRCode value={uri} size={200} level={"L"} />}</div>
          </div>
          <div className="mb-3">
            <strong>
              Step 3: Enter the verification code from your Microsoft
              Authenticator app.
            </strong>
          </div>
          <div className="mb-3 d-flex align-items-center flex-column">
            <FormProvider {...methods}>
              <div className="w-100 text-start">
                <PositiveIntegerInputRHF
                  name="code"
                  placeholder="Enter the verification code from your Microsoft Authentication app"
                  rules={{ required: "Required." }}
                  withoutFormatter
                />
                <ErrorMessage
                  errors={errors}
                  name="code"
                  render={({ message }) => {
                    return message !== undefined ? (
                      <div className="text-danger fst-italic">{message}</div>
                    ) : (
                      <></>
                    );
                  }}
                />
              </div>
              <PrimaryButton
                onClick={submitForm}
                disabled={!isValid || isLoading}
              >
                {isLoading && <SpinnerLoading />}
                Verify
              </PrimaryButton>
            </FormProvider>
          </div>
        </div>
      </div>
    </MfaSetupLayout>
  );
}

export default MfaSetup;
