import { MdPerson } from "react-icons/md";
import ListingUser from "../../models/listing-user";
import AccountInfoCol from "../../../../components/AccountInfoCol";
import {
  ColWrapper,
  Name,
  EditClientBtn,
  StatusCol,
  UserManagementTable as Table,
  Email,
} from "./styles";
import { useNavigate } from "react-router";
import { useCallback, useMemo } from "react";

type Props = {
  users: Array<ListingUser>;
  isSearching: boolean;
};

function UserManagementTable({ users, isSearching }: Props) {
  const navigate = useNavigate();

  const editHandler = useCallback(
    (id: number) => {
      navigate(`/users/${id}`);
    },
    [navigate]
  );

  const renderNoContentText = useMemo(
    () =>
      isSearching
        ? "No data found."
        : "There are currently no users. Please add a new user.",
    [isSearching]
  );

  const renderUserFeatures = useCallback((features: Array<string>) => {
    if (!features || features.length === 0) return "-";
    return features.join(", ");
  }, []);

  return (
    <Table>
      <tbody>
        {users.length > 0 ? (
          users.map((user: ListingUser) => (
            <tr key={user.id}>
              <td>
                <MdPerson />
              </td>
              <td>
                <ColWrapper>
                  <Name>{user.name ?? "-"}</Name>
                  <Email>{user.email}</Email>
                </ColWrapper>
              </td>
              <AccountInfoCol label="Company Name" value={user.companyName} />
              <AccountInfoCol label="Permission" value={user.role} />
              <AccountInfoCol
                label="Business Modules"
                value={renderUserFeatures(user.businessModules)}
              />
              <td>
                <StatusCol $isActive={user.isActive}>
                  {user.isActive ? "Active" : "Inactive"}
                </StatusCol>
              </td>
              <td>
                <EditClientBtn onClick={() => editHandler(user.id)}>
                  Edit
                </EditClientBtn>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>{renderNoContentText}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default UserManagementTable;
