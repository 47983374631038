import { useMemo } from "react";
import { RadioListInputRHF } from "../../../components/InputsWithRHF";
import { ClientAccountLevel } from "../../../enums/client-account-level";
import { HeaderText } from "../common/styles";

function ClientAccountLevelSection() {
  const disabledOptions = useMemo(
    () =>
      Object.entries(ClientAccountLevel)
        .filter((x) => x[1] !== ClientAccountLevel.PremiumB4SI)
        .reduce<{ [key: string]: string }>((prev, cur) => {
          return { ...prev, [cur[0]]: cur[1] };
        }, {}),
    []
  );
  return (
    <div>
      <HeaderText>Account Level</HeaderText>
      <div className="ps-4 pt-3">
        <RadioListInputRHF
          name="accountLevel"
          options={ClientAccountLevel}
          disabledOptions={disabledOptions}
        />
      </div>
    </div>
  );
}

export default ClientAccountLevelSection;
