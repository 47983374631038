import EditUser from "../../pages/EditUser";
import UserManagement from "../../pages/UserManagement";
import ProtectedRoute from "../../components/ProtectedRoute";
import { ModuleFeature } from "../../models/module-feature";

export const UserManagementRoutes = [
  {
    path: "users",
    element: (
      <ProtectedRoute feature={ModuleFeature.UserManagement} isAdminFeature />
    ),
    children: [
      {
        path: "",
        element: <UserManagement />,
      },
      {
        path: "new",
        element: <EditUser />,
      },
      {
        path: ":userId",
        element: <EditUser />,
      },
    ],
  },
];
