import CheckboxInput from "../Inputs/CheckboxInput";
import DateInput from "../Inputs/DateInput";
import DayMonthInput from "../Inputs/DayMonthInput";
import DropdownInput from "../Inputs/DropdownInput";
import RadioInput from "../Inputs/RadioInput";
import RadioListInput from "../Inputs/RadioListInput";
import TextInput from "../Inputs/TextInput";
import withReactHookForm from "./withReactHookForm";
import PositiveIntegerInput from "../Inputs/NumberInput/PositiveIntegerInput";

const DropdownInputRHF = withReactHookForm(DropdownInput);
const DateInputRHF = withReactHookForm(DateInput);
const CheckboxInputRHF = withReactHookForm(CheckboxInput);
const RadioInputRHF = withReactHookForm(RadioInput);
const TextInputRHF = withReactHookForm(TextInput);
const RadioListInputRHF = withReactHookForm(RadioListInput);
const DayMonthInputRHF = withReactHookForm(DayMonthInput);
const PositiveIntegerInputRHF = withReactHookForm(PositiveIntegerInput);

export {
  DropdownInputRHF,
  DateInputRHF,
  CheckboxInputRHF,
  RadioInputRHF,
  TextInputRHF,
  RadioListInputRHF,
  DayMonthInputRHF,
  PositiveIntegerInputRHF,
};
