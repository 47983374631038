import { formatDate } from "../../../helpers/format-date";
import { Input } from "./styles";
type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: string | null;
  setExternalValue: (value: string | null) => void;
  isInvalid?: boolean;
  isPaddedRight?: boolean;
};
function DateInput({
  externalValue,
  setExternalValue,
  disabled,
  isInvalid = undefined,
  isPaddedRight = false,
  onBlur,
  onFocus,
  name,
}: Props) {
  return (
    <Input
      name={name}
      className="position-relative"
      type="date"
      onChange={(e: any) => {
        if (e.target.value === "") setExternalValue(null);
        else setExternalValue(e.target.value);
      }}
      value={externalValue ? formatDate(externalValue) : ""}
      disabled={disabled}
      isInvalid={isInvalid}
      $isPaddedRight={isPaddedRight}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}

export default DateInput;
