import { useLocation, useNavigate } from "react-router-dom";
import { Spinner, Container, Form, FormGroup } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ActionWrapper, LoginFormLayout } from "./styles";
import {
  DarkBlueOutlinedButton,
  GreyOutlinedInput,
} from "../../../components/common";
import { useEffect, useState } from "react";
import sessionService from "../../../services/session.service";
import { useNotification } from "../../../components/NotificationProvider";
import {
  PositiveIntegerInputRHF,
  TextInputRHF,
} from "../../../components/InputsWithRHF";
import ValidationLayout from "../../Users/ValidationLayout";
import { ErrorMessage } from "@hookform/error-message";

function Login2FaForm() {
  const { state } = useLocation();
  const [token, setToken] = useState<string | undefined>(undefined);

  const methods = useForm<{ code: string }>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      code: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = methods;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showError } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.hasOwnProperty("token")) {
      setToken(state.token);
    } else {
      showError(undefined, "An error occurred. Please try again.");
      navigate("/login");
    }
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    if (token) {
      setIsLoading(true);
      sessionService
        .login2Fa(token, data.code)
        .catch((err) => {
          if (err.status) {
            if (err.data.status === "InvalidCode") {
              setError("code", {
                type: "custom",
                message: "Invalid verification code. Please try again.",
              });
            }
          } else {
            showError(undefined, "An error occurred. Please try again later");
          }
        })
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <LoginFormLayout>
      <Container as={Form} onSubmit={onSubmit} noValidate>
        <FormProvider {...methods}>
          <div className="w-100 text-start">
            <PositiveIntegerInputRHF
              name="code"
              placeholder="Enter the verification code from your Microsoft Authentication app"
              rules={{ required: "Required." }}
              withoutFormatter
            />
            <ErrorMessage
              errors={errors}
              name="code"
              render={({ message }) => {
                return message !== undefined ? (
                  <div className="text-danger fst-italic">{message}</div>
                ) : (
                  <></>
                );
              }}
            />
          </div>
        </FormProvider>
        <ActionWrapper>
          <DarkBlueOutlinedButton type="submit" disabled={!isValid}>
            <Spinner
              animation="border"
              size="sm"
              className="me-2"
              hidden={!isLoading}
            />
            Verify
          </DarkBlueOutlinedButton>
        </ActionWrapper>
      </Container>
    </LoginFormLayout>
  );
}

export default Login2FaForm;
