import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { CheckboxInputRHF } from "../../../components/InputsWithRHF";
import { UserRoleType } from "../../../enums/user-role";
import getKeyByValueEnum from "../../../helpers/get-key-by-value-enum";
import { Feature } from "../../../models/feature";
import { HeaderText } from "../common/styles";
interface Props {
  userRole?: UserRoleType;
  globalFeatures?: Feature[];
  clientGlobalFeatures?: number[];
}
function UserGlobalFeatures({
  userRole,
  globalFeatures,
  clientGlobalFeatures,
}: Props) {
  const modelName = "globalFeatures";
  const { control, setValue } = useFormContext();
  const [globalFeatureOptions, setGlobalFeatureOptions] = useState<{
    [key: number]: string;
  }>({});
  const [globalFeatureEnabledOptions, setGlobalFeatureEnabledOptions] =
    useState<
      | {
          [key: number]: string;
        }
      | undefined
    >(undefined);
  const userRoleType = useWatch({
    control,
    name: "userRole",
  }) as keyof typeof UserRoleType;

  const keyOfRole = useCallback((value: UserRoleType) => {
    return getKeyByValueEnum(value, UserRoleType) as keyof typeof UserRoleType;
  }, []);

  useEffect(() => {
    if (globalFeatures) {
      let obj: { [key: number]: string } = {};
      globalFeatures.forEach((option: any) => {
        obj = { ...obj, [option.id]: option.name };
      });
      setGlobalFeatureOptions(obj);
    } else {
      setGlobalFeatureOptions({});
    }
  }, [globalFeatures]);

  useEffect(() => {
    if (
      userRole === keyOfRole(UserRoleType.MasterAdmin) ||
      userRole === keyOfRole(UserRoleType.ModuleAdmin)
    ) {
      setValue(
        modelName,
        Object.keys(globalFeatureOptions).map((id) => +id)
      );
    }
    if (userRole === keyOfRole(UserRoleType.User)) {
      setValue(modelName, []);
    }
  }, [userRole]);

  useEffect(() => {
    if (
      clientGlobalFeatures === undefined ||
      userRoleType !== keyOfRole(UserRoleType.User)
    ) {
      setGlobalFeatureEnabledOptions(undefined);
    } else {
      if (globalFeatures) {
        let obj: { [key: number]: string } = {};
        globalFeatures
          .filter((gf) => clientGlobalFeatures.includes(gf.id))
          .forEach((option: any) => {
            obj = { ...obj, [option.id]: option.name };
          });
        setGlobalFeatureEnabledOptions(obj);
      } else {
        setGlobalFeatureEnabledOptions(undefined);
      }
    }
  }, [userRoleType, clientGlobalFeatures, globalFeatures]);

  useEffect(() => {
    if (
      globalFeatures !== undefined &&
      userRoleType === keyOfRole(UserRoleType.MasterAdmin)
    ) {
      setValue(
        modelName,
        globalFeatures.map((gf) => gf.id)
      );
    }
  }, [userRoleType, globalFeatures]);

  useEffect(() => {
    if (
      clientGlobalFeatures !== undefined &&
      userRoleType === keyOfRole(UserRoleType.User)
    ) {
      setValue(
        modelName,
        clientGlobalFeatures.map((id) => id)
      );
    }
  }, [userRoleType, clientGlobalFeatures]);

  if (clientGlobalFeatures === undefined) return <></>;
  return (
    <Row className="mt-4">
      <Col xs="9">
        <HeaderText>Global Features</HeaderText>
        <div className="ms-4 py-3 d-flex flex-row">
          <CheckboxInputRHF
            name={modelName}
            options={globalFeatureOptions}
            enabledOptions={globalFeatureEnabledOptions}
            disabled
          />
        </div>
      </Col>
    </Row>
  );
}

export default UserGlobalFeatures;
