import { Table } from "react-bootstrap";
import styled from "styled-components";
import { DarkBlueOutlinedButton } from "../../../../components/common";

export const UserManagementTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 1.5rem;
  color: ${({ theme }) => theme.clientManagement.primaryText};
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin: 0;

  td {
    padding: 16px 0;
    width: 10%;

    :first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      border-top-right-radius: none;
      border-bottom-right-radius: none;
    }
    :last-child {
      border-top-left-radius: none;
      border-bottom-left-radius: none;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  tr,
  td {
    border: none;
    background-color: ${({ theme }) => theme.clientManagement.primaryBgColor};
    vertical-align: middle;
  }

  svg {
    font-size: 3rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.clientManagement.thirdBorder};
    border-radius: 50%;
    color: ${({ theme }) => theme.clientManagement.primaryText};
    background-color: ${({ theme }) => theme.clientManagement.secondaryBgColor};
  }
`;

export const StatusCol = styled.div<{ $isActive: boolean }>`
  padding: 4px 4px;
  margin: 0 2rem;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;

  color: ${({ theme }) => theme.clientManagement.thirdText};

  background-color: ${({ theme, $isActive }) =>
    $isActive
      ? theme.clientManagement.fourthBgColor
      : theme.clientManagement.thirdBgColor};
`;

export const Name = styled.h5`
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-weight: 600;
  margin: 0;
`;

export const EditClientBtn = styled(DarkBlueOutlinedButton)`
  border-radius: 2rem;
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const Email = styled.div`
  text-align: left;
`;
