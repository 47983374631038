import { strings } from "../../localization/en";

function PageNotFound() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold text-danger">
          {strings.page_not_found_error_header}
        </h1>
        <p className="fs-3 text-center">
          <span className="">{strings.page_not_found_error_content}</span>
        </p>
      </div>
    </div>
  );
}

export default PageNotFound;
