import { Dropdown } from "react-bootstrap";
import { CustomToggle } from "./CustomToggle";
import { CustomMenu } from "../Inputs/DropdownInput/CustomMenu";
type Props = React.HTMLProps<HTMLSelectElement> & {
  externalValue: string;
  setExternalValue: (value: string) => void;
  columnTitle: string;
  options: string[];
};
function TableFilter({
  externalValue,
  setExternalValue,
  columnTitle,
  options,
}: Props) {
  return (
    <Dropdown className="h-100">
      <Dropdown.Toggle as={CustomToggle}>
        <span>{externalValue ? externalValue : columnTitle}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        <Dropdown.Item
          value=""
          onClick={() => {
            setExternalValue("");
          }}
        >
          All
        </Dropdown.Item>
        {options.map((option, i) => (
          <Dropdown.Item
            key={option}
            value={option}
            onClick={() => {
              setExternalValue(option);
            }}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TableFilter;
