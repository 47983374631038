import { useEffect, useRef, useState } from "react";
import { NavDropdown } from "react-bootstrap";

export function NavDropDownAuto(props: any) {
  const { children, toggle, ...rest } = props;
  const wrapperRef = useRef<any | null>(null);
  const [isMenuFocused, setIsMenuFocused] = useState<boolean | null>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleFocus(event: any) {
      if (wrapperRef.current)
        if (wrapperRef.current.contains(event.target)) {
          setIsMenuFocused(true);
        } else
          setIsMenuFocused((prev) => {
            if (prev === null) return null;
            else {
              return false;
            }
          });
    }

    document.addEventListener("mousedown", handleFocus);
    return () => {
      document.removeEventListener("mousedown", handleFocus);
    };
  }, [wrapperRef]);
  useEffect(() => {
    if (isMenuFocused === false) setShow(false);
  }, [isMenuFocused]);
  return (
    <NavDropdown
      id="collasible-nav-dropdown"
      {...rest}
      title={toggle(() => setShow((prev) => !prev))}
      show={show}
      ref={wrapperRef}
    >
      {children}
    </NavDropdown>
  );
}
