import { Container } from "react-bootstrap";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { NotificationProvider } from "./components/NotificationProvider";
import { slr } from "./styles/theme";
import { routes } from "./Routes";

function App() {
  const browserRouter = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={slr}>
      <GlobalStyles />
      <NotificationProvider>
        <Container fluid className="p-0">
          <RouterProvider router={browserRouter} />
        </Container>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
