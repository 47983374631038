import styled from "styled-components";
import { DarkBlueOutlinedButton } from "../../../../components/common";

export const HeaderLayout = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100%;
  gap: 10rem;
`;

export const ClientTitle = styled.h3`
  color: ${({ theme }) => theme.layout.headlines};
  font-family: ${({ theme }) => theme.fontFamily.headlines};
  font-weight: 600;
  text-align: left;
  margin-left: 0.5rem;
`;

export const AddNewButton = styled(DarkBlueOutlinedButton)`
  border-radius: 2rem;
`;

export const TitleWrapper = styled.div`
  text-align: left;
`;

export const NewBtnWrapper = styled.div``;

export const SearchClientWrapper = styled.div`
  flex-basis: 30%;
  border-color: ${({ theme }) => theme.clientManagement.secondaryBorder};
  border-style: solid;
  border-width: 2px;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const SearchClientSearchIcon = styled.label`
  svg {
    color: ${({ theme }) => theme.clientManagement.primaryText};
    font-size: 1.5rem;
  }
  flex-basis: fit-content;
`;

export const SearchClientCloseIcon = styled.label`
  svg {
    color: ${({ theme }) => theme.clientManagement.primaryText};
    font-size: 1rem;
  }
  flex-basis: fit-content;
  padding-right: 4px;

  cursor: pointer;
  :hover {
    svg {
      color: ${({ theme }) => theme.clientManagement.fourthText};
    }
  }
`;

export const SearchClientInput = styled.input`
  height: 2.4rem;
  padding: 0;
  padding-left: 0.5rem;
  border: none;
  flex-basis: 100%;

  :focus-visible {
    outline: none;
  }
`;
