import { useState } from "react";
import { OrangeOutlinedButton } from "../../../components/common";
import UploadFilesModal from "../../../components/UploadFilesModal";
import { DocumentOption } from "../../../models/document-option";
import SearchBox from "./SearchBox";
import { ToolBarLayout, FormSelect } from "./styles";

interface ToolBarProps {
  textFromQuery: string;
  onSearchDispatch: any;
  fetchFiles: () => void;
  resetSearch: boolean;
  setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

function ToolBar({
  textFromQuery,
  onSearchDispatch,
  fetchFiles,
  resetSearch,
  setResetSearch,
}: ToolBarProps) {
  const [isUploadFilesModalShown, setIsUploadFilesModalShown] =
    useState<boolean>(false);

  return (
    <ToolBarLayout>
      <SearchBox
        textFromQuery={textFromQuery}
        onSearchDispatch={onSearchDispatch}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <OrangeOutlinedButton onClick={() => setIsUploadFilesModalShown(true)}>
        Upload
      </OrangeOutlinedButton>

      <UploadFilesModal
        showModal={isUploadFilesModalShown}
        handleCloseModal={() => setIsUploadFilesModalShown(false)}
        fetchFiles={fetchFiles}
      />
    </ToolBarLayout>
  );
}

export default ToolBar;
