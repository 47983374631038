import { FaCheck } from "react-icons/fa";
import { RoundedModal, Body, Footer } from "./styles";

type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  title: string;
  content: string;
  action: string;
  isStatic?: boolean;
};
function SuccessModal({
  showModal,
  handleCloseModal,
  title,
  content,
  action,
  isStatic = true,
}: Props) {
  return (
    <RoundedModal
      show={showModal}
      centered
      onHide={handleCloseModal}
      backdrop={isStatic ? "static" : true}
      className="border-0"
    >
      <Body>
        <FaCheck color="rgb(36, 185, 7)" size={64} />
        <h5>{title}</h5>
        <p>{content}</p>
      </Body>

      <Footer onClick={handleCloseModal}>{action}</Footer>
    </RoundedModal>
  );
}

export default SuccessModal;
