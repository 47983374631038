import { MdOutlineSearch, MdClose } from "react-icons/md";
import {
  HeaderLayout,
  TitleWrapper,
  NewBtnWrapper,
  SearchClientWrapper,
  SearchClientSearchIcon,
  SearchClientInput,
  ClientTitle,
  AddNewButton,
  SearchClientCloseIcon,
} from "./styles";
import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router";

type Props = {
  searchTextChange: (searchText: string) => void;
};

function ClientManagementHeader({ searchTextChange }: Props) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<string>("");

  function handleChange(event: any) {
    returnedFunction(event.target.value);
  }

  const returnedFunction = debounce(function (nextValue: string) {
    setSearchText(nextValue);
    searchTextChange(nextValue);
  }, 500);

  const clearSearchHandler = useCallback(() => {
    if (inputRef && inputRef.current?.value) {
      inputRef.current.value = "";
      setSearchText("");
      searchTextChange("");
    }
  }, [searchTextChange]);

  const onClickNewAccount = useCallback(() => {
    navigate("new");
  }, [navigate]);

  return (
    <HeaderLayout>
      <TitleWrapper>
        <ClientTitle>Client Management</ClientTitle>
      </TitleWrapper>
      <NewBtnWrapper>
        <AddNewButton onClick={onClickNewAccount}>
          Add a new client
        </AddNewButton>
      </NewBtnWrapper>
      <SearchClientWrapper>
        <SearchClientSearchIcon htmlFor="search-client-management">
          <MdOutlineSearch />
        </SearchClientSearchIcon>
        <SearchClientInput
          ref={inputRef}
          maxLength={255}
          id="search-client-management"
          placeholder="Search clients"
          onChange={handleChange}
        />

        {!!searchText && (
          <SearchClientCloseIcon onClick={clearSearchHandler}>
            <MdClose />
          </SearchClientCloseIcon>
        )}
      </SearchClientWrapper>
    </HeaderLayout>
  );
}

export default ClientManagementHeader;
