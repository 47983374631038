import styled from "styled-components";
import { Pagination } from "react-bootstrap";

export const PrevBtn = styled(Pagination.Prev)`
  & .page-link {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: bold;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 6px;
    padding: 2px 1px;
    border: ${({ theme }) =>
      `2px solid ${theme.pagination.primaryBorderColor}`};
    color: ${({ theme }) => theme.pagination.thirdColor};
    :hover {
      background-color: ${({ theme }) => theme.pagination.hoverColor};
    }
  }

  &.disabled .page-link {
    background-color: ${({ theme }) => theme.pagination.disableBackgroundColor};
    color: ${({ theme }) => theme.pagination.disableColor};
  }
`;

export const NextBtn = styled(Pagination.Next)`
  & .page-link {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: bold;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 6px;
    padding: 2px 1px;
    border: ${({ theme }) =>
      `2px solid ${theme.pagination.primaryBorderColor}`};
    color: ${({ theme }) => theme.pagination.thirdColor};
    :hover {
      background-color: ${({ theme }) => theme.pagination.hoverColor};
    }
  }

  &.disabled .page-link {
    background-color: ${({ theme }) => theme.pagination.disableBackgroundColor};
  }
`;

export const PageItemBtn = styled(Pagination.Item)`
  & .page-link {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: bold;
    text-align: center;
    min-width: 2.25rem;
    height: 2.25rem;
    border-radius: 6px;
    padding: 2px 10px;
    background-color: ${({ theme }) => theme.pagination.primaryBackgroundColor};
    color: ${({ theme }) => theme.pagination.primaryColor};
    border: ${({ theme }) =>
      `2px solid ${theme.pagination.primaryBorderColor}`};
    :hover {
      background-color: ${({ theme }) => theme.pagination.hoverColor};
      color: ${({ theme }) => theme.pagination.hoverTextColor};
    }
  }

  &.active .page-link {
    background-color: ${({ theme }) => theme.pagination.primaryBackgroundColor};
    color: ${({ theme }) => theme.pagination.secondaryColor};
    border: ${({ theme }) =>
      `2px solid ${theme.pagination.secondaryBorderColor}`};
    :hover {
      color: ${({ theme }) => theme.pagination.primaryColor};
    }
  }
`;

export const EllipsisBtn = styled(Pagination.Ellipsis)`
  & .page-link {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: bold;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 6px;
    padding: 2px 12px;
    border: ${({ theme }) =>
      `2px solid ${theme.pagination.primaryBorderColor}`};
    :hover {
      background-color: ${({ theme }) => theme.pagination.hoverColor};
    }
  }

  &.disabled .page-link {
    background-color: ${({ theme }) => theme.pagination.primaryBackgroundColor};
    color: ${({ theme }) => theme.pagination.primaryColor};
  }
`;
