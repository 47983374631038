export enum ClientSector {
  ConsumerGoods = "Consumer Goods",
  EngineeringAndManufacturing = "Engineering and Manufacturing",
  FinancialServices = "Financial Services",
  Legal = "Legal",
  Media = "Media",
  Pharmaceutical = "Pharmaceutical",
  ProfessionalServices = "Professional Services",
  PropertyConstructionHousingAndFacilities = "Property, Construction, Housing and Facilities",
  Retail = "Retail",
  TechnologyAndTelecoms = "Technology and Telecoms",
  TravelAndHospitality = "Travel and Hospitality",
  UtilitiesAndServices = "Utilities and Services",
}
