import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Module } from "../../../models/module";
import { getClientAccessibleModuleFeatures } from "../../../services/module.services";
import { BodyWrapper, Spinner } from "./styles";
import { MasqueradeValueWrapper, MasqueradeLabel, GoButton } from "../styles";
import sessionService from "../../../services/session.service";
import _ from "lodash";

type Props = {
  clientId: number | null;
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
};

function AccessibleModuleModal({ clientId, show, onShow }: Props) {
  const [accessibleModulesOptions, setAccessibleModulesOptions] = useState<{
    [key: number]: string;
  } | null>(null);
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const convertCheckBoxOptions = (
    values: Module[]
  ): { [key: number]: string } => {
    let options = {};
    values.forEach((value) => {
      options = { ...options, [value.id]: value.name };
    });
    return options;
  };

  useEffect(() => {
    clientId &&
      show &&
      getClientAccessibleModuleFeatures(clientId)
        .then((response) => {
          setAccessibleModulesOptions(convertCheckBoxOptions(response));
        })
        .catch((err) => {
          console.log(err);
        });
  }, [clientId, show]);

  const handleClose = useCallback(() => {
    onShow(false);
  }, [onShow]);

  const clickGoButton = useCallback(
    (module: string) => {
      setRedirecting(true);
      clientId &&
        sessionService
          .masqueradeClient(clientId, module)
          .then((res) => {
            if (res && res?.landingPage) {
              window.location.href = res.landingPage;
            } else {
              prompt("Coming soon.");
              setRedirecting(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setRedirecting(false);
          });
    },
    [clientId]
  );

  const renderBody = useCallback(() => {
    if (!accessibleModulesOptions || redirecting) {
      return (
        <React.Fragment>
          <Spinner />
          <div>Loading...</div>
        </React.Fragment>
      );
    }

    if (_.isEmpty(accessibleModulesOptions)) {
      return <React.Fragment>No data found.</React.Fragment>;
    }

    return (
      <React.Fragment>
        {Object.entries(accessibleModulesOptions).map((opt) => (
          <MasqueradeValueWrapper key={opt[0]}>
            <MasqueradeLabel>{opt[1]}</MasqueradeLabel>
            <div>
              <GoButton onClick={() => clickGoButton(opt[1])}>Go</GoButton>
            </div>
          </MasqueradeValueWrapper>
        ))}
      </React.Fragment>
    );
  }, [accessibleModulesOptions, clickGoButton, redirecting]);

  return (
    <Modal size="sm" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>Business Module(s)</Modal.Header>
      <Modal.Body>
        <BodyWrapper>{renderBody()}</BodyWrapper>
      </Modal.Body>
    </Modal>
  );
}

export default AccessibleModuleModal;
