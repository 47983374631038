import { memo, useEffect, useRef, useState } from "react";
import { Input, InputMask, InputWrapper } from "./styles";

type Props = React.HTMLProps<HTMLInputElement> & {
  externalValue: string | null;
  setExternalValue: (value: string | null) => void;
  isInvalid?: boolean;
  isPaddedRight?: boolean;
};

const DayMonthInput = memo(
  ({
    externalValue,
    setExternalValue,
    disabled,
    isInvalid = undefined,
    isPaddedRight = false,
    onBlur,
    onFocus,
    name,
    placeholder,
  }: Props) => {
    const placeHolder = "dd/mm";
    const [currentPlaceHolderText, setCurrentPlaceHolderText] = useState(
      <>{placeHolder}</>
    );
    const inputRef = useRef<HTMLSpanElement | undefined>(undefined);

    const handleOnChange = (e: any) => {
      if (e.target.value === "") {
        setExternalValue(null);
        setCurrentPlaceHolderText(<>{placeHolder}</>);
      } else {
        const newValue = handleCurrentValue(e.target.value);
        handleMaskText(newValue);
        setExternalValue(newValue);
      }
    };

    const handleCurrentValue = (value: string) => {
      const maskedNumber = "dm";
      let newValue = "";
      const strippedValue = value.replace(/\D\//g, "");
      for (let i = 0; i < strippedValue.length; i++) {
        const isInt = !isNaN(parseInt(strippedValue[i]));
        const matchesNumber = maskedNumber.indexOf(placeHolder[i]) >= 0;
        if (matchesNumber && isInt) {
          newValue += strippedValue[i];
        } else if ((!isInt && matchesNumber) || (!matchesNumber && isInt)) {
          return newValue;
        } else if (strippedValue[i] === placeHolder[i]) {
          newValue += placeHolder[i];
        }
        if (strippedValue[i] === undefined) {
          break;
        }
      }
      if (
        placeHolder[newValue.length] === "/" &&
        externalValue &&
        newValue.length > externalValue.length
      ) {
        newValue += "/";
      }
      return newValue;
    };

    const handleMaskText = (newValue: string) => {
      setCurrentPlaceHolderText(
        <>
          <span>{newValue}</span>
          {placeHolder.substring(newValue.length)}
        </>
      );
    };

    useEffect(() => {
      externalValue && handleMaskText(externalValue);
    }, [externalValue]);

    return (
      <InputWrapper>
        <InputMask
          onClick={() => {
            inputRef?.current && inputRef.current.focus();
          }}
        >
          {currentPlaceHolderText}
        </InputMask>
        <Input
          ref={inputRef}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          type="text"
          placeholder={placeholder}
          forwardedAs={"input"}
          onChange={(e: any) => {
            handleOnChange(e);
          }}
          value={externalValue ? externalValue : ""}
          disabled={disabled}
          isInvalid={isInvalid}
          $isPaddedRight={isPaddedRight}
        />
      </InputWrapper>
    );
  }
);

export default DayMonthInput;
