import { Tooltip } from "react-bootstrap";
import styled from "styled-components";

export const FromFileUpload = styled.form`
  margin: 0 3rem;
  max-width: 100%;
  text-align: center;
  position: relative;
`;

export const InputFileUpload = styled.input`
  display: none;
`;

export const ContentFileUploadWrapper = styled.div<{
  $hasFile?: boolean;
}>`
  font-family: ${({ theme }) => theme.fontFamily.body};

  svg {
    color: ${({ theme, $hasFile }) =>
      $hasFile
        ? theme.uploadFiles.iconColorActive
        : theme.uploadFiles.iconColor};
  }

  p {
    margin: 0.5rem 0;
  }
`;

export const LabelFileUpload = styled.label<{ $active?: boolean }>`
  min-height: 13rem;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 25px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.uploadFiles.iconColor};
  background-color: ${({ $active }) =>
    $active
      ? "rgba(17,97,132,0.1)"
      : ({ theme }) => theme.uploadFiles.background};
`;

export const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
export const FileNameWrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  max-width: 16rem;
  font-family: ${({ theme }) => theme.fontFamily.body};

  button {
    border: none;
    padding: 2px 8px;

    :hover {
      opacity: 0.7;
    }

    svg {
      color: ${({ theme }) => theme.uploadFiles.removeFileButtonColor};
    }
  }
  &:first-of-type {
    margin-top: 1rem;
  }
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.uploadFiles.fileNameColor};
`;
export const FullFileName = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const FileNameTooltip = styled(Tooltip)`
  --tooltip-color: ${({ theme }) => theme.tooltip.background};

  opacity: 1 !important;

  & > .tooltip-inner {
    max-width: 610px;
    text-align: left;

    box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 0.2);
    color: ${({ theme }) => theme.tooltip.text};
    background-color: var(--tooltip-color);
  }

  & > .tooltip-arrow::before {
    border-top-color: var(--tooltip-color);
  }
`;
