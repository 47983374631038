import { useTaskManagement } from "../../contexts/TaskManagementProvider";
import TablePagination from "../../TablePagination";
import TaskTable from "./TaskTable";
import ToolBar from "./ToolBar";

function TaskView() {
  const { pagination, setFilter } = useTaskManagement();
  const setPageChange = (toPage: number) => {
    setFilter((currentFilter) => {
      return { ...currentFilter, currentPage: toPage };
    });
  };

  return (
    <>
      <ToolBar />
      <TaskTable />
      {pagination && (
        <TablePagination {...pagination} pageChanged={setPageChange} />
      )}
    </>
  );
}

export default TaskView;
