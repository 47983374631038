import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { CheckboxInputRHF } from "../../../components/InputsWithRHF";
import { Feature } from "../../../models/feature";
import { HeaderText } from "../common/styles";
interface Props {
  globalFeatures?: Feature[];
  addByDefault?: boolean;
}
function GlobalFeatures({ globalFeatures, addByDefault }: Props) {
  const modelName = "globalFeatures";
  const { setValue } = useFormContext();
  const [globalFeatureOptions, setGlobalFeatureOptions] = useState<{
    [key: number]: string;
  }>({});

  useEffect(() => {
    if (globalFeatures) {
      let obj: { [key: number]: string } = {};
      globalFeatures.forEach((option: any) => {
        obj = { ...obj, [option.id]: option.name };
      });
      setGlobalFeatureOptions(obj);
    } else {
      setGlobalFeatureOptions({});
    }
  }, [globalFeatures]);

  useEffect(() => {
    if (addByDefault) {
      setValue(
        modelName,
        Object.keys(globalFeatureOptions).map((id) => +id)
      );
    }
  }, [globalFeatureOptions]);

  return (
    <Row className="mt-4">
      <Col xs="9">
        <HeaderText>Global Features</HeaderText>
        <div className="ms-4 py-3 d-flex flex-row">
          <CheckboxInputRHF name={modelName} options={globalFeatureOptions} disabled/>
        </div>
      </Col>
    </Row>
  );
}

export default GlobalFeatures;
