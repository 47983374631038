import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTaskManagement } from "../contexts/TaskManagementProvider";
import {
  ExpandIndicator,
  Menu,
  MenuItem,
  MenuWrapper,
  SideBarLayout,
  VerticalDivider,
} from "./styles";
import TaskCount from "./TaskCount";
import { getAllTaskModules } from "../../../services/task.service";
import { TaskModule } from "../../../models/task-module";

interface MenuStateDict {
  [id: string]: boolean;
}

function SideBar() {
  const { selectedItem, setSelectedItem, selectedModuleChanged } =
    useTaskManagement();
  const [modules, setModules] = useState<TaskModule[]>([]);
  const [menuStateDict, setExpandedMenu] = useState<MenuStateDict>({});
  const location = useLocation();
  const setPageUrl = useCallback(
    (module: string) => {
      if (!!module)
        window.history.replaceState(
          null,
          "",
          `${location.pathname}?module=${module}`
        );
    },
    [location.pathname]
  );

  const onMenuItemClicked = useCallback(
    (menuItem: TaskModule) => {
      menuStateDict[menuItem.name] = !menuStateDict[menuItem.name];
      setExpandedMenu(menuStateDict);

      setPageUrl(menuItem.name);

      selectedModuleChanged({
        module: menuItem.name,
      });
    },
    [menuStateDict, setPageUrl, selectedModuleChanged]
  );

  useEffect(() => {
    getAllTaskModules().then((result) => setModules(result));
  }, []);

  useEffect(() => {
    setPageUrl(selectedItem.module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideBarLayout>
      <Menu>
        {modules.map((mi: TaskModule) => (
          <MenuWrapper key={mi.name}>
            <MenuItem
              key={mi.name}
              level={0}
              onClick={() => onMenuItemClicked(mi)}
            >
              {!!mi.childModules && mi.childModules.length > 0 && (
                <ExpandIndicator selected={menuStateDict[mi.name]} />
              )}
              {mi.name} <TaskCount module={mi.name} />
            </MenuItem>
            {!!mi.childModules &&
              mi.childModules.map((f: TaskModule) => (
                <MenuWrapper key={f.name} $hide={!menuStateDict[mi.name]}>
                  <MenuItem level={1} onClick={() => onMenuItemClicked(f)}>
                    {!!f.childModules && f.childModules.length > 0 && (
                      <ExpandIndicator
                        $subLevel
                        selected={menuStateDict[f.name]}
                      />
                    )}
                    {f.name} <TaskCount module={f.name} />
                  </MenuItem>
                  <MenuWrapper $hide={!menuStateDict[f.name]}>
                    {!!f.childModules &&
                      f.childModules.map((sf: TaskModule) => (
                        <MenuItem
                          key={sf.name}
                          level={2}
                          selected={selectedItem.module === sf.name}
                          onClick={() => onMenuItemClicked(sf)}
                        >
                          {sf.name} <TaskCount module={sf.name} />
                        </MenuItem>
                      ))}
                  </MenuWrapper>
                </MenuWrapper>
              ))}
          </MenuWrapper>
        ))}

        <MenuItem
          level={0}
          selected={selectedItem.module === "Deleted"}
          onClick={() =>
            setSelectedItem({
              module: "Deleted",
            })
          }
        >
          Deleted Items
        </MenuItem>
      </Menu>
      <VerticalDivider></VerticalDivider>
    </SideBarLayout>
  );
}
export default SideBar;
