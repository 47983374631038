import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbBar from "../../components/BreadcrumbBar";
import { useNotification } from "../../components/NotificationProvider";
import { ClientUser } from "../../models/client-user";
import EditUserForm from "./EditUserForm";
import { getUser } from "../ClientManagement/services/client.services";

export default function EditUser() {
  const { userId } = useParams();
  const { showError } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValue = {
    active: false,
    clientId: location.state?.clientId ? +location.state.clientId : undefined,
    contact: {
      name: "",
      role: "",
      phone: "",
    },
    userRole: undefined,
    allowedToMasquerade: false,
  };

  const methods = useForm<ClientUser>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: initialValue,
  });
  const { reset } = methods;

  useEffect(() => {
    reset(initialValue);

    if (userId) {
      getUser(userId)
        .then((res) => {
          reset(res);
        })
        .catch(() => {
          showError(undefined, "Can't fetch user");
          navigate("/users");
        });
    }
  }, [userId]);

  return (
    <>
      <BreadcrumbBar
        items={[
          { name: "User Management", link: "/users" },
          { name: "Edit User", active: true },
        ]}
      />
      <FormProvider {...methods}>
        <EditUserForm isEdit={!!userId} />
      </FormProvider>
    </>
  );
}
