import styled from "styled-components";

export const ForgotPasswordFormLayout = styled.div.attrs((_) => ({
  className: "pt-5",
}))`
  form,
  .form-input {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  text-align: center;
`;
