import React from "react";
import TablePagination from "../../TablePagination";
import { useTaskManagement } from "../../contexts/TaskManagementProvider";
import ToolBar from "../SharedComponents/ToolBar";
import TaskTable from "../SharedComponents/TaskTable";

function TaskView() {
  const { pagination, setFilter } = useTaskManagement();
  const setPageChange = (toPage: number) => {
    setFilter((currentFilter) => {
      return { ...currentFilter, currentPage: toPage };
    });
  };

  return (
    <React.Fragment>
      <ToolBar />
      <TaskTable />
      {pagination && (
        <TablePagination {...pagination} pageChanged={setPageChange} />
      )}
    </React.Fragment>
  );
}

export default TaskView;
